import React from "react";
import styles from './breadcrumb.module.scss';
import FavoriteIcon from "../../components/favoriate-icon/favoriteIcon";

const BreadCrumb = (props) => {
    const { list, isFavorite, hideFavorite } = props

    const redirectTo = (item) => {
        props.history.push(item.path);
    }

    return (
        <div className={styles.favoriteBreadcrum}>
            <div className={styles.breadcrumParent}>
                <ol className={styles.breadcrumb}>
                    {
                        list.map((item, index) => {
                            return (
                                <li key={index} className={styles.breadcrumbItem}>
                                    {
                                        (index === list.length - 1) ? <span>{item.value}</span> : <a onClick={() => redirectTo(item)}>{item.value}</a>
                                    }
                                </li>
                            )
                        })
                    }
                </ol></div>
            {
                !hideFavorite ? <div>
                    <FavoriteIcon
                        isFavorite={isFavorite}
                        addToFavorite={props.addToFavorite} />
                </div> : null
            }

        </div>

    )
}

export default BreadCrumb