import React from 'react'
import styles from './customTable.module.scss';

const CustomTable = (props) => {
    const { columns, cxList, ascending } = props;
    return (
        <div className={styles.tableResponsive}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {columns.map((item, index) => (
                            <th
                                style={index === 0 ? { paddingLeft: "50px" } : null}
                                key={index}
                                onClick={() => props.listSorting(item)}
                            >
                                <span className={item.sortable ? "pointer" : null}>
                                    {item.name}
                                </span>
                                {
                                    item.isActive && <span
                                        className={ascending ? styles.sortIcnInvert : styles.sortIcn}
                                    ></span>
                                }
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {cxList.map((item, index) => (
                        <tr
                            onClick={() => props.primaryView(item)}
                            key={index}
                        >
                            <td style={{ paddingLeft: "50px" }}>{item.name}</td>
                            <td>
                                {
                                    item.score ? <React.Fragment>
                                        {Number(item.score).toFixed(0)}
                                    </React.Fragment> : '-'
                                }
                            </td>
                            <td>
                                {
                                    item.score ? <React.Fragment>
                                        {
                                            (isNaN(item.differenceCent)
                                                || item.differenceCent === Infinity
                                                || item.differenceCent === -Infinity) ? '-' : <span>
                                                {Number(Math.abs(item.differenceCent)).toFixed(0)}%
                                                {item.score > item.lastQuarterValue ? (
                                                    <span className={styles.upArrow}></span>
                                                ) : (
                                                    item.score < item.lastQuarterValue ?
                                                        <span className={styles.downArrow}></span> : null
                                                )}
                                            </span>
                                        }
                                    </React.Fragment> : '-'
                                }
                            </td>
                            <td>{item.dataPoints.toLocaleString('en-US',{ minimumFractionDigits: 0 })}</td>
                            <td>{item.responsibleProcessTeam}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default CustomTable;