import { connect } from 'react-redux'
import Search from '../../components/search/Search'
import * as consts from '../../store/constant'

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        setCustomer: (data) => dispatch({
            type: consts.SET_CUSTOMER,
            data: data
        }),
        setProductDivision: (data) => dispatch({
            type: consts.SET_PRODUCT_DIVISION,
            data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
