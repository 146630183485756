import { connect } from 'react-redux'
import ChildWeightage from '../../pages/config/child-weightage/ChildWeightage'
import * as consts from '../../store/constant'

const mapStateToProps = state => {
    return {
        kpi: state.KPIWeightageReducer ? state.KPIWeightageReducer.parentKPI : null
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setChildKPI: (data) => dispatch({
            type: consts.SET_CHILD_KPI,
            data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildWeightage)
