import { connect } from 'react-redux'
import ChildView from '../../pages/nba/child-view/ChildView'
import * as consts from '../../store/constant'
import favInput from '../../assets/json/user-favorites/input.json'

const mapStateToProps = state => {
    return {
        nba: state.NBAReducer ? state.NBAReducer.nba : null,
        breadCrumb: state.NBAReducer ? state.NBAReducer.breadCrumb : null,
        selectedProductDivision: state.ProductDivisionReducer ? state.ProductDivisionReducer.data : null,
        selectedCustomer: state.CustomerReducer ? state.CustomerReducer.data : null,
        selectedSBS: state.SBSReducer ? state.SBSReducer.data : null,
        favInput: favInput,
        favorites: state.FavoriteReducer ? state.FavoriteReducer.data : null
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNBABreadCrumb: (data) => dispatch({
            type: consts.SET_NBA_BREAD_CRUMB,
            data: data
        }),
        setFavorite: (data) => dispatch({
            type: consts.SET_FAVORITE,
            data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildView)
