import * as utils from "./utils"

export const chkIsFavorite = (favorites = [], ViewType, productDivision, customer, sbs, kpi, nba) => {
    let FavoriteId = null

    const currentViewFavorites = favorites.filter(element => element.ViewType === ViewType)
    if ((ViewType === 1 || ViewType === 2 || ViewType === 3 || ViewType === 4 || ViewType === 5 || ViewType === 6 || ViewType === 7 || ViewType === 8 || ViewType === 9) && currentViewFavorites.length) {
        if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs)) {
            const favs = currentViewFavorites.filter(element =>
                element.InputParameters.productDivision.code === productDivision.code
                && utils.isEmpty(element.InputParameters.sbs) && utils.isEmpty(element.InputParameters.customer))
            if (favs && favs.length) {
                if (ViewType === 3 || ViewType === 4 || ViewType === 5) {
                    const _favs = favs.filter(element =>
                        element.InputParameters.kpi.name === kpi.name)
                    if (_favs !== undefined && _favs.length)
                        FavoriteId = _favs[0].FavoriteId
                } else if (ViewType === 6 || ViewType === 7 || ViewType === 8) {
                    const _favs = favs.filter(element =>
                        element.InputParameters.nba.name === nba.name)
                    if (_favs !== undefined && _favs.length)
                        FavoriteId = _favs[0].FavoriteId
                } else if (favs !== undefined && favs.length)
                    FavoriteId = favs[0].FavoriteId
            }
        } else if (utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs)) {
            const favs = currentViewFavorites.filter(element =>
                element.InputParameters.customer.code === customer.code && utils.isEmpty(element.InputParameters.productDivision))
            if (ViewType === 3 || ViewType === 4 || ViewType === 5) {
                const _favs = favs.filter(element =>
                    element.InputParameters.kpi.name === kpi.name)
                if (_favs !== undefined && _favs.length)
                    FavoriteId = _favs[0].FavoriteId
            } else if (ViewType === 6 || ViewType === 7 || ViewType === 8) {
                const _favs = favs.filter(element =>
                    element.InputParameters.nba.name === nba.name)
                if (_favs !== undefined && _favs.length)
                    FavoriteId = _favs[0].FavoriteId
            } else if (favs !== undefined && favs.length)
                FavoriteId = favs[0].FavoriteId
        } else if (!utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs)) {
            const favs = currentViewFavorites.filter(element =>
                element.InputParameters.productDivision.code === productDivision.code &&
                element.InputParameters.customer.code === customer.code)
            if (ViewType === 3 || ViewType === 4 || ViewType === 5) {
                const _favs = favs.filter(element =>
                    element.InputParameters.kpi.name === kpi.name)
                if (_favs !== undefined && _favs.length)
                    FavoriteId = _favs[0].FavoriteId
            } else if (ViewType === 6 || ViewType === 7 || ViewType === 8) {
                const _favs = favs.filter(element =>
                    element.InputParameters.nba.name === nba.name)
                if (_favs !== undefined && _favs.length)
                    FavoriteId = _favs[0].FavoriteId
            } else if (favs !== undefined && favs.length)
                FavoriteId = favs[0].FavoriteId
        } else if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && !utils.isEmpty(sbs)) {
            const favs = currentViewFavorites.filter(element =>
                element.InputParameters.productDivision.code === productDivision.code &&
                element.InputParameters.sbs.code === sbs.code)
            if (ViewType === 3 || ViewType === 4 || ViewType === 5) {
                const _favs = favs.filter(element =>
                    element.InputParameters.kpi.name === kpi.name)
                if (_favs !== undefined && _favs.length)
                    FavoriteId = _favs[0].FavoriteId
            } else if (ViewType === 6 || ViewType === 7 || ViewType === 8) {
                const _favs = favs.filter(element =>
                    element.InputParameters.nba.name === nba.name)
                if (_favs !== undefined && _favs.length)
                    FavoriteId = _favs[0].FavoriteId
            } else if (favs !== undefined && favs.length)
                FavoriteId = favs[0].FavoriteId
        } else {
            const lsgItems = currentViewFavorites.filter(element =>
                utils.isEmpty(element.InputParameters.productDivision) &&
                utils.isEmpty(element.InputParameters.customer) &&
                utils.isEmpty(element.InputParameters.sbs))

            if (lsgItems !== undefined && lsgItems.length) {
                if (ViewType === 3 || ViewType === 4 || ViewType === 5) {
                    const favs = lsgItems.filter(element =>
                        element.InputParameters.kpi.name === kpi.name)
                    if (favs !== undefined && favs.length)
                        FavoriteId = favs[0].FavoriteId
                } else if (ViewType === 6 || ViewType === 7 || ViewType === 8) {
                    const favs = lsgItems.filter(element =>
                        element.InputParameters.nba.name === nba.name)
                    if (favs !== undefined && favs.length)
                        FavoriteId = favs[0].FavoriteId
                } else {
                    FavoriteId = lsgItems[0].FavoriteId
                }
            }
        }
    }
    return FavoriteId;

}

export const addFavorites = (input) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}userfavorites`, {
            method: 'POST',
            headers: utils.headers(),
            body: JSON.stringify([input])
        })
            .then(response => response.json())
            .then(response => resolve(response[0].FavoriteId))
            .catch((error) => {
                console.log(error)
                reject(error)
                if (error.message === 'Failed to fetch') {
                    window.open(process.env.REACT_APP_URL, '_self')
                }
            })
    })
}

export const getFavorites = () => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}userfavorites?userId=${utils.getUserEmail()}`, {
            method: 'GET',
            headers: utils.headers()
        })
            .then(response => response.json())
            .then(response => resolve(response))
            .catch((error) => {
                console.log(error)
                reject(error)
                if (error.message === 'Failed to fetch') {
                    window.open(process.env.REACT_APP_URL, '_self')
                }
            })
    })
}

export const removeFavorites = (FavoriteId) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}userfavorites/${FavoriteId}`, {
            method: 'DELETE',
            headers: utils.headers()
        })
            .then(response => response.json())
            .then(response => resolve(response))
            .catch((error) => {
                console.log(error)
                reject(error)
                if (error.message === 'Failed to fetch') {
                    window.open(process.env.REACT_APP_URL, '_self')
                }
            })
    })
}

const getType = favorite => {
    switch (favorite.ViewType) {
        case 3:
        case 4:
        case 5:
            return 'KPI'
        case 6:
        case 7:
        case 8:
            return 'NBA'
        default:
            return ''
    }
}

const getSubTitle = favorite => {
    let subTitle = ''
    const { productDivision, customer, sbs, group } = favorite.InputParameters

    if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs)) {
        return subTitle.concat(productDivision.code)
    } else if (utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs)) {
        return subTitle.concat(utils.textLimitTo(utils.capitalize(customer.name), 20))
    } else if (!utils.isEmpty(productDivision) && !utils.isEmpty(sbs) && utils.isEmpty(customer)) {
        if (favorite.ViewType === 2 || favorite.ViewType === 9) {
            return subTitle.concat(`<div class="favoriteFlex"><div>${productDivision.code}</div><div>${sbs.code}</div></div>`)
        }
        return subTitle.concat(`<div class="favoriteFlex"><div>${productDivision.code}</div><div class="forwardArrowSubTitle"></div><div>${sbs.code}</div></div>`)
    } else if (!utils.isEmpty(productDivision) && utils.isEmpty(sbs) && !utils.isEmpty(customer)) {
        if (favorite.ViewType === 2 || favorite.ViewType === 9) {
            return subTitle.concat(`<div class="favoriteFlex"><div>${productDivision.code}</div><div>${utils.textLimitTo(utils.capitalize(customer.name), 15)}</div></div>`)
        }
        return subTitle.concat(`<div class="favoriteFlex"><div>${productDivision.code}</div><div class="forwardArrowSubTitle"></div><div>${utils.textLimitTo(utils.capitalize(customer.name), 15)}</div></div>`)
    } else {
        return subTitle.concat(group.name)
    }
}

const getTitle = favorite => {
    switch (favorite.ViewType) {
        case 2:
            switch (favorite.cxPageId) {
                case 1:
                    return favorite.InputParameters.group.name
                case 2:
                    return utils.isEmpty(favorite.InputParameters.productDivision) ?
                        utils.textLimitTo(utils.capitalize(favorite.InputParameters.customer.name), 20) :
                        `<div class="favoriteFlex"><div>${favorite.InputParameters.productDivision.code}</div><div class="forwardArrow"></div><div>${utils.textLimitTo(utils.capitalize(favorite.InputParameters.customer.name), 15)}</div></div>`
                case 3:
                    return favorite.InputParameters.productDivision.code
                case 4:
                    return `<div class="favoriteFlex"><div>${favorite.InputParameters.productDivision.code}</div><div class="forwardArrow"></div><div>${favorite.InputParameters.sbs.code}</div></div>`
                default:
                    return ''
            }
        case 3:
        case 4:
        case 5:
            return utils.textLimitTo(favorite.InputParameters.kpi.name, 20)
        case 6:
        case 7:
        case 8:
            return utils.textLimitTo(favorite.InputParameters.nba.name, 20)
        case 9:
            switch (favorite.casPageId) {
                case 1:
                    return favorite.InputParameters.group.name
                case 2:
                    return utils.isEmpty(favorite.InputParameters.productDivision) ?
                        utils.textLimitTo(utils.capitalize(favorite.InputParameters.customer.name), 20) :
                        `<div class="favoriteFlex"><div>${favorite.InputParameters.productDivision.code}</div><div class="forwardArrow"></div><div>${utils.textLimitTo(utils.capitalize(favorite.InputParameters.customer.name), 15)}</div></div>`
                case 3:
                    return favorite.InputParameters.productDivision.code
                case 4:
                    return `<div class="favoriteFlex"><div>${favorite.InputParameters.productDivision.code}</div><div class="forwardArrow"></div><div>${favorite.InputParameters.sbs.code}</div></div>`
                default:
                    return ''
            }
        default:
            return ''
    }
}

const getView = favorite => {
    switch (favorite.ViewType) {
        case 2:
            return favorite.InputParameters.sasParent.toLowerCase() === 'cx' ? 'CX Details Page' : 'CAS Details Page'
        case 3:
        case 6:
            return 'Primary View'
        case 4:
        case 7:
            return 'Detailed View'
        case 5:
        case 8:
            return 'History View'
        case 9:
            return 'Overall CAS Details View'
        default:
            return null
    }
}

// 1 - Group
// 2 - Customer
// 3 - Product Division
// 4 - Business Units
const getDetailPageId = element => {
    const { productDivision, customer, sbs, group } = element.InputParameters

    if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs)) {
        return 3
    } else if (utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs)) {
        return 2
    } else if (!utils.isEmpty(productDivision) && !utils.isEmpty(sbs) && utils.isEmpty(customer)) {
        return 4
    } else if (!utils.isEmpty(productDivision) && utils.isEmpty(sbs) && !utils.isEmpty(customer)) {
        return 2
    } else {
        return 1
    }
}

const getEntityDetail = (url) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}gets3filesdata?${url}`, {
            method: 'GET',
            headers: utils.headers()
        })
            .then(response => response.json())
            .then(response => resolve(response))
            .catch((error) => {
                console.log(error)
                reject(error)
            })
    })
}

const getEntityDetailES = (url, method, input) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_ELASTICSEARCH_URL}${url}`, {
            method: method,
            headers: utils.headers(),
            body: input ? JSON.stringify(input) : undefined
        })
            .then(response => response.json())
            .then(response => resolve(response))
            .catch((error) => {
                console.log(error)
                if (error.message === 'Failed to fetch') {
                    // window.open(process.env.REACT_APP_URL, '_self')
                }
            })
    })
}

const filterCustomerData = (result) => {
    const group = (result && result.hits && result.hits.hits.length) ? result.hits.hits[0] : [];
    const customerFilterdArr = (group && group.inner_hits && group.inner_hits['group.customers'] && group.inner_hits['group.customers'].hits) ? group.inner_hits['group.customers'].hits : []
    let new_array = []
    if (customerFilterdArr && customerFilterdArr.hits && customerFilterdArr.hits.length > 0) {
        new_array = customerFilterdArr.hits.map(function callback(element) {
            let custObj = {};
            custObj['name'] = (element.fields['group.customers.name.keyword']) ? element.fields['group.customers.name.keyword'][0] : '-';
            custObj.cxScore = (element.fields['group.customers.cxScore']) ? element.fields['group.customers.cxScore'][0] : '-';
            custObj.lastQuarterCxScore = (element.fields['group.customers.lastQuarterCxScore']) ? element.fields['group.customers.lastQuarterCxScore'][0] : '-';
            custObj.lastQuarterCas = (element.fields['group.customers.lastQuarterCas'])?element.fields['group.customers.lastQuarterCas'][0]:'-';
            custObj.responseCount = (element.fields['group.customers.responseCount'])?element.fields['group.customers.responseCount'][0]:'-';
            custObj.code = (element.fields['group.customers.code.keyword']) ? element.fields['group.customers.code.keyword'][0] : '-';
            custObj.id = (element.fields['group.customers.id']) ? element.fields['group.customers.id'][0] : '-';
            custObj.name = (element.fields['group.customers.name.keyword']) ? element.fields['group.customers.name.keyword'][0] : '-';
            custObj.cas = (element.fields['group.customers.cas']) ? element.fields['group.customers.cas'][0] : '-';
            custObj.volatility = (element.fields['group.customers.volatility.keyword']) ? element.fields['group.customers.volatility.keyword'][0] : '-';
            custObj.recommendation = (element.fields['group.customers.recommendation.keyword']) ? element.fields['group.customers.recommendation.keyword'][0] : '-';
            custObj.whiteGlove = (element.fields['group.customers.whiteGlove.keyword']) ? element.fields['group.customers.whiteGlove.keyword'][0] : '-';
            return custObj
        })
    }
    group['_source'].group.customers = new_array;
    return group['_source'];
}
const formatCustDetail = (_customerDetail) => {
    let custDetailRes = [];
    if (_customerDetail && _customerDetail.data && _customerDetail.data.hits && _customerDetail.data.hits.hits && _customerDetail.data.hits.hits.length) {
        custDetailRes = _customerDetail.data.hits.hits.map(details => {
            let customerDetail = {}
            customerDetail = details['_source']
            return customerDetail;
        })
        return custDetailRes
    }
    return custDetailRes;
}
const formatESData = (result) => {
    const productDivisions = (result.hits && result.hits.hits && result.hits.hits.length>0)?result.hits.hits[0]:[];
    const customerFilterdArr = (productDivisions && productDivisions.inner_hits && productDivisions.inner_hits['productDivisions.customers'] && productDivisions.inner_hits['productDivisions.customers'].hits)?productDivisions.inner_hits['productDivisions.customers'].hits:[];
    let new_array = []
    if (customerFilterdArr && customerFilterdArr.hits && customerFilterdArr.hits.length > 0) {
      new_array = customerFilterdArr.hits.map(function callback(element) {
        let custObj = {};
        custObj['name'] = (element.fields['productDivisions.customers.name.keyword'])?element.fields['productDivisions.customers.name.keyword'][0]:'-';
        custObj.cxScore = (element.fields['productDivisions.customers.cxScore'])?element.fields['productDivisions.customers.cxScore'][0]:'-';
        custObj.lastQuarterCxScore = (element.fields['productDivisions.customers.lastQuarterCxScore'])?element.fields['productDivisions.customers.lastQuarterCxScore'][0]:'-';
        custObj.lastQuarterCas = (element.fields['productDivisions.customers.lastQuarterCas'])?element.fields['productDivisions.customers.lastQuarterCas'][0]:'-';
        custObj.responseCount = (element.fields['productDivisions.customers.responseCount'])?element.fields['productDivisions.customers.responseCount'][0]:'-';
        custObj.code = (element.fields['productDivisions.customers.code.keyword'])?element.fields['productDivisions.customers.code.keyword'][0]:'-';
        custObj.id = (element.fields['productDivisions.customers.id'])?element.fields['productDivisions.customers.id'][0]:'-';
        custObj.name = (element.fields['productDivisions.customers.name.keyword'])?element.fields['productDivisions.customers.name.keyword'][0]:'-';
        custObj.cas = (element.fields['productDivisions.customers.cas'])?element.fields['productDivisions.customers.cas'][0]:'-';
        custObj.volatility = (element.fields['productDivisions.customers.volatility.keyword'])?element.fields['productDivisions.customers.volatility.keyword'][0]:'-';
        custObj.recommendation = (element.fields['productDivisions.customers.recommendation.keyword'])?element.fields['productDivisions.customers.recommendation.keyword'][0]:'-';
        custObj.whiteGlove = (element.fields['productDivisions.customers.whiteGlove.keyword'])?element.fields['productDivisions.customers.whiteGlove.keyword'][0]:'-';
        custObj.productdivision = (element.fields['productDivisions.customers.productdivision.keyword'])?element.fields['productDivisions.customers.productdivision.keyword'][0]:'-';        
        return custObj
      })
    }
    let allProductDivisions = (productDivisions && productDivisions['_source'] && productDivisions['_source'].productDivisions)?[...productDivisions['_source'].productDivisions]:[]
    const uniquePD = [...new Set(new_array.map(item => item.productdivision))]
    for(let i=0;i<uniquePD.length;i++){
        let findCustomers = new_array.filter(itm=>itm.productdivision === uniquePD[i]);
        let selectedPDIndex = allProductDivisions.findIndex(item=>item.code === uniquePD[i])
        allProductDivisions[selectedPDIndex].customers = findCustomers
    }
    //let selectedPDIndex = allProductDivisions.findIndex(item=>item.code === productDivision.code)
    //allProductDivisions[selectedPDIndex].customers = new_array
    //productDivisions['_source'].productDivisions.customers = new_array;
    return allProductDivisions;
}
const formatSBSESData = (result) =>{
    let BUArray = [];
     let mainArray = (result.hits && result.hits.hits && result.hits.hits.length>0)?result.hits.hits[0]:[]
     let BUfilters = (mainArray && mainArray.inner_hits && mainArray.inner_hits.productDivisions && mainArray.inner_hits.productDivisions.hits && mainArray.inner_hits.productDivisions.hits.hits)?mainArray.inner_hits.productDivisions.hits.hits:[];
     if(BUfilters && BUfilters.length>0){
        let BUArray1 = BUfilters.map((item)=>{
            let result = {}
            result['businessUnits'] = item['_source']['businessUnits']
            BUArray.push(result)
        })
     }
     return BUArray
        //const businessUnit = productDivision['_source'].businessUnits.filter(item=>item.code === selectedSBS.code)[0];
       // return businessUnit
}
const formatESPDCustomerDetailData = (result) => {
    let custDetailsPDArr = []
    let mainArray = (result.hits && result.hits.hits && result.hits.hits.length>0)?result.hits.hits:[]
    if(mainArray && mainArray.length>0){
        let custDetailsPDArray = mainArray.map((item)=>{
            let result = {}
            result['custDetails'] = item['_source']
            custDetailsPDArr.push(result);
        })
     }
    return custDetailsPDArr;
}
export async function processFavorites(favorites) {
    favorites.map(element => {
        element.view = getView(element)
        element.type = getType(element)
        element.subTitle = getSubTitle(element)
        element.detailPageId = (element.ViewType === 1) ? getDetailPageId(element) : null
        element.cxPageId = (element.ViewType === 2) ? getDetailPageId(element) : null
        element.casPageId = (element.ViewType === 9) ? getDetailPageId(element) : null
        element.title = getTitle(element)
    })
    //Updating with latest values
    const detailedViews = favorites.filter(element => ((
        (element.ViewType === 1 || element.ViewType === 2 || element.ViewType === 9))
        && ((!utils.isEmpty(element.InputParameters.productDivision) && utils.isEmpty(element.InputParameters.customer) && utils.isEmpty(element.InputParameters.sbs))
            || (utils.isEmpty(element.InputParameters.productDivision) && !utils.isEmpty(element.InputParameters.customer) && utils.isEmpty(element.InputParameters.sbs))
            || (utils.isEmpty(element.InputParameters.productDivision) && utils.isEmpty(element.InputParameters.customer) && utils.isEmpty(element.InputParameters.sbs))
            || (!utils.isEmpty(element.InputParameters.productDivision) && !utils.isEmpty(element.InputParameters.customer) && utils.isEmpty(element.InputParameters.sbs))
            || (!utils.isEmpty(element.InputParameters.productDivision) && utils.isEmpty(element.InputParameters.customer) && !utils.isEmpty(element.InputParameters.sbs)))))

    if (detailedViews.length) {

        let groupDetail;
        let items2 = detailedViews.filter(element => utils.isEmpty(element.InputParameters.productDivision)
            && utils.isEmpty(element.InputParameters.customer)
            && utils.isEmpty(element.InputParameters.sbs)
            && (element.ViewType === 2 || element.ViewType === 9))
        if (items2 && items2.length) {
            const groupDetailQuery = utils.groupDetailQuery();
            const groupDetailES = await getEntityDetailES('-elasticsearch', 'POST', groupDetailQuery)
            let _grp = (groupDetailES && groupDetailES.data && groupDetailES.data.hits && groupDetailES.data.hits.hits && groupDetailES.data.hits.hits.length > 0) ? groupDetailES.data.hits.hits[0]['_source'] : [];
            groupDetail = _grp.group[0]
        }

        let pdDetail;
        let items3 = detailedViews.filter(element => !utils.isEmpty(element.InputParameters.productDivision)
            && utils.isEmpty(element.InputParameters.customer)
            && utils.isEmpty(element.InputParameters.sbs)
            && (element.ViewType === 2 || element.ViewType === 9))
        if (items3 && items3.length) {
            const pdDetailQuery = utils.pdDetailQuery();
            const _pdDetail = await getEntityDetailES('-elasticsearch', 'POST', pdDetailQuery)
            pdDetail = (_pdDetail && _pdDetail.data && _pdDetail.data.hits && _pdDetail.data.hits.hits && _pdDetail.data.hits.hits.length > 0) ? _pdDetail.data.hits.hits[0]['_source']['productDivision'] : [];
        }

        let parentObj;
        let itemsCustomer = detailedViews.filter(element => utils.isEmpty(element.InputParameters.productDivision)
            && !utils.isEmpty(element.InputParameters.customer)
            && utils.isEmpty(element.InputParameters.sbs)
            && (element.ViewType === 1))
        if (itemsCustomer && itemsCustomer.length) {
            const groupCustomerQuery = utils.groupCustomerQuery(itemsCustomer);
            const _pdDetail = await getEntityDetailES('-elasticsearch', 'POST', groupCustomerQuery)
            parentObj = filterCustomerData(_pdDetail.data);
        } else {
            const getGroupQuery = utils.groupQueryParams();
            let parentObjES = await getEntityDetailES('-elasticsearch', 'POST', getGroupQuery)
            parentObj = (parentObjES && parentObjES.data && parentObjES.data.hits && parentObjES.data.hits.hits && parentObjES.data.hits.hits.length > 0) ? parentObjES.data.hits.hits[0]['_source'] : [];
        }

        let customerDetail;
        let items4 = detailedViews.filter(element => utils.isEmpty(element.InputParameters.productDivision)
            && !utils.isEmpty(element.InputParameters.customer)
            && utils.isEmpty(element.InputParameters.sbs)
            && (element.ViewType === 2 || element.ViewType === 9))
        if (items4 && items4.length) {
            const custDetailsQuery = utils.custDetailQuery(items4)
            let customerDetailsArr = []
            let _customerDetail = await getEntityDetailES('-elasticsearch', 'POST', custDetailsQuery)
            const customerDetailArr = formatCustDetail(_customerDetail);
            customerDetail = customerDetailArr
            // customerDetail = customerDetailsArr
        }

        let pdParentObj
        let items1 = detailedViews.filter(element => !utils.isEmpty(element.InputParameters.productDivision)
            && (!utils.isEmpty(element.InputParameters.customer)))
        if (items1 && items1.length) {
            const pdDetailsQuery = utils.pdQuery(items1)
            let esPDResponse = await getEntityDetailES('-elasticsearch', 'POST', pdDetailsQuery)
            const formatES = formatESData(esPDResponse.data);
            pdParentObj = formatES
        }

        let pdSBSObj
        let items7 = detailedViews.filter(element => !utils.isEmpty(element.InputParameters.productDivision)
            && !utils.isEmpty(element.InputParameters.sbs))
        if (items7 && items7.length) {
            const pdDetailsQuery = utils.pdQuerySBS()
            let esPDResponse = await getEntityDetailES('-elasticsearch', 'POST', pdDetailsQuery)
            const formatES = formatESData(esPDResponse.data);
            pdSBSObj = formatES
        }

            let sbsDetail;
        let items5 = detailedViews.filter(element => !utils.isEmpty(element.InputParameters.productDivision)
            && utils.isEmpty(element.InputParameters.customer)
            && !utils.isEmpty(element.InputParameters.sbs)
            && (element.ViewType === 2 || element.ViewType === 9))
        if (items5 && items5.length) {
            const pdSBSDetailsQuery = utils.pdSBSDetailsQuery(items5)
            let esPDSBSResponse = await getEntityDetailES('-elasticsearch', 'POST', pdSBSDetailsQuery)
            const formatES = formatSBSESData(esPDSBSResponse.data);
            sbsDetail = formatES
        }

        let pdCustomerDetail;
        let items6 = detailedViews.filter(element => !utils.isEmpty(element.InputParameters.productDivision)
            && !utils.isEmpty(element.InputParameters.customer)
            && utils.isEmpty(element.InputParameters.sbs)
            && (element.ViewType === 2 || element.ViewType === 9))
        if (items6 && items6.length) {
            const pdCustomerQuery = utils.pdCsutomerQuery(items6)
            let esPDResponse = await getEntityDetailES('-elasticsearch', 'POST', pdCustomerQuery)
            let ESformatedData = formatESPDCustomerDetailData(esPDResponse.data)
            pdCustomerDetail = ESformatedData
        }


        detailedViews.forEach(element => {
            if (utils.isEmpty(element.InputParameters.productDivision) && utils.isEmpty(element.InputParameters.customer)) {
                element.InputParameters.group = parentObj.group;
                element.InputParameters.group.cxBenchmark = parentObj.group.cxGroupBenchmark;
                element.InputParameters.group.casBenchmark = parentObj.group.casGroupBenchmark;

                if (element.ViewType === 2 || element.ViewType === 9) {
                    if (groupDetail) {
                        element.InputParameters.cxScoreDetails = groupDetail
                    }
                }
            } else if (!utils.isEmpty(element.InputParameters.productDivision) && utils.isEmpty(element.InputParameters.customer) && utils.isEmpty(element.InputParameters.sbs)) {
                const productDivisions = parentObj.group.productDivisions.filter(_element => _element.code === element.InputParameters.productDivision.code)
                if (productDivisions) {
                    element.InputParameters.productDivision = productDivisions[0]
                    element.InputParameters.productDivision.cxBenchmark = parentObj.group.cxGroupBenchmark;
                    element.InputParameters.productDivision.casBenchmark = parentObj.group.casGroupBenchmark;

                    if (element.ViewType === 2 || element.ViewType === 9) {
                        let sltdPDs = pdDetail.filter(item => item.code === element.InputParameters.productDivision.code)
                        if (sltdPDs) {
                            element.InputParameters.cxScoreDetails = sltdPDs[0]
                        }
                    }
                }
            } else if (utils.isEmpty(element.InputParameters.productDivision) && !utils.isEmpty(element.InputParameters.customer) && utils.isEmpty(element.InputParameters.sbs)) {
                let groupCustomer = (parentObj && parentObj.group && parentObj.group.customers)?parentObj.group.customers:[]
                const customers = groupCustomer.filter(_element => _element.code === element.InputParameters.customer.code)
                if (customers.length>0) {
                    element.InputParameters.customer = customers[0]
                    element.InputParameters.customer.cxBenchmark = parentObj.group.cxGroupBenchmark;
                    element.InputParameters.customer.casBenchmark = parentObj.group.casGroupBenchmark;

                    if (element.ViewType === 2 || element.ViewType === 9) {
                        let sltdPDs = customerDetail.filter(item => item.code === element.InputParameters.customer.code)
                        if (sltdPDs) {
                            element.InputParameters.cxScoreDetails = sltdPDs[0]
                        }
                    }
                }else{
                    if (element.ViewType === 2 || element.ViewType === 9) {
                        let sltdPDs = customerDetail.filter(item => item.code === element.InputParameters.customer.code)
                        if (sltdPDs) {
                            element.InputParameters.cxScoreDetails = sltdPDs[0]
                        }
                    }
                }
            } else if (!utils.isEmpty(element.InputParameters.productDivision) && !utils.isEmpty(element.InputParameters.customer) && utils.isEmpty(element.InputParameters.sbs)) {

                let productDivisions = pdParentObj.filter(_element => _element.code === element.InputParameters.productDivision.code)
                if (productDivisions && productDivisions.length) {
                    element.InputParameters.productDivision = {
                        ...element.InputParameters.productDivision,
                        ...productDivisions[0]
                    }
                    element.InputParameters.productDivision.cxBenchmark = parentObj.group.cxProductDivisionBenchmark;
                    element.InputParameters.productDivision.casBenchmark = parentObj.group.casProductDivisionBenchmark;
                    let customers = productDivisions[0].customers.filter(_element => _element.code === element.InputParameters.customer.code)
                    if (customers && customers.length) {
                        element.InputParameters.customer = customers[0]
                        element.InputParameters.customer.cxBenchmark = productDivisions[0].cxCustomerBenchmark;
                        element.InputParameters.customer.casBenchmark = productDivisions[0].casCustomerBenchmark;

                        if (element.ViewType === 2 || element.ViewType === 9) {
                            let finalresult = pdCustomerDetail.filter(items=>{
                                return (items.custDetails.code === element.InputParameters.cxScoreDetails.code && items.custDetails.product_division_code === element.InputParameters.cxScoreDetails.product_division_code)
                            })
                       
                            if (finalresult && finalresult.length) {
                                    element.InputParameters.cxScoreDetails = finalresult[0].custDetails;
                                }
                            }
                    }
                }
            } else if (!utils.isEmpty(element.InputParameters.productDivision) && !utils.isEmpty(element.InputParameters.sbs)) {
                let productDivisions = pdSBSObj.filter(_element => _element.code === element.InputParameters.productDivision.code)
                if (productDivisions && productDivisions.length) {
                    element.InputParameters.productDivision = {
                        ...element.InputParameters.productDivision,
                        ...productDivisions[0]
                    }
                    element.InputParameters.productDivision.cxBenchmark = parentObj.group.cxProductDivisionBenchmark;
                    element.InputParameters.productDivision.casBenchmark = parentObj.group.casProductDivisionBenchmark;

                    let businessSegments = productDivisions[0].businessSegments.filter(_element => _element.code === element.InputParameters.sbs.code)
                    if (businessSegments && businessSegments.length) {
                        element.InputParameters.sbs = businessSegments[0]
                        element.InputParameters.sbs.cxBenchmark = businessSegments[0].cxSbsBenchmark;
                        element.InputParameters.sbs.casBenchmark = businessSegments[0].casSbsBenchmark;

                        if (element.ViewType === 2 || element.ViewType === 9) {
                            let finalresult = sbsDetail.reduce(items=>{
                                return items.businessUnits.filter(res=>{
                                    return res.code === element.InputParameters.sbs.code
                                })
                            })
                            if (finalresult && finalresult.length) {
                                    element.InputParameters.cxScoreDetails = finalresult[0];
                                }
                        }
                    }
                }
            }
        })
    }

    let rest = favorites.filter(element => (
        element.ViewType === 3 || element.ViewType === 4 || element.ViewType === 5 ||
        element.ViewType === 6 || element.ViewType === 7 || element.ViewType === 8))
    detailedViews.push(...rest)
    detailedViews.sort(utils.sorting('CreatedDateTime', 'date', false))
    return detailedViews
    // favorites.sort(utils.sorting('CreatedDateTime', 'date', false))
    // return favorites
}