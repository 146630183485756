import { connect } from 'react-redux'
import Home from '../components/home/Home'

const mapStateToProps = state => {
    return {
        selectedProductDivision: state.ProductDivisionReducer ? state.ProductDivisionReducer.data : null,
        customer: state.CustomerReducer ? state.CustomerReducer.data : null,
        cxScoreDetails: state.KPIReducer ? state.KPIReducer.cxScoreDetails : null,
        selectedKPI: state.KPIReducer ? state.KPIReducer.selectedKPI : null,
        nba: state.NBAReducer ? state.NBAReducer.nba : null,
        group: state.GroupReducer ? state.GroupReducer.data : null,
        config: state.KpiConfigReducer ? state.KpiConfigReducer.data : null,
        parentKPI: state.KPIWeightageReducer ? state.KPIWeightageReducer.parentKPI : null
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
