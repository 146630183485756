import { connect } from 'react-redux'
import ProductDivision from '../pages/product-division/ProductDivision';
import { SET_SBS, SET_CUSTOMER } from '../store/constant';

const mapStateToProps = state => {
    return {
        selectedProductDivision: state.ProductDivisionReducer ? state.ProductDivisionReducer.data : null
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSelectedSBS: (data) => dispatch({
            type: SET_SBS,
            data: data
        }),
        setCustomer: (data) => dispatch({
            type: SET_CUSTOMER,
            data: data
        }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDivision)
