import React, { useState, useEffect } from "react";
import style from "./insight.module.scss";
function Insights(props) {
   const insights = [...props.insights];
  const [isIndex, setisIndex] = useState(insights.length - 1);
  const closePannel = () => {
    props.closePannel(true);
  };
  const getFirstdata = () => {
    if (insights.length) {
      let data = insights.filter((val, i) => {
        return i === insights.length - 1;
      });
     let sortData= data[0].values.sort(function(a, b) {
        return a.detail_view_id - b.detail_view_id;
      });
      let setData = sortData;
      return setData;
    }
  };
  const [insightData, setinsightData] = useState(getFirstdata);
  const setFilter = (index) => {
    setisIndex(index);
    let data = insights.filter((val, i) => {
      return i === index;
    });
    let setData = data[0].values.sort(function(a, b) {
      return a.detail_view_id - b.detail_view_id;
    });;
    setinsightData(setData);
  };
  return (
    <div
      className={
        props.isOpen
          ? `${style.inSightPannel}`
          : `${style.inSightPannel} ${style.closePannel}`
      }
    >
      <div className={style.pannelHeader}>
        Insights Details
        <div className={style.closeButton} onClick={closePannel}></div>
      </div>
      {insights.length > 0 && (
        <div className={style.filterTab}>
          {insights.map((val, index) => (
            <div
              className={
                isIndex === index
                  ? `${style.tab} ${style.activeTab}`
                  : `${style.tab}`
              }
              key={index}
              onClick={() => setFilter(index)}
            >
              {val.insight_period}
            </div>
          ))}
        </div>
      )}
      <div className={style.insightCardHolder}>
        {insights.length > 0 &&
          insightData.map((val, i) => (
            <div className={style.cardHolder} key={i}>
              <h3>{val.detail_view_name}</h3>
              {val.insight_messages.sort((a,b)=>{
                return a.insight_id - b.insight_id;
              }).map((val, i) => (
                <ul key={i}>
                  <li>{val.insight_message}</li>
                </ul>
              ))}
            </div>
          ))}
        {insights.length === 0 && <div>No Insights Available</div>}
      </div>
    </div>
  );
}

export default Insights;