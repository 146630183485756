import React from "react";
import styles from "./favoriteIcon.module.scss";

const FavoriteIcon = (props) => {
    let { isFavorite } = props

    const clickOnTooltip = (e) => {
        e.stopPropagation()
    }

    return (<div id="favorite"
        className={`${styles.favorite} ${styles.tooltip} ${(isFavorite) ? `${styles.favoritePin}` : `${styles.favoriteUnpin}`}`}
        onClick={() => props.addToFavorite(isFavorite)}><span onClick={(e) => clickOnTooltip(e)} className={styles.tooltiptext}>{isFavorite ? "Unpin from Favorites" : "Pin to Favorites"}</span>
    </div>)
}

export default FavoriteIcon