import React from 'react'
import styles from './search-result.module.scss'
import * as utils from '../../utils/utils';

const SearchResult = (props) => {

    const { columns, searchResult, ascending } = props;

    return (
        <React.Fragment>
            {
                searchResult && searchResult.length ?
                    <div className={styles.tableResponsive}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    {columns.map((item, index) => (
                                        <th
                                            style={index === 0 ? { paddingLeft: "50px" } : null}
                                            key={index}
                                            onClick={() => props.listSorting(item)}
                                        >
                                            <span className={item.sortable ? "pointer" : null}>
                                                {item.name}
                                            </span>
                                            {
                                                item.isActive && <span
                                                    className={ascending ? styles.sortIcnInvert : styles.sortIcn}
                                                ></span>
                                            }
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {searchResult.map((item, index) => (
                                    <tr
                                        onClick={() => props.setCustomer(item)}
                                        key={index}
                                        className={'pointer'}
                                    >
                                        <td style={{ paddingLeft: "50px" }}>{utils.capitalize(item.name)}</td>
                                        <td>{utils.capitalize(item.region)}</td>
                                        <td>{item.country}</td>
                                        <td>{utils.capitalize(item.state)}</td>
                                        <td>{utils.capitalize(item.city)}</td>
                                        <td>{item.group_productdivision}</td>
                                        <td>
                                            <div>
                                                {
                                                    !item.cxScore.score ? <span className={styles.dash}>-</span> :
                                                        <React.Fragment>
                                                            <span className={item.cxScore.score >= item.cxScore.benchmark ?
                                                                `${styles.value} ${styles.green}` : `${styles.value} ${styles.red}`} >
                                                                {Number(item.cxScore.score.toFixed(0))}</span>
                                                            <span className={styles.points}>
                                                                {Math.abs(Number(item.cxScore.score - item.cxScore.lastQuarterValue).toFixed(0))}
                                                            </span>
                                                            <span>Points</span>
                                                            <span className={item.cxScore.score >= item.cxScore.lastQuarterValue ?
                                                                styles.upArrow : styles.downArrow}></span>
                                                        </React.Fragment>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {
                                                    !item.responseCount ? <span className={styles.dash}>-</span> :
                                                        <React.Fragment>
                                                            <span className={item.cas.score >= item.cas.benchmark ?
                                                                `${styles.value} ${styles.green}` : `${styles.value} ${styles.red}`}>
                                                                {Number(item.cas.score.toFixed(0))}</span>
                                                            <span className={styles.points}>
                                                                {Number(item.cas.score - item.cas.lastQuarterValue).toFixed(0)}
                                                            </span>
                                                            <span>Points</span>
                                                            <span className={item.cas.score >= item.cas.lastQuarterValue ?
                                                                styles.upArrow : styles.downArrow}></span>
                                                        </React.Fragment>
                                                }

                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div> :
                    <div className={styles.nodata}>No data found!. Please modify your search</div>
            }
        </React.Fragment>
    )
}

export default SearchResult