import React, { useState, useRef } from "react";
import Chevron from "../chevron/Chevron";
import "./accordion.scss";
import RevenueDistribution from "../attributes/revenue-distribution/RevenueDistribution";
import Orders from "../attributes/orders/Orders";
import BarChart from "../attributes/bar-chart/BarChart";
import Customers from "../attributes/customers/Customers";
import Credit from '../attributes/credit/Credit'
import {Footer, Header} from "../attributes/bar-chart-footer/BarChartFooter";

function Accordion(props) {

  const { type, title } = props
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon rotate");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion__icon rotate" : "accordion__icon"
    );
  }

  let accordionBody = "";

  let keys = []
  let values = []
  let pctGrowth = []
  let total = 0
  switch (type) {
    case "customerSegment":
      accordionBody = (
        <RevenueDistribution {...props} name="customerSegments" />
      );
      break;
    case "productDivision":
      accordionBody = (
        <RevenueDistribution {...props} name="productDivisions" />
      )
      break;
    case "businessUnits":
      accordionBody = (
        <RevenueDistribution {...props} name="businessUnits" />
      )
      break;
    case 'regions':
      accordionBody = (
        <RevenueDistribution {...props} name="regions" />
      )
      break;
    case "openOrder":
      accordionBody = <Orders {...props.open_backorder} />
      break;
    case "credit":
      accordionBody = <Credit {...props.credit} />
      break;
    case "covid":
      const skus = (props.skus)?[...props.skus]:[]
      keys = skus.map(element => element.name)
      values = skus.map(element => element.revenue)
      pctGrowth = skus.map(element => (((element.revenue - element.lastRevenue)
        / (element.revenue + element.lastRevenue)) * 100).toFixed(2))
      accordionBody = <React.Fragment>
        <Header y2axis={'Growth %'} />
        <BarChart
          id="covid"
          keys={keys}
          values={values}
          height={150}
          pctGrowth={pctGrowth}
          y2axis={'Growth'} />
        <Footer y1axis={'Revenue ($ in M)'}  />
      </React.Fragment>
      break;
    case "customerRevenue":
      const customers = (props.customers)?[...props.customers]:[]
      keys = customers.map(element => element.name)
      values = customers.map(element => element.revenue)
      total = customers.reduce((sum, element) => sum += element.revenue, 0)
      pctGrowth = customers.map(element => (Number((element.revenue / total) * 100)).toFixed(2))
      accordionBody = <React.Fragment>
        <Header y2axis={'Contribution %'} />
        <BarChart
          id="customer"
          keys={keys}
          values={values}
          height={250}
          pctGrowth={pctGrowth}
          y2axis={'Contribution'} />
        <Footer y1axis={'Revenue ($ in M)'}  />
      </React.Fragment>
      break;
    case "sgns":
      const sgns = (props.sgns)?[...props.sgns]:[]
      keys = sgns.map(element => element.name)
      values = sgns.map(element => element.revenue)
      total = sgns.reduce((sum, element) => sum += element.revenue, 0)
      pctGrowth = sgns.map(element => (Number((element.revenue / total) * 100)).toFixed(2))
      accordionBody = <React.Fragment>
        <Header y2axis={'Contribution %'} />
        <BarChart
          id="sgns"
          keys={keys}
          values={values}
          height={250}
          pctGrowth={pctGrowth}
          y2axis={'Contribution'} />
        <Footer y1axis={'Revenue ($ in M)'} />
      </React.Fragment>
      break;
    case "customerGained":
      accordionBody = <Customers custGained={props.custGained} />
      break;
    default:
      break;
  }

  return (
    <div className="accordion__section">
      <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <p className="accordion__title">{title}</p>
        <Chevron className={`${setRotate}`} width={10} fill={"#777"} />
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        {accordionBody}
      </div>
    </div>
  );
}

export default Accordion;
