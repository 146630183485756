import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import PowerBi from '../../../components/powerbi/PowerBi';
import * as favUtils from '../../../utils/favorite.utils';
import * as utils from '../../../utils/utils';
import style from '../nba.module.scss';
import { Spin } from 'antd';
import Insights from '../../../components/insights/Insights';

const ChildView = props => {
    const [insights] = useState(props.nba.insights ? [...props.nba.insights] : [])
    const breadCrumb = [...props.breadCrumb]
    const favInput = JSON.parse(JSON.stringify(props.favInput));
    let [FavoriteId, setFavoriteId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isOpen, setisOpen] = useState(false)

    if (!breadCrumb.filter(element => element.value.toLowerCase() === getNBAChildViewName().toLowerCase()).length) {
        breadCrumb.push(
            {
                value: getNBAChildViewName(),
                path: "/",
            }
        )
    }

    const goBack = () => {
        props.history.push('/dashboard/nba/primaryview')
    }

    const getCurrentBreadCrumb = () => {
        breadCrumb.map(element => {
            if (element.value.toLowerCase() === getNBAChildViewName().toLowerCase()) {
                element.path = "/dashboard/nba/childview"
            }
        })
        return breadCrumb;
    }

    const history = () => {
        props.setNBABreadCrumb(getCurrentBreadCrumb())
        props.history.push('/dashboard/nba/history')
    }

    function getNBAChildViewName() {
        return utils.getNBAChildViewName(props.nba.name)
    }

    async function getFavorites() {
        const favorites = await favUtils.getFavorites()
        props.setFavorite(favorites)
    }

    async function addToFavorite(isAlreadyFavorite) {
        try {
            setLoading(true)
            if (!isAlreadyFavorite) {
                favInput.UserId = utils.getUserEmail()
                favInput.ViewType = 7
                favInput.InputParameters.productDivision = props.selectedProductDivision;
                favInput.InputParameters.customer = props.selectedCustomer;
                favInput.InputParameters.sbs = props.selectedSBS;
                favInput.InputParameters.breadcrumb = props.breadCrumb;
                favInput.InputParameters.nba = props.nba;
                const FavoriteId = await favUtils.addFavorites(favInput)
                setFavoriteId(FavoriteId)
            } else {
                await favUtils.removeFavorites(FavoriteId)
                setFavoriteId(null)
            }
            setLoading(false)
            getFavorites()
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    }

    useEffect(() => {
        setFavoriteId(favUtils.chkIsFavorite(
            props.favorites,
            7,
            props.selectedProductDivision,
            props.selectedCustomer,
            props.selectedSBS,
            '',
            props.nba))
    }, [])
    const openPannel = () => {
        setisOpen(true)
    }
    const closePannel = () => {
        setisOpen(false)
    }
    return (
        <Spin spinning={loading}>
            <div className={style.primarycontainer}>
                <BreadCrumb
                    list={breadCrumb}
                    addToFavorite={addToFavorite}
                    isFavorite={FavoriteId ? true : false}
                    {...props} />
                <div className={style.layoutbx}>
                    <div className={style.childheading}>
                        <div className={style.backBtn} onClick={goBack}>Back</div>
                        <span className={style.childheadingtext}>
                        </span>
                        <div className={`${style.nextbtn} ${style.right167}`} onClick={openPannel}>Insights</div>
                        <div className={style.nextbtn} onClick={history}>View History</div>
                    </div>
                    <div className={isOpen ? `${style.ifrmHolder} ${style.pRight31}` : `${style.ifrmHolder}`}>
                        <PowerBi view={props.nba.childView} />
                    </div>
                    <Insights closePannel={closePannel} isOpen={isOpen} insights={insights} />
                </div>
            </div>

        </Spin>
    );
}

export default ChildView

