import * as consts from "../../utils/constant";
import * as utils from "../../utils/utils";

export const getCASReport = (props) => {
    const { type } = props

    const getProductDivisionReport = (ReportURL, code) => {
        return `${ReportURL}&filter=CAS/product_division_code eq '${code}'`
    }

    const getSBSReport = (ReportURL, divisionCode, code) => {
        return `${ReportURL}&filter=CAS/product_division_code eq '${divisionCode}' and CAS/business_segment_code eq '${code}'`
    }

    const getCustomerReport = (ReportURL, code) => {
        return `${ReportURL}&filter=CAS/national_sales_group_name eq '${code}'`
    }

    const getCustomer_PDReport = (ReportURL, divisionCode, code) => {
        return `${ReportURL}&filter=CAS/product_division_code eq '${divisionCode}' and CAS/national_sales_group_name eq '${code}'`;
    }

    const getReportLink = (view) => {
        switch (view) {
            case 'overall':
                return process.env.REACT_APP_CAS_OVERALL_LINK
            case 'survey':
                return process.env.REACT_APP_CAS_SURVEY_RESULTS_LINK
            case 'childview':
                return process.env.REACT_APP_CAS_CHILD_LINK
            case 'painpoints':
                return process.env.REACT_APP__PAIN_POINTS_LINK
            default:
                return ''
        }
    }

    if (!utils.isEmpty(props.selectedCustomer)) {
        if (!utils.isEmpty(props.selectedProductDivision)) {
            const { code: divisionCode } = props.selectedProductDivision
            const { code } = props.selectedCustomer
            return getCustomer_PDReport(getReportLink(type), divisionCode, code)
        } else {
            const { code } = props.selectedCustomer
            return getCustomerReport(getReportLink(type), code)
        }
    }
    else if (!utils.isEmpty(props.selectedSBS)) {
        const { code } = props.selectedSBS;
        const { code: divisionCode } = props.selectedProductDivision;
        return getSBSReport(getReportLink(type), divisionCode, code)
    }
    else if (!utils.isEmpty(props.selectedProductDivision)) {
        const { code } = props.selectedProductDivision
        return getProductDivisionReport(getReportLink(type), code)
    } else {
        return getReportLink(type)
    }
}