import React, { useEffect, useState } from "react";
import { useHttpGet } from "../../components/hooks/useHttp";
import Product from "../../components/product/Product";
import Favorite from "../../components/favorite/Favorite";
import Title from "../../components/title/Title";
import styles from "./dashboard.module.scss";
import Carousel from "react-elastic-carousel";
import { Spin } from 'antd';
import * as utils from "../../utils/utils";
import * as favUtils from "../../utils/favorite.utils";
import * as constants from "../../../src/utils/constant";

const Dashboard = (props) => {

  const [favorites, setFavorites] = useState([])
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const viewFavorites = (element) => {
    const { productDivision, customer, sbs, cxScoreDetails, breadcrumb, kpi, nba, group, sasParent } = element.InputParameters;
    if (element.ViewType === 1) {
      if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs)) {
        props.setProductDivision(productDivision)
        props.history.push('/dashboard/productdivisiondetail');
      } else if (utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs)) {
        props.setCustomer(customer)
        props.history.push("/dashboard/customerdetail");
      } else if (!utils.isEmpty(productDivision) && !utils.isEmpty(sbs) && utils.isEmpty(customer)) {
        props.setProductDivision(productDivision)
        props.setSelectedSBS(sbs)
        props.history.push('/dashboard/sbsdetail')
      } else if (!utils.isEmpty(productDivision) && utils.isEmpty(sbs) && !utils.isEmpty(customer)) {
        props.setProductDivision(productDivision)
        props.setCustomer(customer)
        props.history.push("/dashboard/customerdetail");
      } else {
        props.setGroup(group)
        props.history.push('/dashboard/groupdetail');
      }
    } else if (element.ViewType === 2 || element.ViewType === 9) {

      if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs)) {
        props.setProductDivision(productDivision)
      } else if (utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs)) {
        props.setCustomer(customer)
      } else if (!utils.isEmpty(productDivision) && !utils.isEmpty(sbs) && utils.isEmpty(customer)) {
        props.setProductDivision(productDivision)
        props.setSelectedSBS(sbs)
      } else if (!utils.isEmpty(productDivision) && utils.isEmpty(sbs) && !utils.isEmpty(customer)) {
        props.setProductDivision(productDivision)
        props.setCustomer(customer)
      }

      props.setCXScoreData(cxScoreDetails);
      props.setBreadCrumb(breadcrumb);
      props.setSASParent(sasParent);
      if (element.ViewType === 2) {
        if (sasParent === 'cx')
          props.history.push("/dashboard/score");
        else
          props.history.push("/dashboard/score/cas");
      } else {
        props.history.push("/dashboard/cas-childview");
      }
    } else if (element.ViewType === 3 || element.ViewType === 4 || element.ViewType === 5) {

      if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs)) {
        props.setProductDivision(productDivision)
      } else if (utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs)) {
        props.setCustomer(customer)
      } else if (!utils.isEmpty(productDivision) && !utils.isEmpty(sbs) && utils.isEmpty(customer)) {
        props.setProductDivision(productDivision)
        props.setSelectedSBS(sbs)
      } else if (!utils.isEmpty(productDivision) && utils.isEmpty(sbs) && !utils.isEmpty(customer)) {
        props.setProductDivision(productDivision)
        props.setCustomer(customer)
      }

      props.setCXScoreData(cxScoreDetails);
      props.setSASParent('cx');

      props.setKPI(kpi)
      props.setBreadCrumb(breadcrumb);
      if (element.ViewType === 3) {
        props.history.push("/dashboard/primaryview");
      } else if (element.ViewType === 4) {
        props.history.push("/dashboard/childview");
      } else {
        props.history.push("/dashboard/history");
      }
    } else if (element.ViewType === 6 || element.ViewType === 7 || element.ViewType === 8) {

      if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs)) {
        props.setProductDivision(productDivision)
      } else if (utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs)) {
        props.setCustomer(customer)
      } else if (!utils.isEmpty(productDivision) && !utils.isEmpty(sbs) && utils.isEmpty(customer)) {
        props.setProductDivision(productDivision)
        props.setSelectedSBS(sbs)
      } else if (!utils.isEmpty(productDivision) && utils.isEmpty(sbs) && !utils.isEmpty(customer)) {
        props.setProductDivision(productDivision)
        props.setCustomer(customer)
      }

      props.setNBA(nba)
      props.setNBABreadCrumb(breadcrumb);
      if (element.ViewType === 6) {
        props.history.push("/dashboard/nba/primaryview");
      } else if (element.ViewType === 7) {
        props.history.push("/dashboard/nba/childview");
      } else {
        props.history.push("/dashboard/nba/history");
      }
    }
  }

  const processData = (fetchedData) => {
    const { group } = fetchedData;
    group.cxBenchmark = group.cxGroupBenchmark;
    group.casBenchmark = group.casGroupBenchmark;

    const productDivisions = [...group.productDivisions];
    productDivisions.forEach((element) => {
      element.name = element.code;
      element.cxBenchmark = group.cxProductDivisionBenchmark;
      element.casBenchmark = group.casProductDivisionBenchmark;
    });
    productDivisions.sort(utils.sorting('name', 'string', true))

    const customers = [...group.customers];
    customers.forEach((element) => {
      element.name = utils.capitalize(element.name.trim())
      element.cxBenchmark = group.cxCustomerBenchmark;
      element.casBenchmark = group.casCustomerBenchmark;
    });
    customers.sort(utils.sorting('name', 'string', true))
    return { group, productDivisions, customers }

  };

  // const [isLoading, data] = useHttpGet(
  //   process.env.REACT_APP_DASHBOARD_DATA,
  //   [],
  //   processData
  // );
  //const data = {}
  const showGroupDetail = (item) => {
    props.setGroup(item)
    props.history.push("/dashboard/groupdetail");
  }

  const showCustomerDetail = (item) => {
    props.setCustomer(item)
    props.history.push("/dashboard/customerdetail");
  }

  const setProductDivision = (item) => {
    props.setProductDivision(item)
    props.history.push("/dashboard/productdivision");
  }

  async function getFavorites() {
    try {
      setLoading(true)
      const favorites = await favUtils.getFavorites()
      const processedFav = await favUtils.processFavorites(favorites)
      setFavorites(processedFav)
      props.setFavorite(favorites)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }
  const getGroupData = (result) => {
    const group = result.hits.hits[0];
    const customerFilterdArr = group.inner_hits['group.customers'].hits
    let new_array = []
    if (customerFilterdArr && customerFilterdArr.hits && customerFilterdArr.hits.length > 0) {
      new_array = customerFilterdArr.hits.map(function callback(element) {
        let custObj = {};
        custObj['name'] = (element.fields['group.customers.name.keyword'])?element.fields['group.customers.name.keyword'][0]:'-';
        custObj.cxScore = (element.fields['group.customers.cxScore'])?element.fields['group.customers.cxScore'][0]:'-';
        custObj.lastQuarterCxScore = (element.fields['group.customers.lastQuarterCxScore'])?element.fields['group.customers.lastQuarterCxScore'][0]:'-';
        custObj.code = (element.fields['group.customers.code.keyword'])?element.fields['group.customers.code.keyword'][0]:'-';
        custObj.id = (element.fields['group.customers.id'])?element.fields['group.customers.id'][0]:'-';
        custObj.name = (element.fields['group.customers.name.keyword'])?element.fields['group.customers.name.keyword'][0]:'-';
        custObj.cas = (element.fields['group.customers.cas'])?element.fields['group.customers.cas'][0]:'-';
        custObj.volatility = (element.fields['group.customers.volatility.keyword'])?element.fields['group.customers.volatility.keyword'][0]:'-';
        custObj.recommendation = (element.fields['group.customers.recommendation.keyword'])?element.fields['group.customers.recommendation.keyword'][0]:'-';
        custObj.whiteGlove = (element.fields['group.customers.whiteGlove.keyword'])?element.fields['group.customers.whiteGlove.keyword'][0]:'-';
        return custObj
      })
    }
    group['_source'].group.customers = new_array;
    return group['_source'];
  }
  useEffect(() => {
    const input = {
      name: constants.Group_Dashboard_Index,
      queryParams: {
        "_source": {
          "excludes": ["*.customers"]
        },
        "query": {
          "nested": {
            "path": "group.customers",
            "query": {
              "match": {
                "group.customers.whiteGlove": "true"
              }
            },
            "inner_hits": {
              "_source": false,
              "size": 50,
              "docvalue_fields": [
                "group.customers.code.keyword",
                "group.customers.name.keyword",
                "group.customers.id",
                "group.customers.cxScore",
                "group.customers.lastQuarterCxScore",
                "group.customers.cas",
                "group.customers.volatility.keyword",
                "group.customers.recommendation.keyword",
                "group.customers.whiteGlove.keyword"
              ]
            }
          }
        }
      }
    }
    setIsLoading(true)
    utils.getDataES('-elasticsearch', 'POST',input)
      .then(resp => {
        let groupESData = getGroupData(resp.data);
        let result = processData(groupESData)
        setData(JSON.parse(JSON.stringify(result)))
        setIsLoading(false)
      }).catch(err =>  setIsLoading(false))
    props.setCustomer({})
    props.setSelectedSBS({})
    props.setProductDivision({})
    getFavorites()
  }, [])

  async function removeFavorite(e, favorite) {
    try {
      setLoading(true)
      e.stopPropagation()
      await favUtils.removeFavorites(favorite.FavoriteId)
      await getFavorites()
      setLoading(false)
    } catch (err) {
      console.log(err);
      setLoading(false)
    }
  }

  const productBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 }
  ]
  const customerBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 5, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 6 },
    { width: 1750, itemsToShow: 7 }
  ]
  return (
    <Spin spinning={isLoading || loading} >
      <div className={styles.container}>
        <div className={styles.productsContainer}>
          {
            data && data.group ? (
              <div >
                <Title value="Group" count={null} />
                <Product
                  product={data.group}
                  {...props}
                  type="product"
                  showDetail={() => showGroupDetail(data.group)} />
              </div>
            ) : null
          }
          {data && data.productDivisions && data.productDivisions.length ? (
            <div className={styles.productBox} >
              <Title value="Product Divisions​" count={data.productDivisions.length} />
              <div className="outerGrid">
                <Carousel breakPoints={productBreakPoints}>
                  {data.productDivisions.map((item, index) => {
                    return (
                      <Product
                        key={index}
                        product={item}
                        {...props}
                        type="productdivision"
                        showDetail={setProductDivision}
                      />
                    );
                  })}
                </Carousel>
              </div>
            </div>
          ) : null}
        </div>
        <div className={styles.productsContainer} >
          {data && data.customers && data.customers.length ? (
            <div className={`row no-gutters ${styles.customerLayoutBx} ${styles.customerList}`}>
              <Title value="Customers" count={data.customers.length} />
              <Carousel breakPoints={customerBreakPoints}>
                {data.customers.map((item, index) => {
                  return (
                    <Product
                      key={index}
                      product={item}
                      {...props}
                      type="customer"
                      showDetail={showCustomerDetail}
                    />
                  );
                })}
              </Carousel>
            </div>
          ) : null}
        </div>
        <div className={styles.productsContainer} >
          {favorites.length ? (
            <div className={styles.customerLayoutBx}>
              <Title value="Favorites" count={favorites.length} />
              <Carousel breakPoints={customerBreakPoints}>
                {favorites.map((item, index) => {
                  return (
                    <Favorite
                      key={index}
                      favorite={item}
                      {...props}
                      type="customer"
                      removeFavorite={removeFavorite}
                      showDetail={viewFavorites}
                    />
                  );
                })}
              </Carousel>
            </div>
          ) : null}
        </div>
      </div>
    </Spin >)
};

export default Dashboard;
