import React, { useEffect, useState } from 'react';
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import styles from "./detailedview.module.scss";
import CXScore from "./Tiles/CXScore";
import CAS from "./Tiles/CAS";
import { Spin } from 'antd';
import CXList from './Tiles/CXList';
import * as utils from '../../utils/utils';
import * as consts from '../../utils/constant';
import * as favUtils from '../../utils/favorite.utils'
import Attributes from '../attributes/group';
import * as constants from "../../../src/utils/constant";

const Group = (props) => {

    const favInput = JSON.parse(JSON.stringify(props.favInput));
    let [FavoriteId, setFavoriteId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [groupDetails, setGroupDetails] = useState(null)
    const recommended = props.group.recommendation.toLocaleLowerCase()

    const breadcrumbList = [
        {
            value: "Dashboard",
            path: "/dashboard",
        },
        {
            value: "Life Sciences",
            path: "/",
        },
    ];
    const getGroupData = (result) => {
        let group = []
        group = (result.hits && result.hits.hits && result.hits.hits.length > 0) ? result.hits.hits[0] : [];
        if (group && group['_source'] && group['_source'].group && group['_source'].group.length>0){
            return group['_source'].group[0];
        }
        return group; 
    }
    useEffect(() => {
        setLoading(true)
        const input = {
            name: constants.Group_ScoreDetails_Index,
            queryParams: {
                "query": {
                    "match_all": {}
                  }
                }
            }
        setLoading(true)
          utils.getDataES('-elasticsearch', 'POST',input)
            .then(resp => {
              let groupESData = getGroupData(resp.data);
              setGroupDetails(groupESData)
              setLoading(false)
            }).catch(err =>  setLoading(false))
    }, [])

    const getSortedCXList = (cxList) => {
        let list = (cxList)?[...cxList]:[]
        list.forEach(element => {
            element.difference = element.score - element.lastQuarterValue
            element.differenceCent = ((element.score - element.lastQuarterValue) / (element.lastQuarterValue)) * 100
        });
        return list.sort(utils.sorting('differenceCent', 'number', false))
    }

    let cxScore, cas, cxList, cxListDP;
    if (groupDetails) {
        cxScore = groupDetails.cxScore;
        if(groupDetails && groupDetails.cxList && groupDetails.cxList.length>0){
            cxListDP = groupDetails.cxList.find(item=>item.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase());
            cxScore.dataPoints = (cxListDP && cxListDP.dataPoints)?cxListDP.dataPoints:''
        }
        cas = groupDetails.cas;
        cxList = getSortedCXList(groupDetails.cxList);
    }

    const createBreadCrumb = () => {
        breadcrumbList[breadcrumbList.length - 1].path = '/dashboard/groupdetail'
        return breadcrumbList;
    }

    const viewScore = (score) => {
        props.setCXScoreData(groupDetails);
        props.setBreadCrumb(createBreadCrumb());
        if (score === 'cas') {
            props.setSASParent('cas');
            props.history.push("/dashboard/score/cas");
        } else {
            props.setSASParent('cx');
            props.history.push("/dashboard/score");
        }
    };

    const getInsights = (nba) => {
        if (groupDetails.nba_insights) {
            let insights = groupDetails.nba_insights.filter(element => element.metrics_name.toLowerCase() === nba.toLowerCase())
            if (insights && insights.length) {
                return insights[0].insights
            }
            return []
        }
        return []
    }

    const primaryView = (nba) => {
        let _nba = ''
        switch (nba) {
            case consts.IMPROVE_INVOICE_AGING:
                _nba = {
                    name: nba,
                    primaryView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics/product_division_code eq '-' and Salesmetrics/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_CHILD_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-'`,
                    historyView: process.env.REACT_APP_IMPROVE_INVOICE_AGING_HISTORY_LINK,
                    insights: getInsights(consts.IMPROVE_INVOICE_AGING)
                }
                props.setNBA(_nba)
                break;
            case consts.REDUCE_RETURN_PERCENTAGE:
                _nba = {
                    name: nba,
                    primaryView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_PRIMARY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-'`,
                    childView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-'`,
                    historyView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_HISTORY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Rank/return_amount_rank le 5`,
                    insights: getInsights(consts.REDUCE_RETURN_PERCENTAGE)
                }
                props.setNBA(_nba)
                break;
            case consts.IMPROVE_PRODUCT_AVAILABILITY:
                _nba = {
                    name: nba,
                    primaryView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_PRIMARY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics/product_division_code eq '-' and Salesmetrics/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_HISTORY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Rank/backorder_rank le 5`,
                    insights: getInsights(consts.IMPROVE_PRODUCT_AVAILABILITY)
                }
                props.setNBA(_nba)
                break;
            case consts.NEW_IMPROVE_ORDER_VELOCITY:
                _nba = {
                    name: nba,
                    primaryView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_PRIMARY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics/product_division_code eq '-' and Salesmetrics/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_HISTORY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Rank/order_cycle_time_rank le 5`,
                    insights: getInsights(consts.NEW_IMPROVE_ORDER_VELOCITY)
                }
                props.setNBA(_nba)
                break;
            case consts.IMPROVE_OTIF:
                _nba = {
                    name: nba,
                    primaryView: `${process.env.REACT_APP_IMPROVE_OTIF_PRIMARY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics/product_division_code eq '-' and Salesmetrics/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_OTIF_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_OTIF_HISTORY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Rank/revenue_rank le 5`,
                    insights: getInsights(consts.IMPROVE_OTIF)
                }
                props.setNBA(_nba)
                break;
            case consts.INCREASE_CREDITLINE:
                _nba = {
                    name: nba,
                    primaryView: `${process.env.REACT_APP_INCREASE_CREDITLINE_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics/product_division_code eq '-' and Salesmetrics/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '-'`,
                    childView: `${process.env.REACT_APP_INCREASE_CREDITLINE_CHILD_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '-'`,
                    historyView: `${process.env.REACT_APP_INCREASE_CREDITLINE_HISTORY_LINK}`,
                    insights: getInsights(consts.INCREASE_CREDITLINE)
                }
                props.setNBA(_nba)
                break;
            default:
                break;
        }

        props.setNBABreadCrumb(createBreadCrumb())
        props.history.push('/dashboard/nba/primaryview')
    }

    useEffect(() => {
        props.setCustomer({})
        props.setSelectedSBS({})
        props.setProductDivision({})
        setFavoriteId(favUtils.chkIsFavorite(props.favorites, 1))
    }, [])

    async function getFavorites() {
        const favorites = await favUtils.getFavorites()
        props.setFavorite(favorites)
    }

    async function addToFavorite(isAlreadyFavorite) {
        try {
            setLoading(true)
            if (!isAlreadyFavorite) {
                favInput.UserId = utils.getUserEmail()
                favInput.ViewType = 1
                favInput.InputParameters.group = props.group;
                const FavoriteId = await favUtils.addFavorites(favInput)
                setFavoriteId(FavoriteId)
            } else {
                await favUtils.removeFavorites(FavoriteId)
                setFavoriteId(null)
            }
            setLoading(false)
            getFavorites()
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    }

    const nbas = [
        {
            nba: consts.REDUCE_RETURN_PERCENTAGE,
            recommended: recommended === consts.REDUCE_RETURN_PERCENTAGE.toLowerCase() ? true : false
        },
        {
            nba: consts.IMPROVE_PRODUCT_AVAILABILITY,
            recommended: recommended === consts.IMPROVE_PRODUCT_AVAILABILITY.toLowerCase() ? true : false
        },
        {
            nba: consts.NEW_IMPROVE_ORDER_VELOCITY,
            recommended: recommended === consts.NEW_IMPROVE_ORDER_VELOCITY.toLowerCase() ? true : false
        },
        {
            nba: consts.IMPROVE_OTIF,
            recommended: recommended === consts.IMPROVE_OTIF.toLowerCase() ? true : false
        },
        {
            nba: consts.IMPROVE_INVOICE_AGING,
            recommended: recommended === consts.IMPROVE_INVOICE_AGING.toLowerCase() ? true : false
        },
        {
            nba: consts.INCREASE_CREDITLINE,
            recommended: recommended === consts.INCREASE_CREDITLINE.toLowerCase() ? true : false
        }
    ]

    return (
        <Spin spinning={loading}>
            <div className={styles.cxContainer}>
                <React.Fragment>
                    <BreadCrumb
                        list={breadcrumbList}
                        {...props}
                        addToFavorite={addToFavorite}
                        isFavorite={FavoriteId ? true : false} />
                    <div className="row no-gutters">
                        <div className={`${styles.leftContent} ${"col-sm-12 col-md-12 col-lg-8"}`}>
                            {
                                groupDetails ?
                                    <React.Fragment>
                                        <div className={styles.scoreGrid}>
                                            {cxScore && <CXScore cxScore={cxScore} {...props} viewScore={viewScore} />}
                                            {cas && <CAS cas={cas} {...props} viewScore={viewScore} />}
                                            {cxList && <CXList cxList={cxList} {...props} />}
                                        </div>
                                        <div className={styles.layoutBx}>
                                            <h2 className={styles.cxHeading}>Journey Map Score</h2>
                                            <div className={styles.underDesign}>Under Design</div>
                                        </div>
                                        <div className={styles.layoutBx}>
                                            <h2 className={styles.cxHeading}>Next Best Actions</h2>
                                            <div className={styles.nbaContainer}>
                                                {
                                                    nbas.map((element, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() => primaryView(element.nba)}
                                                            className={element.recommended ? `${styles.griditem} ${styles.recommended}` : styles.griditem}>{element.nba}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment> : null
                            }
                        </div>
                        <div className={`${styles.rightContent} ${"col-sm-12 col-md-12 col-lg-4"}`}>
                            <Attributes />
                        </div>
                    </div>
                </React.Fragment>
            </div>
        </Spin>
    );
};

export default Group;
