import React, { useEffect, useState } from 'react';
import Accordion from "../../components/accordion/Accordion";
import styles from './attributes.module.scss';
import { useHttpGet } from "../../components/hooks/useHttp";
import * as utils from '../../utils/utils';
import * as constants from "../../../src/utils/constant";

function ProductDivisionAttribute(props) {

    const [customers, setCustomers] = useState(null)
    const [customerSegment, setCustomerSegment] = useState(null)
    const [businessUnits, setBusinessUnits] = useState(null)
    const [custGained, setCustGained] = useState(null)
    const [skus, setskus] = useState(null)
    const [open_backorder, setOpen_Backorder] = useState(null)
    const [divisionDetails, setdivisionDetails] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const processData = (fetchedData) => {
        const divisionDetails = fetchedData.productDivision
            .filter(item => item.code.toLowerCase() === props.code.toLowerCase())[0]
        if (divisionDetails.revenueDistribution && divisionDetails.revenueDistribution.customerSegment) {
            const customerSegment = [...divisionDetails.revenueDistribution.customerSegment]
            setCustomerSegment([...customerSegment.sort(utils.sorting('revenue', "number", false))])
        }
        if (divisionDetails.revenueDistribution && divisionDetails.revenueDistribution.businessUnits) {
            const businessUnits = [...divisionDetails.revenueDistribution.businessUnits]
            setBusinessUnits([...businessUnits.sort(utils.sorting('revenue', "number", false))])
        }

        const obj = {
            gained: divisionDetails.customerGained,
            lost: divisionDetails.customerLost
        }
        setCustGained({ ...obj })

        const open_backorder = {
            open: divisionDetails.openorder,
            back: divisionDetails.backorder
        }
        setOpen_Backorder(open_backorder)
        if (divisionDetails.revenueDistribution && divisionDetails.revenueDistribution.skus) {
            const skus = [...divisionDetails.revenueDistribution.skus]
            setskus([...skus.sort(utils.sorting('revenue', "number", false))])
        }
        if (divisionDetails.revenueDistribution && divisionDetails.revenueDistribution.customers) {
            const customers = [...divisionDetails.revenueDistribution.customers]
            setCustomers(customers.sort(utils.sorting('revenue', "number", false)))
        }

        return divisionDetails
    };

    const getGroupData = (result) => {
        const group = (result && result.hits && result.hits.hits && result.hits.hits.length>0)?result.hits.hits[0]:[];
        return (group && group['_source'])?group['_source']:{};
    }
    useEffect(() => {
    setIsLoading(true)
    const input = {
        name: constants.PD_Attributes_Index,
        queryParams: {
            "query": {
                "match_all": {}
            }
        }
    }
    utils.getDataES('-elasticsearch', 'POST', input)
        .then(resp => {
            let groupESData = getGroupData(resp.data);
            let result = processData(groupESData)
            setdivisionDetails(JSON.parse(JSON.stringify(result)))
            setIsLoading(false)
        }).catch(err => setIsLoading(false))
    }, [])
    return (
        divisionDetails && <div className={styles.attributes}>
            <div className={styles.attributesHeader}>{props.code} (Division level view)</div>
            <div className={styles.attributesBody}>
                <div className={styles.groupTopSection}>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>YTD Revenue​</div>
                        <div className={styles.bodyValue}>{(divisionDetails.totalRevenue)?'$'+utils.convertToInternationalCurrencySystem(divisionDetails.totalRevenue.toFixed(2)):'-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>YTD Growth​</div>
                        <div className={styles.bodyValue}>{(divisionDetails.YTDGrowth) ? divisionDetails.YTDGrowth.toFixed(2)+'%' : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>QoQ Growth​</div>
                        <div className={styles.bodyValue}>{(divisionDetails.QoQGrowth) ? divisionDetails.QoQGrowth.toFixed(2)+'%' : '-'}</div>
                    </div>
                </div>
                <div>
                    <Accordion
                        title="Revenue Distribution by Channel (YTD)"
                        type="customerSegment"
                        list={customerSegment}
                    />
                    <Accordion
                        title="Revenue Distribution by Business Units (YTD)"
                        type="businessUnits"
                        list={businessUnits}
                    />
                    <Accordion title="Open Order & Backorder"
                        type="openOrder"
                        open_backorder={open_backorder}
                    />
                    <Accordion
                        title="Current Revenue for COVID SKUs vs Non COVID SKUs​"
                        type="covid"
                        skus={skus}
                    />
                    <Accordion
                        title="Top 5 Customers by Revenue​ ($M) and Contribution (%)"
                        type="customerRevenue"
                        customers={customers}
                    />
                    <Accordion title="Customers Gained/Lost"
                        type="customerGained"
                        custGained={custGained}
                    />
                </div>
            </div>
        </div>)
}

export default ProductDivisionAttribute