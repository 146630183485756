import React from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createStore } from 'redux';
import { Provider } from "react-redux";
import rootReducer from "./store/reducers";
const store = createStore(rootReducer)

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
