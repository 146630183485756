import React from "react";
import styles from "./product.module.scss";
import * as utils from '../../utils/utils'
function Product(props) {

  const {
    name,
    code,
    cxScore,
    lastQuarterCxScore,
    cas,
    lastQuarterCas,
    volatility,
    recommendation,
    cxBenchmark,
    casBenchmark,
    responseCount
  } = props.product;
  const { type } = props;

  const getRrecommendation = (recommendation) => {
    return utils.getRrecommendation(recommendation)
  }

  const getRecommendationColorCode = (recommendation) => {
    return utils.getRecommendationColorCode(recommendation)
  }

  return (
    <div
      onClick={() => props.showDetail(props.product)}
      className={`${styles.cardContainer} ${type === "productdivision" ? `${styles.cardMargin}` : ""
        } ${type === "product" ? `${styles.cardAfter}` : ""}`}
    >
      <div className={styles.cardHeader}>
        <div className={styles.cardTitle}>{name}</div>
      </div>
      <div className={styles.cardBody}>
        <div className={styles.innerLayout}>
          <div className={styles.bodyTitle}>CX Score</div>
          {
            !cxScore ? <div className={styles.dash}>-</div> :
              <span
                className={`${styles.bodyValue} ${cxScore >= cxBenchmark ? "text-color-green" : "text-color-red"
                  }`}
              >
                {Number(cxScore).toFixed(0)}
                <span
                  className={
                    (cxScore == lastQuarterCxScore) ? `${styles.arrowNoChange}` : (cxScore > lastQuarterCxScore) ? `${styles.arrowUp}` : `${styles.arrowDown}`
                  }
                ></span>
              </span>
          }
        </div>
        <div className={styles.innerLayout}>
          <div className={styles.bodyTitle}>CAS</div>
          {
            !responseCount ? <div className={styles.dash}>-</div> :
              <span
                className={`${styles.bodyValue} ${cas >= casBenchmark ? "text-color-green" : "text-color-red"
                  }`}
              >
                {Number(cas).toFixed(0)}
                <span
                  className={
                    (cas == lastQuarterCas) ? `${styles.arrowNoChange}` : (cas > lastQuarterCas) ? `${styles.arrowUp}` : `${styles.arrowDown}`
                  }
                ></span>
              </span>
          }
        </div>
        <div className={styles.innerLayout}>
          <div className={styles.bodyTitle}>Volatility</div>
          {
            !cxScore ? <div className={styles.dash}>-</div> :
              <span className={styles.bodyValue}>{volatility}</span>
          }
        </div>
      </div>
      <div
        className={`${styles.cardFooter} ${getRecommendationColorCode(recommendation)}`}
      >
        <b>{getRrecommendation(recommendation)}</b>
      </div>
    </div>
  );
}

export default Product;
