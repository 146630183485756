
const getCASweightage = (cas, quarter) => {
    let responseCount = 0
    if (quarter === 'current')
        responseCount = cas.responseCount
    else
        responseCount = cas.lastQuarterResponseCount || 0
    if (responseCount > 40) {
        return 0.3
    } else if (responseCount <= 40 && responseCount >= 15) {
        return 0.2
    } else if (responseCount < 15 && responseCount > 0) {
        return 0.1
    } else {
        return 0
    }
}

const ConsolidatedScore = (props, quarter) => {
    const { cas, cxScore } = props
    const CASweightage = getCASweightage(cas, quarter)
    const _cas = (quarter === 'current') ? cas.score : cas.lastQuarterValue
    const _cx = (quarter === 'current') ? cxScore.score : cxScore.lastQuarterValue
    const score = (_cas * CASweightage) + (_cx * (1 - CASweightage))
    return score
}

export default ConsolidatedScore;