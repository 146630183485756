import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import PowerBi from '../../../components/powerbi/PowerBi';
import * as favUtils from '../../../utils/favorite.utils';
import * as utils from '../../../utils/utils';
import style from '../kpi.module.scss'
import { Spin } from 'antd';
import Insights from '../../../components/insights/Insights';
const ChildView = props => {

    const [insights] = useState(props.selectedKPI.insights ? [...props.selectedKPI.insights] : [])
    const favInput = JSON.parse(JSON.stringify(props.favInput));
    const childViews = props.selectedKPI.child.split(',')
    let [FavoriteId, setFavoriteId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isOpen, setisOpen] = useState(false)
    const breadCrumb = [...props.breadCrumb]

    if (!breadCrumb.filter(element => element.value.toLowerCase() === getChildViewName().toLowerCase()).length) {
        breadCrumb.push(
            {
                value: getChildViewName(),
                path: "/",
            }
        )
    }

    const goBack = () => {
        props.history.push('/dashboard/primaryview')
    }

    const getCurrentBreadCrumb = () => {
        breadCrumb.map(element => {
            if (element.value.toLowerCase() === getChildViewName().toLowerCase()) {
                element.path = "/dashboard/childview"
            }
        })
        return breadCrumb;
    }

    const history = () => {
        props.setBreadCrumb(getCurrentBreadCrumb())
        props.history.push('/dashboard/history')
    }

    function getChildViewName() {
        return utils.getChildViewName(props.selectedKPI.name)
    }

    async function getFavorites() {
        const favorites = await favUtils.getFavorites()
        props.setFavorite(favorites)
    }

    async function addToFavorite(isAlreadyFavorite) {
        try {
            setLoading(true)
            if (!isAlreadyFavorite) {
                favInput.UserId = utils.getUserEmail()
                favInput.ViewType = 4
                favInput.InputParameters.productDivision = props.selectedProductDivision;
                favInput.InputParameters.customer = props.selectedCustomer;
                favInput.InputParameters.sbs = props.selectedSBS;
                favInput.InputParameters.sasParent = props.sasParent;
                favInput.InputParameters.cxScoreDetails = props.cxScoreDetails;
                favInput.InputParameters.breadcrumb = breadCrumb;
                favInput.InputParameters.kpi = props.selectedKPI;
                const FavoriteId = await favUtils.addFavorites(favInput)
                setFavoriteId(FavoriteId)
            } else {
                await favUtils.removeFavorites(FavoriteId)
                setFavoriteId(null)
            }
            setLoading(false)
            getFavorites()
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    }

    useEffect(() => {
        setFavoriteId(favUtils.chkIsFavorite(
            props.favorites,
            4,
            props.selectedProductDivision,
            props.selectedCustomer,
            props.selectedSBS,
            props.selectedKPI
        ))
    }, [])
    const openPannel = () => {
        setisOpen(true)
    }
    const closePannel = () => {
        setisOpen(false)
    }
    return (
        <Spin spinning={loading}>
            <div className={style.primarycontainer}>
                <BreadCrumb
                    list={breadCrumb}
                    {...props}
                    addToFavorite={addToFavorite}
                    isFavorite={FavoriteId ? true : false} />
                <div className={style.layoutbx}>
                    <div className={style.childheading}>
                        <div className={style.backBtn} onClick={goBack}>Back</div>
                        <span className={style.childheadingtext}>
                        </span>
                        <div className={`${style.nextbtn} ${style.right167}`} onClick={openPannel}>Insights</div>
                        <div className={style.nextbtn} onClick={history}>View History</div>
                    </div>
                    <div className={isOpen ? `${style.ifrmHolder} ${style.pRight31}` : `${style.ifrmHolder}`}>
                        {
                            childViews.map((view, index) => (
                                <React.Fragment key={index}>
                                    <PowerBi view={view} />
                                </React.Fragment>
                            ))
                        }
                    </div>
                    <Insights closePannel={closePannel} isOpen={isOpen} insights={insights} />
                </div>
            </div>
        </Spin>
    );
}

export default ChildView

