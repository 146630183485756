import { combineReducers } from 'redux'
import ProductDivisionReducer from './ProductDivisionReducer'
import SBSReducer from './SBSReducer'
import CustomerReducer from './CustomerReducer'
import KPIReducer from './KPIReducer'
import NBAReducer from './NBAReducer'
import SASReducer from './SASReducer'
import FavoriteReducer from './FavoriteReducer'
import GroupReducer from './GroupReducer'
import KpiConfigReducer from './KpiConfigReducer'
import KPIWeightageReducer from './KPIWeightage'

const rootReducer = combineReducers({
    GroupReducer,
    ProductDivisionReducer,
    SBSReducer,
    CustomerReducer,
    KPIReducer,
    NBAReducer,
    SASReducer,
    FavoriteReducer,
    KpiConfigReducer,
    KPIWeightageReducer
})

export default rootReducer