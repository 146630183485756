import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import PowerBi from '../../../components/powerbi/PowerBi';
import * as favUtils from '../../../utils/favorite.utils';
import * as utils from '../../../utils/utils';
import style from '../nba.module.scss'
import { Spin } from 'antd';

const PrimaryView = props => {

    let breadCrumb = [...props.breadCrumb]
    const favInput = JSON.parse(JSON.stringify(props.favInput));
    let [FavoriteId, setFavoriteId] = useState(null)
    const [loading, setLoading] = useState(false)

    if (!breadCrumb.filter(element => element.value.toLowerCase() === props.nba.name.toLowerCase()).length) {
        breadCrumb.push(
            {
                value: props.nba.name,
                path: "/",
            }
        )
    }

    //remove the selected KPI from bread crumb
    if (props.nba) {
        breadCrumb = breadCrumb
            .filter(item => item.value.toLowerCase() !== utils.getNBAChildViewName(props.nba.name).toLowerCase())
    }

    const getCurrentBreadCrumb = () => {
        breadCrumb.map(element => {
            if (element.value.toLowerCase() === props.nba.name.toLowerCase()) {
                element.path = "/dashboard/nba/primaryview"
            }
        })
        return breadCrumb;
    }

    const childView = () => {
        props.setNBABreadCrumb(getCurrentBreadCrumb())
        props.history.push('/dashboard/nba/childview')
    }

    const goBack = () => {
        if (!utils.isEmpty(props.selectedCustomer)) {
            props.history.push('/dashboard/customerdetail')
        } else if (!utils.isEmpty(props.selectedSBS)) {
            props.history.push('/dashboard/sbsdetail')
        } else if (!utils.isEmpty(props.selectedProductDivision)) {
            props.history.push('/dashboard/productdivisiondetail')
        } else {
            props.history.push('/dashboard/groupdetail')
        }
    }

    async function getFavorites() {
        const favorites = await favUtils.getFavorites()
        props.setFavorite(favorites)
    }

    async function addToFavorite(isAlreadyFavorite) {
        try {
            setLoading(true)
            if (!isAlreadyFavorite) {
                favInput.UserId = utils.getUserEmail()
                favInput.ViewType = 6
                favInput.InputParameters.productDivision = props.selectedProductDivision;
                favInput.InputParameters.customer = props.selectedCustomer;
                favInput.InputParameters.sbs = props.selectedSBS;
                favInput.InputParameters.breadcrumb = props.breadCrumb;
                favInput.InputParameters.nba = props.nba;
                const FavoriteId = await favUtils.addFavorites(favInput)
                setFavoriteId(FavoriteId)
            } else {
                await favUtils.removeFavorites(FavoriteId)
                setFavoriteId(null)
            }
            setLoading(false)
            getFavorites()
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    }

    useEffect(() => {
        setFavoriteId(favUtils.chkIsFavorite(
            props.favorites,
            6,
            props.selectedProductDivision,
            props.selectedCustomer,
            props.selectedSBS,
            '',
            props.nba))
    }, [])

    return (
        <Spin spinning={loading}>
            <div className={style.primarycontainer}>
                <BreadCrumb
                    list={breadCrumb}
                    addToFavorite={addToFavorite}
                    isFavorite={FavoriteId ? true : false}
                    {...props} />
                <div className={style.layoutbx}>
                    <div className={style.childheading}>
                        <div className={style.backBtn} onClick={goBack}>Back</div>
                        <span className={style.childheadingtext}>
                        </span>
                        <div className={style.nextbtn} onClick={childView}>Detailed View</div>
                    </div>
                    <PowerBi view={props.nba.primaryView} />
                </div>
            </div>
        </Spin>
    );
}

export default PrimaryView

