import React from 'react';
import { Route, Redirect } from 'react-router-dom'

const ProtectedRoute = ({ Component, isAuthenticated, notStable, redirectTo, ...rest }) => {

    const redirectToFn = (to) => {
        if (to) {
            return to
        }
        return `/dashboard`
    }

    return (<Route {...rest} render={(props) => (
        isAuthenticated ?
            (notStable ? (<Redirect to={redirectToFn(redirectTo)} />) : (<Component {...props} />))
            :
            (<Redirect to='/' />)
    )} ></Route>)

}

export default ProtectedRoute;