import React, { useState } from "react";
import styles from './cas.module.scss'
import Tabs from "../../components/tabs/Tabs";
import { getCASReport } from './Cas.utils'
import PowerBi from '../../components/powerbi/PowerBi';

const CAS = (props) => {

    const CAS = getCASReport({ ...props, type: 'overall' })
    const survey = getCASReport({ ...props, type: 'survey' })
    const [tabs, setTabs] = useState([
        {
            value: 'CAS',
            isActive: true,
            url: ''
        },
        {
            value: 'Pain Points Heat Map',
            isActive: false,
            url: ''
        },
        {
            value: 'Survey Results',
            isActive: false,
            url: ''
        }
    ])

    const setActive = (tab) => {
        const _tabs = [...tabs]
        _tabs.forEach(element => {
            if (element.value.toLowerCase() === tab.value.toLowerCase()) {
                element.isActive = true;
            } else {
                element.isActive = false;
            }
        })
        setTabs(_tabs)
    }

    const showChildView = () => {
        props.history.push('/dashboard/cas-childview')
    }

    return (
        <div>
            <Tabs tabs={tabs} setActive={setActive} value="CAS​" />
            {
                tabs[0].isActive ? <div className={`${styles.CAS} ${'row no-gutters'}`}>
                    <div className={`${styles.CASGraph} ${'col-sm-12 col-md-7'}`}>
                        <div className={styles.childheading}>
                            <div className={styles.overall}>Overall CAS | <span>Touchpoints included: Customer Care, Sales, Order Fulfillment, Web</span></div>
                            <div className={styles.nextbtn} onClick={showChildView} >
                                <span>Detailed View</span>
                            </div>
                        </div>
                        <PowerBi view={CAS} height="550px" />
                    </div>
                    <div className={`${styles.CASInsights} ${'d-block d-sm-none d-md-block col-md-5'}`}>
                        <h2 className={styles.CASInsightsHeading}>Insights</h2>
                        <div className={styles.indexDesc}>
                            <ul>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</li>
                            </ul>
                        </div>
                    </div>
                </div> : null
            }
            {
                tabs[2].isActive ? <PowerBi view={survey} height="600px" /> : null
            }
        </div>
    )
}

export default CAS