import { connect } from 'react-redux'
import division from '../../pages/config/child-weightage-division/ChildWeightageDivision'

const mapStateToProps = state => {
    return {
        kpi: state.KPIWeightageReducer ? state.KPIWeightageReducer.parentKPI : null,
        childKPI: state.KPIWeightageReducer ? state.KPIWeightageReducer.childKPI : null
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(division)
