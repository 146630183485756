import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import style from '../kpi.module.scss'
import PowerBi from '../../../components/powerbi/PowerBi';
import * as utils from '../../../utils/utils';
import * as favUtils from '../../../utils/favorite.utils';
import { Spin } from 'antd';

const History = props => {

    const favInput = JSON.parse(JSON.stringify(props.favInput));
    let [FavoriteId, setFavoriteId] = useState(null)
    const [loading, setLoading] = useState(false)

    const breadCrumb = [...props.breadCrumb, {
        value: 'History',
        path: "/",
    }]

    const goBack = () => {
        props.history.push('/dashboard/childview')
    };

    async function getFavorites() {
        const favorites = await favUtils.getFavorites()
        props.setFavorite(favorites)
    }

    async function addToFavorite(isAlreadyFavorite) {
        try {
            setLoading(true)
            if (!isAlreadyFavorite) {
                favInput.UserId = utils.getUserEmail()
                favInput.ViewType = 5
                favInput.InputParameters.productDivision = props.selectedProductDivision;
                favInput.InputParameters.customer = props.selectedCustomer;
                favInput.InputParameters.sbs = props.selectedSBS;
                favInput.InputParameters.sasParent = props.sasParent;
                favInput.InputParameters.cxScoreDetails = props.cxScoreDetails;
                favInput.InputParameters.breadcrumb = props.breadCrumb;
                favInput.InputParameters.kpi = props.selectedKPI;
                const FavoriteId = await favUtils.addFavorites(favInput)
                setFavoriteId(FavoriteId)
            } else {
                await favUtils.removeFavorites(FavoriteId)
                setFavoriteId(null)
            }
            setLoading(false)
            getFavorites()
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    }

    useEffect(() => {
        setFavoriteId(favUtils.chkIsFavorite(
            props.favorites,
            5,
            props.selectedProductDivision,
            props.selectedCustomer,
            props.selectedSBS,
            props.selectedKPI
        ))
    }, [])

    return (
        <Spin spinning={loading}>
            <div className={style.primarycontainer}>
                <BreadCrumb
                    list={breadCrumb}
                    {...props}
                    addToFavorite={addToFavorite}
                    isFavorite={FavoriteId ? true : false} />
                <div className={style.layoutbx}>
                    <div className={style.childheading}>
                        <div className={style.backBtn} onClick={goBack}>Back</div>
                        <span className={style.childheadingtext}>
                        </span>
                    </div>
                    <PowerBi view={props.selectedKPI.history} height="700px" />
                </div>
            </div>
        </Spin>
    );
}

export default History

