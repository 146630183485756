import React from 'react'
import styles from "./tiles.module.scss";
import Carousel from "react-elastic-carousel";

const CXList = (props) => {
    const cxList = [...props.cxList];
    return (
        <div className="cardContainerCar carouselWrapper">
            <Carousel itemsToScroll={1} itemsToShow={1}>
                <div className={styles.carouselItem}>
                    <div className={styles.cardHeader}>
                        <div className={styles.cardTitle}>CX Index</div>
                        <div className={styles.percentageInc}>
                            Max % <span>Increase</span>
                        </div>
                    </div>
                    <div className="cxCardBody">
                        {
                            cxList[0].score === 0 ?
                                <div className={styles.noTransactions}>No transactions in current quarter</div> :
                                <React.Fragment>
                                    <div className={styles.indexSession}>
                                        <span className={styles.kpiLeft}>
                                            {cxList[0].name}
                                        </span>
                                        <span className={styles.kpiRight}>{Number(cxList[0].score).toFixed(0)}</span>
                                    </div>
                                    <div className={styles.indexDesc}>
                                        {
                                            cxList[0].difference === 0 ?
                                                <React.Fragment>
                                                    <span>Same as last quarter</span>
                                                    <span className={styles.arrowNoChange}></span>
                                                </React.Fragment> :
                                                <React.Fragment>
                                                    <span style={{ color: "#4aa707" }}>{Number(Math.abs(cxList[0].difference)).toFixed(0)}</span> Points
                                                    <span className={styles.arrowUp}></span>from last quarter
                                                </React.Fragment>
                                        }
                                    </div>
                                </React.Fragment>
                        }
                    </div>
                </div>
                <div className={styles.carouselItem}>
                    <div className={styles.cardHeader}>
                        <div className={styles.cardTitle}>CX Index</div>
                        <div className={styles.percentageInc}>
                            Max % <span className="text-color-red">Decrease</span>
                        </div>
                    </div>
                    <div className="cxCardBody">
                        {
                            cxList[0].score === 0 ?
                                <div className={styles.noTransactions}>No transactions in current quarter</div> :
                                <React.Fragment>
                                    <div className={styles.indexSession}>
                                        <span className={styles.kpiLeft}>
                                            {cxList[cxList.length - 1].name}
                                        </span>
                                        <span className={styles.kpiRight}>{Number(cxList[cxList.length - 1].score).toFixed(0)}</span>
                                    </div>
                                    <div className={styles.indexDesc}>
                                        {
                                            cxList[cxList.length - 1].difference === 0 ?
                                                <React.Fragment>
                                                    <span>Same as last quarter</span>
                                                    <span className={styles.arrowNoChange}></span>
                                                </React.Fragment> :
                                                <React.Fragment>
                                                    <span className="text-color-red">{Number(Math.abs(cxList[cxList.length - 1].difference)).toFixed(0)}</span> Points
                                                    <span className={styles.arrowDown}></span>from last quarter
                                                </React.Fragment>
                                        }
                                    </div>
                                </React.Fragment>
                        }
                    </div>
                </div>
            </Carousel>
        </div>
    )
}

export default CXList;