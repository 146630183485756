import { SET_CXSCORE_DATA, SET_BREAD_CRUMB, SET_KPI } from "../constant";

const KPIReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_CXSCORE_DATA:
            return {
                ...state,
                cxScoreDetails: action.data
            }
        case SET_BREAD_CRUMB:
            return {
                ...state,
                breadCrumb: action.data
            }
        case SET_KPI:
            return {
                ...state,
                selectedKPI: action.data
            }
        default:
            return state
    }
}

export default KPIReducer;