import React, { useEffect, useState } from 'react';
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import styles from "./detailedview.module.scss";
import CXScore from "./Tiles/CXScore";
import CAS from "./Tiles/CAS";
import * as utils from "../../utils/utils";
import { Spin } from 'antd';
import CXList from './Tiles/CXList';
import * as consts from '../../utils/constant';
import * as favUtils from '../../utils/favorite.utils';
import Attributes from '../attributes/customer'
import * as constants from "../../../src/utils/constant";

const Customer = (props) => {

    const favInput = JSON.parse(JSON.stringify(props.favInput));
    const { customer } = props;
    let [FavoriteId, setFavoriteId] = useState(null)
    const [loading, setLoading] = useState(false)
    const recommended = customer.recommendation ? customer.recommendation.toLocaleLowerCase() : ''
    const [customerDetails, setCustomerDetails] = useState(null)

    const breadcrumbList = [
        {
            value: "Dashboard",
            path: "/dashboard",
        }
    ];

    if (!utils.isEmpty(props.selectedProductDivision)) {
        breadcrumbList.push({ value: props.selectedProductDivision.code, path: '/dashboard/productdivision' })
    }
    breadcrumbList.push({ value: utils.capitalize(props.customer.name), path: '/' })
    const formatData = (result) => {
        const result1 = (result && result.hits && result.hits.hits && result.hits.hits.length>0)?result.hits.hits[0]:[];
        return (result1 && result1['_source'])?result1['_source']:{};
    }
    useEffect(() => {
        setLoading(true)
        const type = utils.isEmpty(props.selectedProductDivision) ? 'customer'
            : 'productDivisionCustomer'
        // const filePath = utils.isEmpty(props.selectedProductDivision)
        //     ? 'score_details/customer/customer.json'
        //     : 'score_details/productDivisionCustomer/productDivisionCustomer.json';
        // const divisionCode = utils.isEmpty(props.selectedProductDivision)
        //     ? undefined : props.selectedProductDivision.code
        // utils.getData(`getscoredetails`,
        //     'POST',
        //     {
        //         type: type,
        //         filePath: filePath,
        //         divisionCode: divisionCode,
        //         customerCode: customer.code
        //     }).then(resp => {
        //         setCustomerDetails(resp.data)
        //         setLoading(false)
        //     }).catch(err => setLoading(false))
        let params = {
            "size": 1, 
            "query": {
              "match": {
                "code":customer.code
              }
            }
          }
        if(type === 'productDivisionCustomer'){
            // params = {
            //     "size": 1, 
            //       "query": {
            //           "bool": {
            //               "must": [
            //                   {
            //                       "match": {
            //                           "product_division_code": props.selectedProductDivision.code
            //                       }
            //                   },
            //                   {
            //                       "match": {
            //                           "code": customer.code
            //                       }
            //                   }
            //               ]
            //           }
            //       }
            //   }
            params = {
                "size": 1,
                "query": {
                  "bool": {
                    "should": [
                      {
                        "bool": {
                          "must": [
                            {
                              "bool": {
                                "should": [
                                  {
                                    "match": {
                                      "code": customer.code
                                    }
                                  },
                                  {
                                    "match": {
                                      "volatility": customer.volatility
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              "match": {
                                "product_division_code": props.selectedProductDivision.code
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              }

        }
        const input = {
            name: (type === 'customer')?constants.Customer_ScoreDetails_Index:constants.PD_customer_ScoreDeatils_Index,
            queryParams: params
        }
          setLoading(true)
          utils.getDataES('-elasticsearch', 'POST',input)
            .then(resp => {
              let ESData = formatData(resp.data);
              setCustomerDetails(ESData)
              //setData(JSON.parse(JSON.stringify(result)))
              setLoading(false)
            }).catch(err =>  setLoading(false))
    }, [props.customer])

    let cxScore, cas, cxList, cxListDP;
    const getSortedCXList = (cxList) => {
        let list = [...cxList]
        list.forEach(element => {
            element.difference = element.score - element.lastQuarterValue
            element.differenceCent = ((element.score - element.lastQuarterValue) / (element.lastQuarterValue)) * 100
        });
        return list.sort(utils.sorting('differenceCent', 'number', false))
    }

    if (customerDetails) {
        cxScore = customerDetails.cxScore;
        if(customerDetails && customerDetails.cxList && customerDetails.cxList.length>0){
            cxListDP = customerDetails.cxList.find(item=>item.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase());
            cxScore.dataPoints = (cxListDP && cxListDP.dataPoints)?cxListDP.dataPoints:''
        }
        cas = customerDetails.cas;
        cxList = getSortedCXList(customerDetails.cxList);
    }

    const createBreadCrumb = () => {
        breadcrumbList[breadcrumbList.length - 1].path = '/dashboard/customerdetail'
        return breadcrumbList;
    }

    const viewScore = (score) => {
        props.setCXScoreData(customerDetails);
        props.setBreadCrumb(createBreadCrumb());
        if (score === 'cas') {
            props.setSASParent('cas');
            props.history.push("/dashboard/score/cas");
        } else {
            props.setSASParent('cx');
            props.history.push("/dashboard/score");
        }
    };

    const getInsights = (nba) => {
        if (customerDetails.nba_insights) {
            let insights = customerDetails.nba_insights.filter(element => element.metrics_name.toLowerCase() === nba.toLowerCase())
            if (insights && insights.length) {
                return insights[0].insights
            }
            return []
        }
        return []
    }

    const primaryView = (nba) => {
        let _nba;
        const { code } = props.customer
        switch (nba) {
            case consts.IMPROVE_INVOICE_AGING:
                if (!utils.isEmpty(props.selectedProductDivision)) {
                    const { code: divisionCode } = props.selectedProductDivision
                    _nba = {
                        name: nba,
                        primaryView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '${code}'`,
                        childView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_CHILD_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Invoice/product_division_code eq '${divisionCode}' and Invoice2/product_division_code eq '${divisionCode}' and Invoice2/ship_to_national_sales_group_name eq '${code}'`,
                        historyView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_HISTORY_LINK}&filter=Invoice/product_division_code eq '${divisionCode}' and Invoice/ship_to_national_sales_group_name eq '${code}'`,
                        insights: getInsights(consts.IMPROVE_INVOICE_AGING)
                    }
                    props.setNBA(_nba)
                } else {
                    _nba = {
                        name: nba,
                        primaryView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics/product_division_code eq '-' and Salesmetrics/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '${code}'`,
                        childView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_CHILD_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Invoice2/ship_to_national_sales_group_name eq '${code}'`,
                        historyView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_HISTORY_LINK}&filter=Invoice/ship_to_national_sales_group_name eq '${code}'`,
                        insights: getInsights(consts.IMPROVE_INVOICE_AGING)
                    }
                    props.setNBA(_nba)
                }
                break;
            case consts.REDUCE_RETURN_PERCENTAGE:
                if (!utils.isEmpty(props.selectedProductDivision)) {
                    const { code: divisionCode } = props.selectedProductDivision
                    _nba = {
                        name: nba,
                        primaryView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_PRIMARY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '${divisionCode}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/ship_to_national_sales_group_name eq '${code}'`,
                        childView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '${divisionCode}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/ship_to_national_sales_group_name eq '${code}'`,
                        historyView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/ship_to_national_sales_group_name eq '${code}'`,
                        insights: getInsights(consts.REDUCE_RETURN_PERCENTAGE)
                    }
                    props.setNBA(_nba)
                } else {
                    _nba = {
                        name: nba,
                        primaryView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_PRIMARY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Sales2/ship_to_national_sales_group_name eq '${code}'`,
                        childView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Sales2/ship_to_national_sales_group_name eq '${code}'`,
                        historyView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_HISTORY_LINK}&filter=Sales/ship_to_national_sales_group_name eq '${code}'`,
                        insights: getInsights(consts.REDUCE_RETURN_PERCENTAGE)
                    }
                    props.setNBA(_nba)
                }
                break;
            case consts.IMPROVE_PRODUCT_AVAILABILITY:
                if (!utils.isEmpty(props.selectedProductDivision)) {
                    const { code: divisionCode } = props.selectedProductDivision
                    props.setNBA({
                        name: nba,
                        primaryView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_PRIMARY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '${code}'`,
                        childView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '${divisionCode}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/ship_to_national_sales_group_name eq '${code}'`,
                        historyView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/ship_to_national_sales_group_name eq '${code}'`,
                        insights: getInsights(consts.IMPROVE_PRODUCT_AVAILABILITY)
                    })
                } else {
                    props.setNBA({
                        name: nba,
                        primaryView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_PRIMARY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics/product_division_code eq '-' and Salesmetrics/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '${code}'`,
                        childView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Sales2/ship_to_national_sales_group_name eq '${code}'`,
                        historyView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_HISTORY_LINK}&filter=Sales/ship_to_national_sales_group_name eq '${code}'`,
                        insights: getInsights(consts.IMPROVE_PRODUCT_AVAILABILITY)
                    })
                }
                break;
            case consts.NEW_IMPROVE_ORDER_VELOCITY:
                if (!utils.isEmpty(props.selectedProductDivision)) {
                    const { code: divisionCode } = props.selectedProductDivision
                    const _nba = {
                        name: nba,
                        primaryView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_PRIMARY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '${code}'`,
                        childView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '${divisionCode}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/ship_to_national_sales_group_name eq '${code}'`,
                        historyView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/ship_to_national_sales_group_name eq '${code}'`,
                        insights: getInsights(consts.NEW_IMPROVE_ORDER_VELOCITY)
                    }
                    props.setNBA(_nba)
                } else {
                    const _nba = {
                        name: nba,
                        primaryView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_PRIMARY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics/product_division_code eq '-' and Salesmetrics/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '${code}'`,
                        childView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Sales2/ship_to_national_sales_group_name eq '${code}'`,
                        historyView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_HISTORY_LINK}&filter=Sales/ship_to_national_sales_group_name eq '${code}'`,
                        insights: getInsights(consts.NEW_IMPROVE_ORDER_VELOCITY)
                    }
                    props.setNBA(_nba)
                }
                break;
            case consts.IMPROVE_OTIF:
                if (!utils.isEmpty(props.selectedProductDivision)) {
                    const { code: divisionCode } = props.selectedProductDivision
                    _nba = {
                        name: nba,
                        primaryView: `${process.env.REACT_APP_IMPROVE_OTIF_PRIMARY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '${code}'`,
                        childView: `${process.env.REACT_APP_IMPROVE_OTIF_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '${divisionCode}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/ship_to_national_sales_group_name eq '${code}'`,
                        historyView: `${process.env.REACT_APP_IMPROVE_OTIF_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/ship_to_national_sales_group_name eq '${code}'`,
                        insights: getInsights(consts.IMPROVE_OTIF)
                    }
                    props.setNBA(_nba)
                } else {
                    _nba = {
                        name: nba,
                        primaryView: `${process.env.REACT_APP_IMPROVE_OTIF_PRIMARY_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics/product_division_code eq '-' and Salesmetrics/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '${code}'`,
                        childView: `${process.env.REACT_APP_IMPROVE_OTIF_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Sales2/ship_to_national_sales_group_name eq '${code}'`,
                        historyView: `${process.env.REACT_APP_IMPROVE_OTIF_HISTORY_LINK}&filter=Sales/ship_to_national_sales_group_name eq '${code}'`,
                        insights: getInsights(consts.IMPROVE_OTIF)
                    }
                    props.setNBA(_nba)
                }
                break;
            case consts.INCREASE_CREDITLINE:
                if (!utils.isEmpty(props.selectedProductDivision)) {
                    const { code: divisionCode } = props.selectedProductDivision
                    _nba = {
                        name: nba,
                        primaryView: `https://app.powerbi.com/reportEmbed?reportId=e1322c33-8422-4138-8c70-16579a5d450d&autoAuth=true&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '${code}'`,
                        childView: `${process.env.REACT_APP_INCREASE_CREDITLINE_CHILD_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '${code}' and Invoice/product_division_code eq '${divisionCode}' and Invoice2/product_division_code eq '${divisionCode}' and Invoice2/ship_to_national_sales_group_name eq '${code}'`,
                        historyView: `${process.env.REACT_APP_INCREASE_CREDITLINE_HISTORY_LINK}&filter=Sales/ship_to_national_sales_group_name eq '${code}'`,
                        insights: getInsights(consts.IMPROVE_OTIF)
                    }
                    props.setNBA(_nba)
                } else {
                    _nba = {
                        name: nba,
                        primaryView: `https://app.powerbi.com/reportEmbed?reportId=e1322c33-8422-4138-8c70-16579a5d450d&autoAuth=true&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics/product_division_code eq '-' and Salesmetrics/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '${code}'`,
                        childView: `${process.env.REACT_APP_INCREASE_CREDITLINE_CHILD_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/ship_to_national_sales_group_name eq '${code}' and Invoice2/ship_to_national_sales_group_name eq '${code}'`,
                        historyView: `${process.env.REACT_APP_INCREASE_CREDITLINE_HISTORY_LINK}&filter=Sales/ship_to_national_sales_group_name eq '${code}'`,
                        insights: getInsights(consts.IMPROVE_OTIF)
                    }
                    props.setNBA(_nba)
                }
                break;
            default:
                break;
        }

        props.setNBABreadCrumb(createBreadCrumb())
        props.history.push('/dashboard/nba/primaryview')
    }

    async function getFavorites() {
        const favorites = await favUtils.getFavorites()
        props.setFavorite(favorites)
    }

    async function addToFavorite(isAlreadyFavorite) {
        try {
            setLoading(true)
            if (!isAlreadyFavorite) {
                favInput.UserId = utils.getUserEmail()
                favInput.ViewType = 1
                favInput.InputParameters.productDivision = props.selectedProductDivision;
                favInput.InputParameters.customer = customer;
                const FavoriteId = await favUtils.addFavorites(favInput)
                setFavoriteId(FavoriteId)
            } else {
                await favUtils.removeFavorites(FavoriteId)
                setFavoriteId(null)
            }
            setLoading(false)
            getFavorites()
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    }

    useEffect(() => {
        setFavoriteId(favUtils.chkIsFavorite(
            props.favorites,
            1,
            props.selectedProductDivision,
            customer))
    }, [customer])

    const nbas = [
        {
            nba: consts.REDUCE_RETURN_PERCENTAGE,
            recommended: recommended === consts.REDUCE_RETURN_PERCENTAGE.toLowerCase() ? true : false
        },
        {
            nba: consts.IMPROVE_PRODUCT_AVAILABILITY,
            recommended: recommended === consts.IMPROVE_PRODUCT_AVAILABILITY.toLowerCase() ? true : false
        },
        {
            nba: consts.NEW_IMPROVE_ORDER_VELOCITY,
            recommended: recommended === consts.NEW_IMPROVE_ORDER_VELOCITY.toLowerCase() ? true : false
        },
        {
            nba: consts.IMPROVE_OTIF,
            recommended: recommended === consts.IMPROVE_OTIF.toLowerCase() ? true : false
        },
        {
            nba: consts.IMPROVE_INVOICE_AGING,
            recommended: recommended === consts.IMPROVE_INVOICE_AGING.toLowerCase() ? true : false
        },
        {
            nba: consts.INCREASE_CREDITLINE,
            recommended: recommended === consts.INCREASE_CREDITLINE.toLowerCase() ? true : false
        }
    ]

    return (
        <Spin spinning={loading}>
            <div className={styles.cxContainer}>
                <BreadCrumb
                    list={breadcrumbList}
                    {...props}
                    addToFavorite={addToFavorite}
                    isFavorite={FavoriteId ? true : false} />
                <div className="row no-gutters">
                    <div className={`${styles.leftContent} ${"col-sm-12 col-md-12 col-lg-8"}`} >
                        {
                            customerDetails ? <React.Fragment>
                                <div className={styles.scoreGrid}>
                                    {cxScore && <CXScore cxScore={cxScore} {...props} viewScore={viewScore} />}
                                    {cas && <CAS cas={cas} {...props} viewScore={viewScore} />}
                                    {cxList && <CXList cxList={cxList} {...props} />}
                                </div>
                                <div className={styles.layoutBx}>
                                    <h2 className={styles.cxHeading}>Journey Map Score</h2>
                                    <div className={styles.underDesign}>Under Design</div>
                                </div>
                                <div className={styles.layoutBx}>
                                    <h2 className={styles.cxHeading}>Next Best Actions</h2>
                                    <div className={styles.nbaContainer}>
                                        {
                                            nbas.map((element, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => primaryView(element.nba)}
                                                    className={element.recommended ? `${styles.griditem} ${styles.recommended}` : styles.griditem}>{element.nba}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </React.Fragment> : null
                        }
                    </div>
                    <div className={`${styles.rightContent} ${"col-sm-12 col-md-12 col-lg-4"}`}>
                        <Attributes code={customer.code} divisionCode={props.selectedProductDivision.code} whiteGlove={props.customer.whiteGlove} />
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default Customer;
