import { connect } from 'react-redux'
import KPIperformance from '../../pages/config/performance/KPIperformance'

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setConfigKPI: (data) => dispatch({
            type: 'childkpiInfo',
            data: data
        }),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KPIperformance)
