import { useState, useEffect } from 'react'

export const useHttpGet = (url, dependencies, processData) => {

    const [isLoading, setIsLoading] = useState(false)
    const [fetchedData, setFetchedData] = useState(null)

    const getToken = () => {
        const token = JSON.parse(localStorage.__tf_token)
        return token.id_token
    }

    const headers = {
        'Content-Type': 'application/json',
        'AuthToken': getToken()
    }

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_API_URL}gets3filesdata?${url}`, {
            method: 'GET',
            headers: headers
        })
            .then(response => response.json())
            .then((response) => {
                setFetchedData(processData(response))
                setIsLoading(false)
            }).catch((error) => {
                setIsLoading(false)
                console.log(error)
                if (error.message === 'Failed to fetch') {
                    window.open(process.env.REACT_APP_URL, '_self')
                }
            })
    }, dependencies)

    return [isLoading, fetchedData]
}
