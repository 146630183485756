import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from '../login/Login';
import ProtectedRoute from './ProtectedRoute';
import HomeContainer from '../../containers/HomeContainer';

const Router = (props) => {

    const isAuthenticated = () => {
        if (new URL(window.location.href).hash.length
            || (localStorage.getItem('__tf_token') && localStorage.getItem('__tf_token').length)) {
            return true
        } else {
            return false
        }
    }

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path='/' component={Login} ></Route>
                <React.Fragment>
                    <ProtectedRoute
                        path='/dashboard'
                        Component={HomeContainer}
                        isAuthenticated={isAuthenticated()}
                        {...props} />
                </React.Fragment>
            </Switch>
        </BrowserRouter>
    )
}

export default Router