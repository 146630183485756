import React from "react";

function Chevron(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      height={props.height}
      width={props.width}
      viewBox="0 0 10 6"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={props.fill}>
          <g>
            <g>
              <path
                d="M478 21L483 27 473 27z"
                transform="translate(-1390 -648) translate(917 154) translate(0 473)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Chevron;
