import React, { useState, useEffect } from 'react';
import Accordion from "../../components/accordion/Accordion";
import styles from './attributes.module.scss';
import * as utils from '../../utils/utils';
import * as constants from "../../../src/utils/constant";

function CustomerAttribute(props) {

    const { divisionCode } = props
    const { code } = props    
    const { whiteGlove } = props
    const [productDivisions, setProductDivisions] = useState(null)
    const [regions, setRegions] = useState(null)
    const [custSegment, setCustSegment] = useState(null)
    const [open_backorder, setOpen_Backorder] = useState(null)
    const [credit, setCredit] = useState(null)
    const [sgns, setSgns] = useState(null)
    const [customerDetails, setCustomerDetails] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const processData = (customerDetails) => {

        const sgns = [...customerDetails.revenueDistribution.sgns]
        setSgns([...sgns.sort(utils.sorting('revenue', "number", false))])
        if (customerDetails.revenueDistribution && customerDetails.revenueDistribution.regions) {
            const regions = [...customerDetails.revenueDistribution.regions]
            setRegions([...regions.sort(utils.sorting('revenue', "number", false))])
        }
        if (customerDetails && customerDetails.revenueDistribution && customerDetails.revenueDistribution.productDivisions) {
            const productDivisions = [...customerDetails.revenueDistribution.productDivisions]
            setProductDivisions([...productDivisions.sort(utils.sorting('revenue', "number", false))])
        }
        const obj = {
            segment: customerDetails.segment,
            segmentGroup: customerDetails.segmentGroup
        }
        setCustSegment({ ...obj })

        const credit = {
            total: customerDetails.totalCreditLimit,
            available: customerDetails.availableCredit
        }
        setCredit({ ...credit })

        const open_backorder = {
            open: customerDetails.openorder,
            back: customerDetails.backorder
        }
        setOpen_Backorder(open_backorder)

        return customerDetails
    };
    const formatESData = (result) => {
        if (utils.isEmpty(divisionCode)) {
            let hitsArray = (result.hits && result.hits.hits && result.hits.hits.length>0)?result.hits.hits[0]:[]
            let group = (hitsArray && hitsArray.inner_hits && hitsArray.inner_hits.customer && hitsArray.inner_hits.customer.hits && hitsArray.inner_hits.customer.hits.hits && hitsArray.inner_hits.customer.hits.hits.length>0)?hitsArray.inner_hits.customer.hits.hits[0]:[];
            return (group && group['_source'])?group['_source']:{};
        } else {
            let hitsArray = (result.hits && result.hits.hits && result.hits.hits.length>0)?result.hits.hits[0]:[]
            const productDivisionCustomer = (hitsArray && hitsArray.inner_hits && hitsArray.inner_hits['productDivision.customers'] && hitsArray.inner_hits['productDivision.customers'].hits && hitsArray.inner_hits['productDivision.customers'].hits.hits && hitsArray.inner_hits['productDivision.customers'].hits.hits.length>0)?hitsArray.inner_hits['productDivision.customers'].hits.hits[0]:[];
            return (productDivisionCustomer && productDivisionCustomer['_source'])?productDivisionCustomer['_source']:{};
        }
    }
    useEffect(() => {
        let params;
        if (utils.isEmpty(divisionCode)) {
            params = {
                "_source": false,
                "query": {
                    "nested": {
                        "path": "customer",
                        "query": {
                            "match": {
                                "customer.code": code
                            }
                        },
                        "inner_hits": {
                            "size": 1
                        }
                    }
                }
            }

        } else {
            params = {
                "_source": {
                    "excludes": "*.customers"
                },
                "query": {
                    "nested": {
                        "path": "productDivision.customers",
                        "query": {
                            "bool": {
                                "must": [
                                    {
                                        "match": {
                                            "productDivision.customers.code": code
                                        }
                                    },
                                    {
                                        "match": {
                                            "productDivision.customers.productdivision": divisionCode
                                        }
                                    }
                                ]
                            }
                        },
                        "inner_hits": {
                            "size": 1
                        }
                    }
                }
            }
        }
        setIsLoading(true)
        const input = {
            name: utils.isEmpty(divisionCode) ? constants.Customer_Attributes_Index : constants.PD_Customer_Attributes_Index,
            queryParams: params
        }
        utils.getDataES('-elasticsearch', 'POST', input)
            .then(resp => {
                let ESData = formatESData(resp.data);
                let customerDetails;
                if (!props.divisionCode) {
                    customerDetails = ESData;
                } else {
                    customerDetails = ESData;
                }
                if (customerDetails) {
                    const _customerDetails = processData(customerDetails)
                    setCustomerDetails(_customerDetails)
                } else {
                    setCustomerDetails(null)
                }
                setIsLoading(false)
            }).catch(err => setIsLoading(false))
    }, [code, divisionCode])

    return (
        customerDetails && <div className={styles.attributes}>
            <div className={styles.attributesHeader}>
                <div>
                    {utils.capitalize(props.code)}
                    <span>
                        {
                            divisionCode ? ' (Division level view)' : ' (Account level view)'
                        }
                    </span>
                </div>
                <div className={whiteGlove === "true" ? styles.whiteGlove : null}></div>
            </div>
            <div className={styles.attributesBody}>
                <div className={styles.topContainer}>
                    <div className={styles.groupTopSection}>
                        <div className={styles.innerLayout}>
                            <div className={styles.bodyTitle}>YTD Revenue​</div>
                            <div className={styles.bodyValue}>{(customerDetails.totalRevenue)?'$'+utils.convertToInternationalCurrencySystem(customerDetails.totalRevenue.toFixed(2)):'-'}</div>
                        </div>
                        <div className={styles.innerLayout}>
                            <div className={styles.bodyTitle}>YTD Growth​</div>
                            <div className={styles.bodyValue}>{(customerDetails.YTDGrowth)?customerDetails.YTDGrowth.toFixed(2)+'%':'-'}</div>
                        </div>
                        <div className={styles.innerLayout}>
                            <div className={styles.bodyTitle}>QoQ Growth​</div>
                            <div className={styles.bodyValue}>{(customerDetails.QoQGrowth)?customerDetails.QoQGrowth.toFixed(2)+'%':'-'}</div>
                        </div>
                    </div>
                    <div className={styles.segmentSection}>
                        <div className={styles.segment}>
                            <div className={styles.bodyTitle}>Customer Segment Group​</div>
                            <div className={styles.bodyValue}>{custSegment.segmentGroup}</div>
                        </div>
                        <div className={styles.segment}>
                            <div className={styles.bodyTitle}>Customer Segment​</div>
                            {/* <div className={styles.bodyValue}>{custSegment.segment}</div> */}
                            <div className={styles.bodyValue}>
                                {custSegment.segment.split(';').map((val, i) =>
                                    <div key={i}>{val}</div>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        !divisionCode ? <Accordion
                            title="Revenue Distribution by Product Divisions (YTD)"
                            type="productDivision"
                            list={productDivisions}
                        /> : null
                    }
                    <Accordion
                        title="Revenue Distribution by Region (YTD)"
                        type="regions"
                        list={regions}
                    />
                    <Accordion title="Open Order & Backorder"
                        type="openOrder"
                        open_backorder={open_backorder}
                    />
                    <Accordion
                        title="Top 5 SGNs by Revenue​ ($M) and Contribution (%)"
                        type="sgns"
                        sgns={sgns}
                    />
                    <Accordion title="Credit Limit"
                        type="credit"
                        credit={credit}
                    />
                </div>
            </div>
        </div>)
}

export default CustomerAttribute