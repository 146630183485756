import React, { useState, useEffect } from "react";
import styles from "./score.module.scss";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import GaugeChart from "react-gauge-chart";
import * as utils from "../../utils/utils";
import { Route, Switch } from "react-router-dom";
import CXList from "../../components/cx-list/CXList";
import Tabs from "../../components/tabs/Tabs";
import CAS from '../cas/Cas'
import * as favUtils from "../../utils/favorite.utils";
import { Spin } from 'antd';
import ConsolidatedScore from "../../utils/Formula";

const Score = (props) => {

  const favInput = JSON.parse(JSON.stringify(props.favInput));
  const { cxScore, cas, volatility } = props.cxScoreDetails
  const { responseCount } = props.cxScoreDetails.cas
  const consolidatedScore = ConsolidatedScore(props.cxScoreDetails, 'current')
  const consolidatedLastScore = ConsolidatedScore(props.cxScoreDetails, 'previous')
  const { sasParent } = props
  let [FavoriteId, setFavoriteId] = useState(null)
  const [loading, setLoading] = useState(false)

  const [tabs, setTabs] = useState([
    {
      value: 'CX Score',
      isActive: sasParent === 'cx' ? true : false,
      url: '/dashboard/score'
    },
    {
      value: 'CAS',
      isActive: sasParent === 'cx' ? false : true,
      url: '/dashboard/score/cas'
    }
  ])
  let breadCrumb = [...props.breadCrumb];
  if (!breadCrumb.filter(element => element.value.toLowerCase() === 'cx score').length) {
    breadCrumb.push(
      {
        value: "CX Score",
        path: "/",
      }
    )
  }

  //remove the selected KPI from bread crumb
  if (props.selectedKPI) {
    breadCrumb = breadCrumb
      .filter(item => item.value.toLowerCase() !== props.selectedKPI.name.toLowerCase()
        && item.value.toLowerCase() !== utils.getChildViewName(props.selectedKPI.name).toLowerCase())
  }

  const setActive = (tab) => {
    const _tabs = [...tabs]
    _tabs.forEach(element => {
      if (element.value.toLowerCase() === tab.value.toLowerCase()) {
        element.isActive = true;
      } else {
        element.isActive = false;
      }
    })
    setTabs(_tabs)
    if (tab.value === 'CX Score')
      props.setSASParent('cx')
    else
      props.setSASParent('cas')
    props.history.push(tab.url)
  }

  async function getFavorites() {
    const favorites = await favUtils.getFavorites()
    props.setFavorite(favorites)
  }

  async function addToFavorite(isAlreadyFavorite) {
    try {
      setLoading(true)
      if (!isAlreadyFavorite) {
        favInput.UserId = utils.getUserEmail()
        favInput.ViewType = 2
        favInput.InputParameters.productDivision = props.selectedProductDivision;
        favInput.InputParameters.customer = props.selectedCustomer;
        favInput.InputParameters.sbs = props.selectedSBS;
        favInput.InputParameters.sasParent = props.sasParent;
        favInput.InputParameters.cxScoreDetails = props.cxScoreDetails;
        favInput.InputParameters.breadcrumb = breadCrumb;
        const FavoriteId = await favUtils.addFavorites(favInput)
        setFavoriteId(FavoriteId)
      } else {
        await favUtils.removeFavorites(FavoriteId)
        setFavoriteId(null)
      }
      setLoading(false)
      getFavorites()
    } catch (err) {
      console.log(err);
      setLoading(false)
    }
  }

  useEffect(() => {
    setFavoriteId(favUtils.chkIsFavorite(
      props.favorites,
      2,
      props.selectedProductDivision,
      props.selectedCustomer,
      props.selectedSBS))
  }, [])

  return (
    <Spin spinning={loading}>
      <div className={styles.cxContainer}>
        <BreadCrumb
          list={breadCrumb}
          {...props}
          addToFavorite={addToFavorite}
          isFavorite={FavoriteId ? true : false} />
        <div className={styles.cxIndexContainer}>
          <h2 className={styles.cxHeading}>CX Index</h2>
          <div className={`${styles.indexContent} ${'row no-gutters'}`}>
            <div className={`${'row no-gutters col-md-6'}`}>
              <div className={`${styles.chartLeft} ${'col-sm-7 col-md-6'}`}>
                {
                  cxScore.score ?
                    <GaugeChart
                      id="gauge-chart1"
                      colors={["#e71316", "#f2a041", "#4aa707"]}
                      cornerRadius={0}
                      arcWidth={0.12}
                      needleColor="#54585a"
                      textColor="#000"
                      percent={Math.round(consolidatedScore) / 100}
                      arcPadding={0.05}
                      formatTextValue={() => Math.round(consolidatedScore)}
                    /> :
                    <div className={styles.emptyGauge}></div>
                }
              </div>
              <div className={`${styles.indexChartLegend} ${'col-sm-5 col-md-6'}`}>
                <ul>
                  <li>
                    <span className={styles.percentLabel}>Consolidated Score</span>
                  </li>
                  <li>
                    {
                      cxScore.score ? <React.Fragment>
                        <span className={styles.percentValue}>{Math.round(consolidatedScore)}</span>
                        {/* <span className={(consolidatedScore === consolidatedLastScore) ?
                          styles.arrowNoChange : (consolidatedScore > consolidatedLastScore) ?
                            styles.arrowUp : styles.arrowDown}></span> */}
                      </React.Fragment> : <span className={styles.dash}>-</span>
                    }
                  </li>
                  <li>
                    <span className={styles.percentLabel}>CX Score</span>
                  </li>
                  <li>
                    {
                      cxScore.score ? <React.Fragment>
                        <span className={(cxScore.score >= cxScore.benchmark) ?
                          styles.percentValue1 : styles.percentValue2}>{Number(cxScore.score).toFixed(0)}</span>
                        <span className={(cxScore.score === cxScore.lastQuarterValue) ?
                          styles.arrowNoChange : (cxScore.score > cxScore.lastQuarterValue) ?
                            styles.arrowUp : styles.arrowDown}></span>
                      </React.Fragment> : <span className={styles.dash}>-</span>
                    }
                  </li>
                  <li>
                    <span className={styles.percentLabel}>CAS</span>
                  </li>
                  <li>
                    {
                      responseCount ? <React.Fragment>
                        <span className={(cas.score >= cas.benchmark) ?
                          styles.percentValue1 : styles.percentValue2}>{Number(cas.score).toFixed(0)}</span>
                        <span className={(cas.score === cas.lastQuarterValue) ?
                          styles.arrowNoChange : (cas.score > cas.lastQuarterValue) ?
                            styles.arrowUp : styles.arrowDown}></span>
                      </React.Fragment> : <span className={styles.dash}>-</span>
                    }
                  </li>
                  <li>
                    <span className={styles.percentLabel}>Volatility</span>
                  </li><li>
                    {
                      cxScore.score ? <span className={styles.volatility}>{volatility}</span> :
                        <span className={styles.dash}>-</span>
                    }
                  </li>
                </ul>
              </div>

            </div>
            <div className={`${styles.indexDesc} ${'d-block d-sm-none d-md-block col-md-6'}`}>
              <h3>Insights: CX Score vs CAS</h3>
              <ul>
                <li>Matching Scores on On-Time Delivery and On-Time Response</li>
                <li>Mismatch Score on Quote Cycle Time</li>
              </ul>
            </div>
          </div>
        </div>
        <Tabs tabs={tabs} setActive={setActive} value="Score" />
        <Switch>
          <Route
            exact
            path={`${props.match.path}`}
            render={() => (
              <CXList {...props} breadCrumb={breadCrumb} />
            )} ></Route>
          <Route
            exact
            path={`${props.match.path}/cas`}
            render={() => (
              <CAS {...props} />
            )} ></Route>
        </Switch>
      </div>
    </Spin>
  );
};

export default Score;
