import React, { useState } from "react";
import styles from '../weightage-style/weightage.module.scss'
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { useHttpGet } from '../../../components/hooks/useHttp'
import { Spin } from 'antd';
import * as utils from '../../../utils/utils'
import { changeWeightageFn } from '../weightage-utils/WeightUtils'
function ParentWeightage(props) {

    const breadcrumbList = [
        {
            value: "Dashboard",
            path: "/dashboard",
        },
        {
            value: "Configuration",
            path: "/dashboard/config",
        },
        {
            value: "KPI Weightage",
            path: "",
        }
    ];

    const [data, setData] = useState(null)
    const [initialData, setInitialData] = useState(null)
    const [loading, setLoading] = useState(false)

    const processData = (fetchedData) => {
        fetchedData.forEach(element => {
            element['isEdit'] = false
            element['isError'] = false
        });
        setData(JSON.parse(JSON.stringify(fetchedData)))
        setInitialData(JSON.parse(JSON.stringify(fetchedData)))
    }

    const [isLoading, fetchedData] = useHttpGet(
        `filePath=kpi-configuration&fileName=parent-weightage.json`,
        [],
        processData
    );

    const edit = (element) => {
        const newData = data.map(_element => {
            if (_element.name === element.name) {
                _element.isEdit = true
            }
            return _element
        })
        setData(newData)
    }

    const changeWeightage = (event, element) => {
        const newData = changeWeightageFn(event, element, data)
        setData(newData)
    }

    const cancel = () => {
        setData(JSON.parse(JSON.stringify(initialData)))
    }

    const save = () => {
        const isErrors = data.filter(element => element.isError)
        if (isErrors && isErrors.length) {
            console.log('errors');
        } else {
            setLoading(true)
            const kpis = data.filter(element => element.isEdit)
            kpis.forEach(element => {
                if (!element.weightage)
                    element.weightage = 0
            });
            utils.getData('kpiconfigurations/editparentkpiweightage', 'POST', kpis)
                .then(resp => {
                    processData(resp.data)
                    console.log(resp.message);
                    setLoading(false)
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false)
                })
        }
    }

    const setParentKPI = (kpi) => {
        props.setParentKPI(kpi)
        props.history.push('/dashboard/config/kpi/child')
    }
    const back = () => {
        props.history.push('/dashboard/config')
    }
    const isAdminUser = () => {
        return utils.isAdminUser()
    }
    return (
        <div className={styles.container}>
            <BreadCrumb
                list={breadcrumbList}
                {...props}
                hideFavorite={true}
            />
            <Spin spinning={isLoading || loading}>
                {
                    data && <div className={`${styles.layoutRow} ${styles.layoutAlignCenter}`}>
                         <div className={styles.buttonHolder}>
                            <div onClick={back} className={styles.backBtn}>Back</div>
                        </div>
                        <div className={styles.parent}>
                            CX Index
                        </div>
                        <div className={styles.parentLine}>
                        </div>
                        <div className={styles.linecontainer}>
                            {
                                data.map((element, index) => (
                                    <div key={index} className={styles.lines}>
                                        <div className={styles.line}></div>
                                        <div className={styles.label}>
                                            <div onClick={() => setParentKPI(element)} className={styles.labelText}>
                                                {
                                                    element.name
                                                }
                                            </div>
                                            <div className={styles.weightage}>
                                                <div className={styles.labelForwtg}>Weightage</div>
                                                {element.isEdit ? (
                                                    <React.Fragment>
                                                        <input type="text"
                                                            className={styles.weightageInput}
                                                            value={element.weightage}
                                                            onChange={(e) => changeWeightage(e, element)} />
                                                        <div onClick={save} className={styles.saveHolder}></div>
                                                        <div onClick={cancel} className={styles.cancelHolder}></div>
                                                    </React.Fragment>
                                                ) : (<div className={isAdminUser()?`${styles.displayWtg} ${styles.cPointer}`:`${styles.displayWtg}`}>{element.weightage}
                                                </div>
                                                )}
                                                {isAdminUser() && !element.isEdit && (<div className={styles.editIcon}
                                                    onClick={() => edit(element)}>
                                                </div>)}
                                            </div>
                                        </div>
                                        {
                                            element.isError && <div className={styles.error}>Sum of weightages of all child KPIs should be exactly 1</div>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }

            </Spin>
        </div>
    )
}

export default ParentWeightage