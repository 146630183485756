import React, { useEffect } from 'react'

function Login() {

    useEffect(() => {
        window.open(process.env.REACT_APP_URL, '_self')
    }, []);

    return (
        <React.Fragment></React.Fragment>
    )
}

export default Login