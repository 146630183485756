import * as utils from "./utils"
export const getConfig = () => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}kpiconfigurations`, {
            method: 'GET',
            headers: utils.headers()
        })
            .then(response => response.json())
            .then(response => resolve(response))
            .catch((error) => {
                console.log(error)
                reject(error)
                if (error.message === 'Failed to fetch') {
                    window.open(process.env.REACT_APP_URL, '_self')
                }
            })
    })
}
export const getChildKPIInfo = (KPIId) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}kpibuckets?kpiId=${KPIId}`, {
            method: 'GET',
            headers: utils.headers()
        })
            .then(response => response.json())
            .then(response => resolve(response))
            .catch((error) => {
                console.log(error)
                reject(error)
                if (error.message === 'Failed to fetch') {
                    window.open(process.env.REACT_APP_URL, '_self')
                }
            })
    })
}


export const submitChildKPI = (data) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}kpiconfigurations/kpibuckets`, {
            method: 'PUT',
            headers: utils.headers(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(response => resolve(response))
            .catch((error) => {
                console.log(error)
                reject(error)
                if (error.message === 'Failed to fetch') {
                    window.open(process.env.REACT_APP_URL, '_self')
                }
            })
    })
}