import React, { useEffect, useState } from "react";
import styles from "./kpi-performance.module.scss";
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb'
import { Dropdown, Button } from "react-bootstrap"
import * as configUtils from "../../../utils/config.utils";
import { Spin } from 'antd';
import * as utils from '../../../utils/utils'

function KPIperformance(props) {
    let currentKPI = [];
    let [configData, setConfig] = useState([])
    let [selectedKPI, setselectedKPI] = useState([])
    let [selectedChildKPI, setSelectedChildName] = useState([])
    let [parentKPI, setParentKPI] = useState([])
    let [firstChildKPI, setFirstChildKPI] = useState([])
    let [secondChildKPI, setSecondChildKPI] = useState([])
    const [loading, setLoading] = useState(false)
    let [showChildDropDown, setshowChildDropDown] = useState(false);
    let [childKPIInfo, setchildKPI] = useState(currentKPI)

    let filterArr = []
    const setKPIValues = (kpiConfig) => {
        if (kpiConfig && kpiConfig.KPIDetails.length) {
            kpiConfig.KPIDetails.forEach(kpi => {
                if (kpi.ParentKPI === null) {
                    parentKPI.push({ name: kpi.KPIName, id: kpi.KPIId })
                }

            });
            setParentKPI(parentKPI)
            setselectedKPI(parentKPI[0].name)
            setcurrentChildKPI(parentKPI[0].id, false)
        }

    }
    const setcurrentChildKPI = (value, issecondDropDownSelected) => {
        firstChildKPI = configData.KPIDetails.filter(obj => obj.ParentKPI === value && obj.ParentKPI !== null);
        if (!issecondDropDownSelected) {
            setFirstChildKPI(firstChildKPI)
            setSelectedChildName(firstChildKPI[0].KPIName)
        }
        if (firstChildKPI && firstChildKPI.length) {
            setSecondLevelKPI(firstChildKPI[0].KPIId, firstChildKPI, issecondDropDownSelected)
        }

    }
    const setSelectedChildKPI = (value) => {
        let arr = []
        secondChildKPI = configData.KPIDetails.filter(obj => obj.ParentKPI === value && obj.ParentKPI !== null);
        secondChildKPI.forEach(firstKPI => {
            let currentChildKPI = configData.KPIBucketValues.filter(obj => obj.KPIId === firstKPI.KPIId)
            arr.push({ KPIId: currentChildKPI[0].KPIId, KPIName: currentChildKPI[0].KPIName, Unit: currentChildKPI[0].Unit, BucketValues: currentChildKPI[0].BucketValues })

        })
        setchildKPI(arr)
    }

    const setSecondLevelKPI = (firstChildKPiId, firstChildKPI, issecondDropDownSelected) => {
        secondChildKPI = configData.KPIDetails.filter(obj => obj.ParentKPI === firstChildKPiId && obj.ParentKPI !== null);
        if (secondChildKPI && secondChildKPI.length === 0) {
            if (firstChildKPI && firstChildKPI.length) {
                firstChildKPI.forEach(firstKPI => {
                    let currentChildKPI = configData.KPIBucketValues.filter(obj => obj.KPIId === firstKPI.KPIId)
                    if (currentChildKPI && currentChildKPI.length) {
                        filterArr.push({ KPIId: currentChildKPI[0].KPIId, KPIName: currentChildKPI[0].KPIName, Unit: currentChildKPI[0].Unit, BucketValues: currentChildKPI[0].BucketValues })
                    }
                })
                setchildKPI(filterArr)
                if (!issecondDropDownSelected) {
                    setshowChildDropDown(false)
                }
            }
        }
        else {
            if (secondChildKPI && secondChildKPI.length) {
                secondChildKPI.forEach(firstKPI => {
                    let currentChildKPI = configData.KPIBucketValues.filter(obj => obj.KPIId === firstKPI.KPIId)
                    filterArr.push({ KPIId: currentChildKPI[0].KPIId, KPIName: currentChildKPI[0].KPIName, Unit: currentChildKPI[0].Unit, BucketValues: currentChildKPI[0].BucketValues })

                })
                setchildKPI(filterArr)
                if (!issecondDropDownSelected) {
                    setshowChildDropDown(true)
                }
            }
        }

    }

    async function loadKPIData() {
        try {
            setLoading(true)
            configData = await configUtils.getConfig()
            setConfig(configData);
            setKPIValues(configData)
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }

    useEffect(() => {
        loadKPIData()
    }, [])
    const breadcrumbList = [
        {
            value: "Dashboard",
            path: "/dashboard",
        },
        {
            value: "Configuration",
            path: "/dashboard/config",
        },
        {
            value: "KPI Performance Buckets",
            path: "",
        }
    ];

    const navigateToEditPage = (childInfo) => {
        let data = {}
        data.selectedKPI = selectedKPI;
        data.childKPIInfo = childKPIInfo
        data.configData = configData
        data.childInfo = childInfo
        data.selectedChildKPI = selectedChildKPI
        props.setConfigKPI(data)
        props.history.push("/dashboard/config/kpi/updateperformance");
    }
    const selectParentKPI = (value) => {
        let currentParentKpi = parentKPI.filter(obj => obj.id === value);
        setselectedKPI(currentParentKpi[0].name)
        setcurrentChildKPI(value)
    }
    const selectChildKPI = (value) => {
        let currentParentKpi = firstChildKPI.filter(obj => obj.KPIId === value);
        setSelectedChildName(currentParentKpi[0].KPIName)
        setSelectedChildKPI(value, true)
    }

    const isAdminUser = () => {
        return utils.isAdminUser()
    }

    return (
        <Spin spinning={loading} >
            <div className={styles.config_container}>
                <BreadCrumb
                    list={breadcrumbList}
                    hideFavorite={true}
                    {...props} />
                <div className={`row ${styles.marTop15} ${styles.resetMargin}`}>
                    <div className={`col-md-5 ${styles.resetPadding}`}>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className={`${styles.btn_success_config}`}>
                                <span title={selectedKPI}>{selectedKPI}</span>
                                <span className={styles.styleArrow}>X</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className={styles.dropdownMenu1}>
                                {
                                    parentKPI.map((kpi, parentIdx) =>
                                    (
                                        <Dropdown.Item onClick={() => selectParentKPI(kpi.id)} key={parentIdx} title={kpi.name}><span>{kpi.name}</span></Dropdown.Item>
                                    )
                                    )
                                }

                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <div className={`col-md-5 ${styles.resetRighPadding}`}>
                        {showChildDropDown &&
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className={`${styles.btn_success_config}`}>
                                    <span title={selectedChildKPI}>{selectedChildKPI}</span>
                                    <span className={styles.styleArrow}>X</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={styles.dropdownMenu1}>
                                    {
                                        firstChildKPI.map((kpi, childIdx) =>
                                        (
                                            <Dropdown.Item className="list_KPI_item" onClick={() => selectChildKPI(kpi.KPIId)} title={kpi.KPIName} key={childIdx}><span>{kpi.KPIName}</span></Dropdown.Item>
                                        )
                                        )
                                    }

                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </div>

                </div>
                <div className={`row ${styles.mTop10} ${styles.resetMargin}`}>
                    <div className={`col-md-10 ${styles.table_header} ${styles.resetPadding}`}>
                        <div className={`row ${styles.resetMargin}`}>
                            <div className={`col-md-6 ${styles.center}`}>
                                <span className={`${styles.number_sign}`}>#</span>
                                <span>Child KPI Name</span>
                            </div>
                            <div className={`col-md-1 ${styles.center}`}>

                                <span>Unit</span>
                            </div>
                            <div className={`col-md-3 ${styles.center}`}>

                                <span>Performance Bucket</span>
                            </div>
                            <div className={`col-md-1 ${styles.center}`}>

                                <span>Scale</span>
                            </div>
                            <div className={`col-md-1 ${styles.unit}`}>

                                {/* <span>edit</span> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-2">

                    </div>
                </div>
                {
                    childKPIInfo.length ? childKPIInfo.map((childInfo, index) =>

                        <div className={`row ${styles.rowHeight} ${styles.resetMargin}`} key={index}>
                            <div className={`col-md-10 ${styles.resetPadding}`}>
                                <div className={`row ${styles.borderBtm} ${styles.resetMargin}`}>
                                    <div className={`col-md-6 ${styles.child_kpi} ${(index % 2 == 0) ? '' : `${styles.applybg1}`}`}>
                                        <span className={`${styles.counting}`}>{index + 1}</span>
                                        <span>{childInfo.KPIName}</span>
                                    </div>
                                    <div className={`col-md-1 ${styles.unit}`}>

                                        <span>{childInfo.Unit}</span>
                                    </div>
                                    <div className={`col-md-3 ${styles.center}`}>
                                        {
                                            childInfo.BucketValues.map((bucket, buketIdx) =>
                                                <div className={styles.mTop10} key={buketIdx}>{bucket.Operator}
                                                    {
                                                        bucket.Value.map((element, index) => (
                                                            <React.Fragment key={index} >
                                                                {
                                                                    bucket.Operator === 'between' && index !== 0 ? <span> &nbsp;& {element}</span> : <span>&nbsp;{element}</span>
                                                                }
                                                            </React.Fragment>
                                                        ))
                                                    }

                                                </div>

                                            )
                                        }

                                    </div>
                                    <div className={`col-md-1 ${styles.center}`}>
                                        {
                                            childInfo.BucketValues.map((scale, idx) =>
                                                <div className={styles.mTop10} key={idx}>{scale.Scale}</div>
                                            )
                                        }
                                    </div>
                                    {
                                        isAdminUser() && <div className={`col-md-1 ${styles.unit}`}>
                                            <Button variant="primary" className={styles.customBtn} onClick={() => navigateToEditPage(childInfo)} key={index}>Edit</Button>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="col-md-2">

                            </div>
                        </div>
                    ) : null}
            </div>
        </Spin>
    )

}
export default KPIperformance;