import "./navbar.scss";

const Navbar = (props) => {

  const goHome = () => {
    props.history.push('/dashboard')
  }

  return (
    <nav className="navBg">
      <ul>
        <li>
          <a className="active">
            <i onClick={goHome} className="dashboard-icn"></i>
            <span>Dashboard</span>
          </a>
        </li>
        {/* <li>
          <a>
            <i className="view-icn"></i>
            <span>CCO View</span>
          </a>
        </li>
        <li>
          <a>
            <i className="summary-icn"></i>
            <span>CX Summary</span>
          </a>
        </li>
        <li>
          <a>
            <i className="revenue-icn"></i>
            <span>Revenue/P&amp;L</span>
          </a>
        </li>
        <li>
          <a>
            <i className="account-icn"></i>
            <span>Account Hierarchies</span>
          </a>
        </li> */}
      </ul>
    </nav>
  );
};

export default Navbar;
