import React from 'react'
import styles from './select.module.scss'

function Select(props) {
    const { values, type } = props;
    return (
        <select className={styles.select}
            onChange={(event) => props.handleChange(event, type)} name={type} id={type}>
            {
                values.map((value, i) => <option key={i} value={value}>{value}</option>)
            }
        </select>
    )
}

export default Select