import { connect } from 'react-redux'
import EditKPIperformance from '../../pages/config/performance/EditKPIperformance'

const mapStateToProps = state => {
    return {
        config: state.KpiConfigReducer ? state.KpiConfigReducer.data : null
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditKPIperformance)
