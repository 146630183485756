import { SET_PRODUCT_DIVISION } from "../constant";

const ProductDivisionReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_PRODUCT_DIVISION:
            return {
                ...state,
                data: action.data
            }
        default:
            return state
    }
}

export default ProductDivisionReducer