import React, { useState, useEffect } from "react";
import styles from '../weightage-style/weightage.module.scss'
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { Spin } from 'antd';
import * as utils from '../../../utils/utils'
import { changeWeightageFn } from '../weightage-utils/WeightUtils'

function ChildWeightage(props) {

    const { kpi } = props;
    const breadcrumbList = [
        {
            value: "Dashboard",
            path: "/dashboard",
        },
        {
            value: "Configuration",
            path: "/dashboard/config",
        },
        {
            value: "KPI Weightage",
            path: "/dashboard/config/kpi/parent",
        },
        {
            value: kpi.name,
            path: "",
        }
    ];

    const [data, setData] = useState(null)
    const [initialData, setInitialData] = useState(null)
    const [loading, setLoading] = useState(false)

    const processData = (data) => {
        const child = data.child
        child.forEach(element => {
            element['isEdit'] = false
            element['isError'] = false
        });
        setData(JSON.parse(JSON.stringify(child)))
        setInitialData(JSON.parse(JSON.stringify(child)))
    }

    useEffect(() => {
        setLoading(true)
        utils.getData(`kpiconfigurations/getchildkpiweightage`,
            'POST',
            {
                kpi: kpi.name
            }).then(resp => {
                processData(resp.data)
                setLoading(false)
            }).catch(err => setLoading(false))
    }, [])

    const edit = (element) => {
        const newData = data.map(_element => {
            if (_element.name === element.name) {
                _element.isEdit = true
            }
            return _element
        })
        setData(newData)
    }

    const changeWeightage = (event, element) => {
        const newData = changeWeightageFn(event, element, data)
        setData(newData)
    }

    const cancel = () => {
        setData(JSON.parse(JSON.stringify(initialData)))
    }

    const save = () => {
        const isErrors = data.filter(element => element.isError)
        if (isErrors && isErrors.length) {
            console.log('errors');
        } else {
            setLoading(true)
            const input = {
                name: kpi.name,
                child: data
            }
            utils.getData('kpiconfigurations/editchildkpiweightage', 'POST', input)
                .then(resp => {
                    processData(resp.data)
                    console.log(resp.message);
                    setLoading(false)
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false)
                })
        }
    }

    const back = () => {
        props.history.push('/dashboard/config/kpi/parent')
    }

    const showDivision = (element) => {
        if (element.divisons) {
            props.setChildKPI(element)
            props.history.push('/dashboard/config/kpi/child/division')
        }
    }
    const isAdminUser = () => {
        return utils.isAdminUser()
    }
    return (
        <div className={styles.container}>
            <BreadCrumb
                list={breadcrumbList}
                {...props}
                hideFavorite={true}
            />
            <Spin spinning={loading}>
                {
                    data && <div className={`${styles.layoutRow} ${styles.layoutAlignCenter} ${styles.reduceWidth}`}>
                        <div className={styles.buttonHolder}>
                            <div onClick={back} className={styles.backBtn}>KPIs</div>
                        </div>
                        <div className={styles.chieldLabel}>
                            <div className={styles.labelTextNoChild}>{kpi.name}</div>
                            <div className={styles.weightage}>
                                <div className={styles.labelForwtg}>Weightage</div>
                                <div className={`${styles.displayWtg} ${styles.cAuto}`}>{kpi.weightage}</div>
                            </div>
                        </div>
                        <div className={styles.parentLine}>
                        </div>
                        <div className={`${styles.linecontainer} ${styles.childNode}`}>
                            {
                                data.map((element, index) => (
                                    <div key={index} className={styles.lines}>
                                        <div className={styles.line}></div>
                                        <div className={styles.label}>
                                            <div onClick={() => showDivision(element)} className={element.divisons ? styles.labelText : styles.labelTextNoChild}>
                                                {
                                                    element.name
                                                }
                                            </div>
                                            <div className={styles.weightage}>
                                                <div className={styles.labelForwtg}>Weightage</div>
                                                {element.isEdit ? (
                                                    <React.Fragment>
                                                        <input type="text"
                                                            className={styles.weightageInput}
                                                            value={element.weightage}
                                                            onChange={(e) => changeWeightage(e, element)} />
                                                        <div onClick={save} className={styles.saveHolder}></div>
                                                        <div onClick={cancel} className={styles.cancelHolder}></div>
                                                    </React.Fragment>
                                                ) : (<div className={isAdminUser()?`${styles.displayWtg} ${styles.cPointer}`:`${styles.displayWtg}`}>{element.weightage}
                                                </div>
                                                )}
                                                {isAdminUser() && !element.isEdit && (<div className={styles.editIcon}
                                                    onClick={() => edit(element)}>
                                                </div>)}
                                            </div>
                                        </div>
                                        {
                                            element.isError && <div className={styles.error}>Sum of weightages of all child KPIs should be exactly 1</div>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }

            </Spin>
        </div>
    )
}

export default ChildWeightage