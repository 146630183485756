import { SET_PARENT_KPI, SET_CHILD_KPI } from "../constant";

const KPIWeightageReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_PARENT_KPI:
            return {
                ...state,
                parentKPI: action.data
            }
        case SET_CHILD_KPI:
            return {
                ...state,
                childKPI: action.data
            }
        default:
            return state
    }
}

export default KPIWeightageReducer;