import React from "react";
import "./title.scss";

function Title(props) {
  const { value, count } = props
  return count ? <h1 className="heading">{`${value} (${count}) `}</h1>
    :
    <h1 className="heading">{value}</h1>
}

export default Title;
