export const changeWeightageFn = (event, element, data) => {
    let total = getSum(element, data);
    const re = /^\d*(\.\d{0,4})?$/;
    const newData = data.map(_element => {
        if (_element.name === element.name) {
            if (re.test(event.target.value)) {
                _element.weightage = event.target.value
                if (isNaN(Number(event.target.value))) {
                    _element.isError = true
                } else {
                    if (total + Number(event.target.value) === 1) {
                        _element.isError = false
                    } else {
                        _element.isError = true
                    }
                }
            }
        } else if (_element.isEdit) {
            if (total + Number(event.target.value) === 1) {
                _element.isError = false
            } else {
                _element.isError = true
            }
        }
        return _element
    })
    return newData
}

// get the sum expect the current row
const getSum = (element, data) => {
    let total = 0;
    data.forEach(_element => {
        if (_element.name !== element.name) {
            total += Number(_element.weightage)
        }
    })
    return Number(total)
}