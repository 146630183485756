import React from 'react'
import styles from './barchartfooter.module.scss'

export function Footer(props) {
    const { y1axis, y2axis } = props
    return (
        <div className={styles.skusLegend}>
            <div className={styles.footer}>
                <div className={styles.skuRevenue}></div>
                <div>{y1axis}</div>
            </div>
        </div>
    )
}

export function Header(props) {
    const { y1axis, y2axis } = props
    return (
        <div className={styles.skusLegend}>
            <div className={styles.footer}>
                <div className={styles.skuGrowth}></div>
                <div>{y2axis}</div>
            </div>
        </div>
    )
}