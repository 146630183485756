import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import * as utils from '../../../utils/utils';
import style from '../kpi.module.scss'
import PowerBi from '../../../components/powerbi/PowerBi';
import * as favUtils from '../../../utils/favorite.utils';
import { Spin } from 'antd';

const PrimaryView = props => {

    const favInput = JSON.parse(JSON.stringify(props.favInput));
    let [FavoriteId, setFavoriteId] = useState(null)
    let breadCrumb = [...props.breadCrumb]
    const [loading, setLoading] = useState(false)

    if (!breadCrumb.filter(element => element.value.toLowerCase() === props.selectedKPI.name.toLowerCase()).length) {
        breadCrumb.push(
            {
                value: props.selectedKPI.name,
                path: "/",
            }
        )
    }

    //remove the selected KPI from bread crumb
    if (props.selectedKPI) {
        breadCrumb = breadCrumb
            .filter(item => item.value.toLowerCase() !== utils.getChildViewName(props.selectedKPI.name).toLowerCase())
    }

    const getCurrentBreadCrumb = () => {
        breadCrumb.map(element => {
            if (element.value.toLowerCase() === props.selectedKPI.name.toLowerCase()) {
                element.path = "/dashboard/primaryview"
            }
        })
        return breadCrumb;
    }

    const childView = () => {
        props.setBreadCrumb(getCurrentBreadCrumb())
        props.history.push('/dashboard/childview')
    }

    const goBack = () => {
        props.history.push('/dashboard/score')
    }

    async function getFavorites() {
        const favorites = await favUtils.getFavorites()
        props.setFavorite(favorites)
    }

    async function addToFavorite(isAlreadyFavorite) {
        try {
            setLoading(true)
            if (!isAlreadyFavorite) {
                favInput.UserId = utils.getUserEmail()
                favInput.ViewType = 3
                favInput.InputParameters.productDivision = props.selectedProductDivision;
                favInput.InputParameters.customer = props.selectedCustomer;
                favInput.InputParameters.sbs = props.selectedSBS;
                favInput.InputParameters.sasParent = props.sasParent;
                favInput.InputParameters.cxScoreDetails = props.cxScoreDetails;
                favInput.InputParameters.breadcrumb = breadCrumb;
                favInput.InputParameters.kpi = props.selectedKPI;
                const FavoriteId = await favUtils.addFavorites(favInput)
                setFavoriteId(FavoriteId)
            } else {
                await favUtils.removeFavorites(FavoriteId)
                setFavoriteId(null)
            }
            setLoading(false)
            getFavorites()
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    }

    useEffect(() => {
        setFavoriteId(favUtils.chkIsFavorite(
            props.favorites,
            3,
            props.selectedProductDivision,
            props.selectedCustomer,
            props.selectedSBS,
            props.selectedKPI))
    }, [])

    return (
        <Spin spinning={loading}>
            <div className={style.primarycontainer}>
                <BreadCrumb
                    list={breadCrumb}
                    {...props}
                    addToFavorite={addToFavorite}
                    isFavorite={FavoriteId ? true : false} />
                <div className={style.layoutbx}>
                    <div className={style.childheading}>
                        <div className={style.backBtn} onClick={goBack}>Back</div>
                        <div className={style.nextbtn} onClick={childView}>Detailed View</div>
                    </div>
                    <PowerBi view={props.selectedKPI.primary} />
                </div>
            </div>
        </Spin>
    );
}

export default PrimaryView

