import React from 'react'
import styles from '../search.module.scss'
import * as utils from '../../../utils/utils';

function SearchItem(props) {

    const { name, nsgn, group_productdivision, cxScore, cas, lastQuarterCxScore, cxCustomerBenchmark, whiteGlove,lastQuarterCas,casCustomerBenchmark, responseCount } = props.customer
    //const { responseCount } = cas

    return (
        <div onClick={() => props.setCustomer(props.customer)} className={styles.customerItem}>
            <div className={styles.customer}>
                <div className={styles.searchItem}>
                    <div>{utils.capitalize(name)}</div>
                </div>
                <div className={styles.searchItem}>
                    <span className={styles.label}>CX</span>
                    {
                        !cxScore ? <span className={styles.dash}>-</span> :
                            <span
                                className={cxScore >= cxCustomerBenchmark ?
                                    `${styles.value} ${styles.green}` : `${styles.value} ${styles.red}`}>
                                {cxScore.toFixed(0)}
                                <span
                                    className={
                                        (cxScore == lastQuarterCxScore) ?
                                            `${styles.arrowNoChange}` :
                                            (cxScore > lastQuarterCxScore) ?
                                                `${styles.arrowUp}` : `${styles.arrowDown}`
                                    }
                                ></span>
                            </span>
                    }
                </div>
                <div className={styles.searchItem}>
                    <span className={styles.label}>CAS</span>
                    {
                        !responseCount ? <span className={styles.dash}>-</span> :
                            <span
                                className={cas >= casCustomerBenchmark ?
                                    `${styles.value} ${styles.green}` : `${styles.value} ${styles.red}`}>
                                {cas}
                                <span
                                    className={
                                        (cas == lastQuarterCas) ?
                                            `${styles.arrowNoChange}` :
                                            (cas > lastQuarterCas) ?
                                                `${styles.arrowUp}` : `${styles.arrowDown}`
                                    }
                                ></span>
                            </span>
                    }
                </div>
            </div>
            <div className={styles.label}>{utils.capitalize(nsgn)}{
                nsgn && nsgn.length ? <span>, </span> : null
            }{group_productdivision}</div>
        </div>
    )
}

export default SearchItem