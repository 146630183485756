import { connect } from 'react-redux'
import ProductDivision from '../../pages/detailed-view/ProductDivision'
import * as consts from '../../store/constant'
import favInput from '../../assets/json/user-favorites/input.json'

const mapStateToProps = state => {
    return {
        selectedProductDivision: state.ProductDivisionReducer ? state.ProductDivisionReducer.data : null,
        favInput: favInput,
        favorites: state.FavoriteReducer ? state.FavoriteReducer.data : null
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCXScoreData: (data) => dispatch({
            type: consts.SET_CXSCORE_DATA,
            data: data
        }),
        setBreadCrumb: (data) => dispatch({
            type: consts.SET_BREAD_CRUMB,
            data: data
        }),
        setNBA: (data) => dispatch({
            type: consts.SET_NBA,
            data: data
        }),
        setNBABreadCrumb: (data) => dispatch({
            type: consts.SET_NBA_BREAD_CRUMB,
            data: data
        }),
        setSASParent: (data) => dispatch({
            type: consts.SET_SAS_PARENT,
            data: data
        }),
        setFavorite: (data) => dispatch({
            type: consts.SET_FAVORITE,
            data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDivision)
