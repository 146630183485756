import { SET_GROUP } from "../constant";

const KpiConfigReducer = (state = {}, action) => {
    switch (action.type) {
        case 'childkpiInfo':
            return {
                ...state,
                data: action.data
            }
        default:
            return state
    }
}

export default KpiConfigReducer