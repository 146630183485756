import React, { useState } from 'react'
import { CreateScoreLinks } from '../../pages/score/Score.utils'
import CustomTable from '../custom-table/CustomTable'
import * as utils from "../../utils/utils";

const CXList = (props) => {

    let breadCrumb = [...props.breadCrumb];
    const list = CreateScoreLinks(props)
    const [ascending, setAscending] = useState(false)
    const [cxList, setCXList] = useState(
        [...list].sort(
            utils.sorting("score", "number", ascending)
        )
    );

    const [columns, setColumns] = useState([
        {
            name: "CX Index",
            selector: "name",
        },
        {
            name: "Score",
            selector: "score",
            sortable: true,
            isActive: true,
        },
        {
            name: "Last Quarter Comparison",
            selector: "difference",
            sortable: true,
            isActive: false,
        },
        {
            name: "Data Points",
            selector: "dataPoints",
            sortable: true,
            isActive: false,
        },
        {
            name: "Responsible Process Area/Team",
            selector: "responsibleProcessArea",
        },
    ]);

    const listSorting = (item) => {
        if (item.sortable) {
            const dataArray = [...cxList];
            let list = dataArray.sort(
                utils.sorting(item.selector, "number", !ascending)
            );
            setCXList(list);
            setAscending(!ascending)

            let columnsArray = [...columns];
            columnsArray.map((element) => {
                if (element.name === item.name) {
                    element.isActive = true;
                } else if (element.isActive !== undefined) {
                    element.isActive = false;
                }
            });
            setColumns(columnsArray);
        }
    };

    const getCurrentBreadCrumb = () => {
        breadCrumb.map(element => {
            if (element.value.toLowerCase() === 'cx score') {
                element.path = "/dashboard/score"
            }
        })
        return breadCrumb;
    }

    const primaryView = (item) => {
        props.setBreadCrumb(getCurrentBreadCrumb())
        props.setKPI(item)
        props.history.push('/dashboard/primaryview')
    }

    return <CustomTable
        columns={columns}
        cxList={cxList}
        listSorting={listSorting}
        primaryView={primaryView}
        ascending={ascending} />
}

export default CXList