import React from 'react'
import styles from './credit.module.scss';
import * as utils from '../../../utils/utils';

function Credit(props) {
    const { total, available } = props
    const percentage = ((available / total) * 100).toFixed(0)
    return (
        <div className={styles.orderContainer}>
            <div className={styles.header}>
                <div>
                    <span className={styles.label}>Total Credit Limit </span>
                    <span className={styles.value}>${utils.convertToInternationalCurrencySystem(total.toFixed(0))}</span>
                </div>
                <div>
                    <span className={styles.label}>Available Credit </span>
                    <span className={styles.value}>${utils.convertToInternationalCurrencySystem(available.toFixed(0))} ({isNaN(percentage)?0:percentage}%)</span>
                </div>
            </div>
            <div className={styles.barProgress} >
                <div className={percentage === 100 ? styles.myBarFull : styles.myBar} style={{ width: `${percentage}%` }}></div>
            </div>
        </div>
    )
}

export default Credit