import React from 'react'
import styles from "./tabs.module.scss";

const Tabs = (props) => {
    return (
        <div>
            <ul className={props.value==='Score'?styles.nav:styles.nav1}>
                {
                    props.tabs.map((element, index) => (
                        <li key={index} >
                            <button
                                className={element.isActive ? styles.active : null}
                                onClick={() => props.setActive(element)}>{element.value}
                            </button>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default Tabs