import React, { useState } from 'react'
import "./Header.scss";
import Search from '../../containers/search/QuickSearch'
import debouncingMethod from './Search'
import * as utils from '../../utils/utils';

const Header = (props) => {

  const { selectedProductDivision } = props
  const [search, setSearch] = useState('')
  const [customers, setCustomers] = useState([])
  const productDivision = (selectedProductDivision && selectedProductDivision.code) ? selectedProductDivision.code : '';
  const showHeader = false;
  const checkPath = () => {
    const path = props.location.pathname;
    if(path === '/dashboard' || path === '/dashboard/productdivision'){
      return true
    }
  }
  const getName = () => {
    const name = props.decoded.email.split('@')[0]
    const fName = name.split('.')[0]
    const lName = name.split('.')[1]
    return fName + ' ' + lName
  }
  const openDropdown = () => document.getElementById("configDropdown").classList.toggle("show")

  // Close the dropdown if the user clicks outside of it
  window.onclick = function (event) {
    if (!event.target.matches('.dropdown')) {
      let dropdowns = document.getElementsByClassName("dropdown-content");
      for (let i = 0; i < dropdowns.length; i++) {
        let openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }
  const getGroupData = (result1) => {
    let group = {}
    group = (result1.data && result1.data.hits && result1.data.hits.hits && result1.data.hits.hits.length > 0) ? result1.data.hits.hits[0] : [];
    if (group.length === 0) return group
    let new_array = []
    if (productDivision) {
      const customerFilterdArr = group.inner_hits['productDivisions.customers'].hits
      if (customerFilterdArr && customerFilterdArr.hits && customerFilterdArr.hits.length > 0) {
        new_array = customerFilterdArr.hits.map(function callback(element) {
          let custObj = {};
          custObj['name'] = (element.fields['productDivisions.customers.name.keyword'])?element.fields['productDivisions.customers.name.keyword'][0]:'-';
          custObj.cxScore = (element.fields['productDivisions.customers.cxScore'])?element.fields['productDivisions.customers.cxScore'][0]:'-';
          custObj.lastQuarterCxScore = (element.fields['productDivisions.customers.lastQuarterCxScore'])?element.fields['productDivisions.customers.lastQuarterCxScore'][0]:'-';
          custObj.lastQuarterCas = (element.fields['productDivisions.customers.lastQuarterCas'])?element.fields['productDivisions.customers.lastQuarterCas'][0]:'-';
          custObj.responseCount = (element.fields['productDivisions.customers.responseCount'])?element.fields['productDivisions.customers.responseCount'][0]:'-';
          custObj.code = (element.fields['productDivisions.customers.code.keyword'])?element.fields['productDivisions.customers.code.keyword'][0]:'-';
          custObj.id = (element.fields['productDivisions.customers.id'])?element.fields['productDivisions.customers.id'][0]:'-';
          custObj.name = (element.fields['productDivisions.customers.name.keyword'])?element.fields['productDivisions.customers.name.keyword'][0]:'-';
          custObj.cas = (element.fields['productDivisions.customers.cas'])?element.fields['productDivisions.customers.cas'][0]:'-';
          custObj.whiteGlove = (element.fields['productDivisions.customers.whiteGlove.keyword'])?element.fields['productDivisions.customers.whiteGlove.keyword'][0]:'-';
          custObj.volatility = (element.fields['productDivisions.customers.volatility.keyword'])?element.fields['productDivisions.customers.volatility.keyword'][0]:'-';
          custObj.recommendation = (element.fields['productDivisions.customers.recommendation.keyword'])?element.fields['productDivisions.customers.recommendation.keyword'][0]:'-';
          return custObj
        })
      }
      let productDivisions = [...group['_source'].productDivisions];
      let pdIndex = productDivisions.findIndex(item => item.code === productDivision);
      productDivisions[pdIndex].customers = new_array;
      return productDivisions;
    } else {
      const customerFilterdArr = group.inner_hits['group.customers'].hits
      if (customerFilterdArr && customerFilterdArr.hits && customerFilterdArr.hits.length > 0) {
        new_array = customerFilterdArr.hits.map(function callback(element,index) {
          let custObj = {};
          custObj['name'] = (element.fields['group.customers.name.keyword'])?element.fields['group.customers.name.keyword'][0]:'-';
          custObj.cxScore = (element.fields['group.customers.cxScore'])?element.fields['group.customers.cxScore'][0]:'-';
          custObj.lastQuarterCxScore = (element.fields['group.customers.lastQuarterCxScore'])?element.fields['group.customers.lastQuarterCxScore'][0]:'-';
          custObj.lastQuarterCas = (element.fields['group.customers.lastQuarterCas'])?element.fields['group.customers.lastQuarterCas'][0]:'-';
          custObj.responseCount = (element.fields['group.customers.responseCount'])?element.fields['group.customers.responseCount'][0]:'-';
          custObj.code = (element.fields['group.customers.code.keyword'])?element.fields['group.customers.code.keyword'][0]:'-';
          custObj.id = (element.fields['group.customers.id'])?element.fields['group.customers.id'][0]:'-';
          custObj.name = (element.fields['group.customers.name.keyword'])?element.fields['group.customers.name.keyword'][0]:'-';
          custObj.cas = (element.fields['group.customers.cas'])?element.fields['group.customers.cas'][0]:'-';
          custObj.whiteGlove = (element.fields['group.customers.whiteGlove.keyword'])?element.fields['group.customers.whiteGlove.keyword'][0]:'-'
          custObj.volatility = (element.fields['group.customers.volatility.keyword'])?element.fields['group.customers.volatility.keyword'][0]:'-';
          custObj.recommendation = (element.fields['group.customers.recommendation.keyword'])?element.fields['group.customers.recommendation.keyword'][0]:'-';
          return custObj
        })
      }
      group['_source'].group.customers = new_array;
      return group['_source'];
    }
  }
  const onChange = (event) => {
    setSearch(event.target.value)
    if (event.target.value.length >= 3) {
      setCustomers([])

      debouncingMethod(event.target.value.toLowerCase(), productDivision).then(resp => {
        let customers = []

        // if (resp.group) {
        //   resp.group.forEach(element => {
        //     element['group_productdivision'] = 'Life Sciences';
        //     element['cxValue'] = element.cxScore.score;
        //     element['casValue'] = element.cas.score;
        //     element['group'] = 'Life Sciences';
        //     customers.push(element);
        //   });
        // }
        // if (resp.productDivisions) {
        //   resp.productDivisions.forEach(_element => {
        //     _element.customers.forEach(element => {
        //       element['group_productdivision'] = _element.code;
        //       element['cxValue'] = element.cxScore.score;
        //       element['casValue'] = element.cas.score;
        //       element['productDivision'] = {
        //         name: _element.code,
        //         code: _element.code,
        //         cas: _element.cas,
        //         cxScore: _element.cxScore,
        //         lastQuarterCas: _element.lastQuarterCas,
        //         lastQuarterCxScore: _element.lastQuarterCxScore,
        //         recommendation: _element.recommendation,
        //         volatility: _element.volatility
        //       }
        //       customers.push(element);
        //     });
        // });
        // }
        let cutomerfilterResult = getGroupData(resp)
        if (productDivision) {
          cutomerfilterResult.forEach(_element => {
            if (_element && _element.customers) {
              _element.customers.forEach(element => {
                element['group_productdivision'] = _element.code;
                element['cxCustomerBenchmark'] = _element.cxCustomerBenchmark
                element['casCustomerBenchmark'] = _element.casCustomerBenchmark
                element['cxValue'] = element.cxScore;
                element['casValue'] = element.cas;
                element['responseCount'] = element.responseCount;
                element['whiteGlove'] = element.whiteGlove;
                element['volatility'] = element.volatility;
                // element['productDivision'] = {
                //   name: _element.code,
                //   code: _element.code,
                //   cas: _element.cas,
                //   cxScore: _element.cxScore,
                //   lastQuarterCas: _element.lastQuarterCas,
                //   lastQuarterCxScore: _element.lastQuarterCxScore,
                //   recommendation: _element.recommendation,
                //   volatility: _element.volatility
                // }
                customers.push(element);
              });
            }
          });
        } else {
          if (cutomerfilterResult.group) {
            cutomerfilterResult.group.customers.forEach(element => {
              element['group_productdivision'] = 'Life Sciences';
              element['cxCustomerBenchmark'] = (cutomerfilterResult && cutomerfilterResult.group && cutomerfilterResult.group.cxCustomerBenchmark)?cutomerfilterResult.group.cxCustomerBenchmark:'-';
              element['casCustomerBenchmark'] = (cutomerfilterResult && cutomerfilterResult.group && cutomerfilterResult.group.casCustomerBenchmark)?cutomerfilterResult.group.casCustomerBenchmark:'-';
              element['cxValue'] = element.cxScore;
              element['casValue'] = element.cas;
              element['responseCount'] = element.responseCount;
              element['whiteGlove'] = element.whiteGlove;
              element['volatility'] = element.volatility;
              element['group'] = 'Life Sciences';
              customers.push(element);
            });
          }
        }


        //customers = customers.sort(utils.sorting("name", "string", true))
        setCustomers(customers)
      })
    }
  }

  const cancel = () => {
    setSearch('')
    setCustomers([])
  }

  const config = () => props.history.push('/dashboard/config')

  const goHome = () => props.history.push('/dashboard')

  const logout = () => {
    localStorage.clear();
    window.open(process.env.REACT_APP_URL, '_self')
  }

  return (
    <header>
      <div className="leftSection">
        <span onClick={goHome} className="logo"></span>
        <span onClick={goHome} className="logoTxt">VOICE Platform</span>
      </div>
      <div className="rightSection">
        <div className="dropdown">
          {checkPath()?( <input type="text" id="esInput" autocomplete="off"
            className="search"
            value={search}
            onChange={onChange}
            placeholder="search here..." ></input>):''}
         
          <div id="searchDropdown" className="search-content" >
            <a onClick={config}><span className="mleft35"> Configuration</span></a>
            <a onClick={logout} className="logout" href="#Logout">Logout</a>
          </div>
        </div>
        <div className="profileOuter dropdown" onClick={openDropdown}>
          <span className="profileName">{getName()}</span>
          <span className="profileEmail">{props.decoded.email}</span>
          <div id="configDropdown" className="dropdown-content">
            <a onClick={config}><span className="mleft35"> Configuration</span></a>
            <a onClick={logout} className="logout" href="#Logout">Logout</a>
          </div>
        </div>
      </div>
      <Search
        search={search}
        customers={customers}
        cancel={cancel}
        {...props} />
    </header>
  );
};

export default Header;
