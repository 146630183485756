import { connect } from 'react-redux'
import ParentWeightage from '../../pages/config/parent-weightage/ParentWeightage'
import * as consts from '../../store/constant'

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        setParentKPI: (data) => dispatch({
            type: consts.SET_PARENT_KPI,
            data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParentWeightage)
