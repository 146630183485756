import * as consts from "../../utils/constant";
import * as utils from "../../utils/utils";

export const CreateScoreLinks = (props) => {

    const { cxList } = props.cxScoreDetails

    const getProductDivisionReport = (ReportURL, code, PBI_Table) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${code}'`
    }

    const getSBSReport = (ReportURL, divisionCode, code, PBI_Table) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/business_segment_code eq '${code}'`
    }

    const getCustomerReport = (ReportURL, code, PBI_Table) => {
        return `${ReportURL}&filter=${PBI_Table}/ship_to_national_sales_group_name eq '${code}'`
    }

    const getCustomer_PDReport = (ReportURL, divisionCode, code, PBI_Table) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/ship_to_national_sales_group_name eq '${code}'`;
    }

    // for Held Order Index Primary Link
    const getHeldOrderIndexPrimaryReportLink = (url, divisionCode = '-', buCode = '-', customer = '-') => {
        return `${url}&filter=Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '${buCode}' and Salesmetrics/ship_to_national_sales_group_name eq '${customer}'`
    }

    // for availability index
    const getProductDivisionReport_Avail = (ReportURL, code, PBI_Table) => {
        return `${ReportURL} and ${PBI_Table}/product_division_code eq '${code}'`
    }

    const getSBSReport_Avail = (ReportURL, divisionCode, code, PBI_Table) => {
        return `${ReportURL} and ${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/business_segment_code eq '${code}'`
    }

    const getCustomerReport_Avail = (ReportURL, code, PBI_Table) => {
        return `${ReportURL} and ${PBI_Table}/ship_to_national_sales_group_name eq '${code}'`
    }

    const getCustomer_PDReport_Avail = (ReportURL, divisionCode, code, PBI_Table) => {
        return `${ReportURL} and ${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/ship_to_national_sales_group_name eq '${code}'`;
    }

    const getReport_salesmetrics = (ReportURL, division = '-', customer = '-', segment = '-') => {
        return `${ReportURL}&filter=Salesmetrics/product_division_code eq '${division}' and Salesmetrics/business_segment_code eq '${segment}' and Salesmetrics/ship_to_national_sales_group_name eq '${customer}'`;
    }

    const getReport_Salesmetrics = (ReportURL, division = '-', customer = '-', segment = '-') => {
        return `${ReportURL}&filter=Salesmetrics/product_division_code eq '${division}' and Salesmetrics/business_segment_code eq '${segment}' and Salesmetrics/ship_to_national_sales_group_name eq '${customer}'`;
    }


    const getReportLink = (primary, { name }) => {
        switch (primary) {
            case 'primary':
                switch (name.toLowerCase()) {
                    case consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase():
                        return process.env.REACT_APP_TOTAL_ADMIN_COMPLAINTS_PRIMARY_LINK
                    case consts.HELD_ORDER_INDEX.toLowerCase():
                        return process.env.REACT_APP_HELD_ORDER_INDEX_PRIMARY_LINK
                    case consts.AVAILABILITY_INDEX.toLowerCase():
                        return process.env.REACT_APP_AVAILABILITY_INDEX_PRIMARY_LINK
                    case consts.PERFECT_ORDER_INDEX.toLowerCase():
                        return process.env.REACT_APP_PERFECT_ORDER_INDEX_PRIMARY_LINK
                    case consts.INVOICING_INDEX.toLowerCase():
                        return process.env.REACT_APP_INVOICING_INDEX_PRIMARY_LINK
                    case consts.AR_DISPUTE_INDEX.toLowerCase():
                        return process.env.REACT_APP_AR_DISPUTE_INDEX_PRIMARY_LINK
                    default:
                        return ''
                }
            case 'child':
                switch (name.toLowerCase()) {
                    case consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase():
                        return process.env.REACT_APP_TOTAL_ADMIN_COMPLAINTS_CHILD_LINK
                    case consts.HELD_ORDER_INDEX.toLowerCase():
                        return process.env.REACT_APP_HELD_ORDER_INDEX_CHILD_LINK
                    case consts.AVAILABILITY_INDEX.toLowerCase():
                        return process.env.REACT_APP_AVAILABILITY_INDEX_CHILD_LINK
                    case consts.PERFECT_ORDER_INDEX.toLowerCase():
                        return process.env.REACT_APP_PERFECT_ORDER_INDEX_CHILD_LINK
                    case consts.INVOICING_INDEX.toLowerCase():
                        return process.env.REACT_APP_INVOICING_INDEX_CHILD_LINK
                    case consts.AR_DISPUTE_INDEX.toLowerCase():
                        return process.env.REACT_APP_AR_DISPUTE_INDEX_CHILD_LINK
                    default:
                        return ''
                }
            case 'history':
                switch (name.toLowerCase()) {
                    case consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase():
                        return process.env.REACT_APP_TOTAL_ADMIN_COMPLAINTS_HISTORY_LINK
                    case consts.HELD_ORDER_INDEX.toLowerCase():
                        return process.env.REACT_APP_HELD_ORDER_INDEX_HISTORY_LINK
                    case consts.AVAILABILITY_INDEX.toLowerCase():
                        return process.env.REACT_APP_AVAILABILITY_INDEX_HISTORY_LINK
                    case consts.PERFECT_ORDER_INDEX.toLowerCase():
                        return process.env.REACT_APP_PERFECT_ORDER_INDEX_HISTORY_LINK
                    case consts.INVOICING_INDEX.toLowerCase():
                        return process.env.REACT_APP_INVOICING_INDEX_HISTORY_LINK
                    case consts.AR_DISPUTE_INDEX.toLowerCase():
                        return process.env.REACT_APP_AR_DISPUTE_HISTORY_LINK
                    default:
                        return ''
                }
        }
    }

    cxList.forEach(element => {
        if (element.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase() || element.name.toLowerCase() === consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase()) {
            if (!utils.isEmpty(props.selectedCustomer)) {
                if (!utils.isEmpty(props.selectedProductDivision)) {
                    const { code: divisionCode } = props.selectedProductDivision
                    const { code } = props.selectedCustomer
                    if (element.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase()) {
                        element.primary = getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element), divisionCode, '-', code);
                    } else if (element.name.toLowerCase() === consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase()) {
                        element.primary = getCustomer_PDReport_Avail(getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element), divisionCode, '-', code), divisionCode, code, 'Sales')
                    } else {
                        element.primary = getCustomer_PDReport(getReportLink('primary', element), divisionCode, code, 'Sales')
                    }
                    element.child = getCustomer_PDReport(getReportLink('child', element), divisionCode, code, 'Sales')
                    element.history = getCustomer_PDReport(getReportLink('history', element), divisionCode, code, 'Sales')
                } else {
                    const { code } = props.selectedCustomer
                    if (element.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase()) {
                        element.primary = getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element), '-', '-', code);
                    } else if (element.name.toLowerCase() === consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase()) {
                        element.primary = getCustomerReport_Avail(getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element), '-', '-', code), code, 'Sales');
                    } else {
                        element.primary = getCustomerReport(getReportLink('primary', element), code, 'Sales')
                    }
                    element.child = getCustomerReport(getReportLink('child', element), code, 'Sales')
                    element.history = getCustomerReport(getReportLink('history', element), code, 'Sales')
                }
            } else if (!utils.isEmpty(props.selectedSBS)) {
                const { code } = props.selectedSBS;
                const { code: divisionCode } = props.selectedProductDivision;
                if (element.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase()) {
                    element.primary = getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element), divisionCode, code);
                } else if (element.name.toLowerCase() === consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase()) {
                    element.primary = getSBSReport_Avail(getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element), divisionCode, code), divisionCode, code, 'Sales')
                } else {
                    element.primary = getSBSReport(getReportLink('primary', element), divisionCode, code, 'Sales')
                }
                element.child = getSBSReport(getReportLink('child', element), divisionCode, code, 'Sales')
                element.history = getSBSReport(getReportLink('history', element), divisionCode, code, 'Sales')
            } else if (!utils.isEmpty(props.selectedProductDivision)) {
                const { code } = props.selectedProductDivision
                if (element.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase()) {
                    element.primary = getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element), code);
                } else if (element.name.toLowerCase() === consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase()) {
                    element.primary = getProductDivisionReport_Avail(getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element), code), code, 'Sales');
                } else {
                    element.primary = getProductDivisionReport(getReportLink('primary', element), code
                        , 'Sales')
                }
                element.child = getProductDivisionReport(getReportLink('child', element), code, 'Sales')
                element.history = getProductDivisionReport(getReportLink('history', element), code, 'Sales')
            } else {
                if (element.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase() || element.name.toLowerCase() === consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase()) {
                    element.primary = getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element));
                } else {
                    element.primary = getReportLink('primary', element);
                }
                element.child = getReportLink('child', element);
                element.history = getReportLink('history', element)
            }
        } else if (element.name.toLowerCase() === consts.AVAILABILITY_INDEX.toLowerCase()
            || element.name.toLowerCase() === consts.PERFECT_ORDER_INDEX.toLowerCase()
            || element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()
            || element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase()) {
            const { code: divisionCode } = props.selectedProductDivision
            const { code: sbsCode } = props.selectedSBS;
            const { code: customerCode } = props.selectedCustomer

            if (element.name.toLowerCase() === consts.AVAILABILITY_INDEX.toLowerCase()) {
                element.primary = getReport_salesmetrics(getReportLink('primary', element), divisionCode, customerCode, sbsCode);
            } else {
                element.primary = getReport_Salesmetrics(getReportLink('primary', element), divisionCode, customerCode, sbsCode);
            }

            if (!utils.isEmpty(props.selectedCustomer)) {
                if (!utils.isEmpty(props.selectedProductDivision)) {
                    const { code: divisionCode } = props.selectedProductDivision
                    const { code } = props.selectedCustomer
                    if (element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                        element.child = getReport_Salesmetrics(getReportLink('child', element), divisionCode, code, sbsCode)
                    } else if (element.name.toLowerCase() === consts.AVAILABILITY_INDEX.toLowerCase()) {
                        element.child = getCustomer_PDReport_Avail(getReport_Salesmetrics(getReportLink('child', element), divisionCode, code, sbsCode), divisionCode, code, 'Sales2')
                    } else if (element.name.toLowerCase() === consts.PERFECT_ORDER_INDEX.toLowerCase()) {
                        element.child = getCustomer_PDReport(getReportLink('child', element), divisionCode, code, 'Sales')
                    } else if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase()) {
                        element.child = getCustomer_PDReport(getReportLink('child', element), divisionCode, code, 'Invoice')
                    } else {
                        element.child = getCustomer_PDReport_Avail(getReport_salesmetrics(getReportLink('child', element), divisionCode, code, sbsCode), divisionCode, code, 'Sales')
                    }
                    if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase() || element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                        element.history = getCustomer_PDReport(getReportLink('history', element), divisionCode, code, 'Invoice')
                    } else {
                        element.history = getCustomer_PDReport(getReportLink('history', element), divisionCode, code, 'Sales')
                    }
                } else {
                    const { code } = props.selectedCustomer
                    if (element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                        element.child = getReport_Salesmetrics(getReportLink('child', element), divisionCode, code, sbsCode)
                    } else if (element.name.toLowerCase() === consts.AVAILABILITY_INDEX.toLowerCase()) {
                        element.child = getCustomerReport_Avail(getReport_Salesmetrics(getReportLink('child', element), divisionCode, code, sbsCode), code, 'Sales2')
                    } else if (element.name.toLowerCase() === consts.PERFECT_ORDER_INDEX.toLowerCase()) {
                        element.child = getCustomerReport(getReportLink('child', element), code, 'Sales')
                    } else if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase()) {
                        element.child = getCustomerReport(getReportLink('child', element), code, 'Invoice')
                    } else {
                        element.child = getCustomerReport_Avail(getReport_salesmetrics(getReportLink('child', element), divisionCode, code, sbsCode), code, 'Sales')
                    }
                    if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase() || element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                        element.history = getCustomerReport(getReportLink('history', element), code, 'Invoice')
                    } else {
                        element.history = getCustomerReport(getReportLink('history', element), code, 'Sales')
                    }
                }
            } else if (!utils.isEmpty(props.selectedSBS)) {
                const { code } = props.selectedSBS;
                let url;
                const { code: divisionCode } = props.selectedProductDivision;
                if (element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                    element.child = getReport_Salesmetrics(getReportLink('child', element), divisionCode, undefined, code)
                } else if (element.name.toLowerCase() === consts.AVAILABILITY_INDEX.toLowerCase()) {
                    element.child = getSBSReport_Avail(getReport_Salesmetrics(getReportLink('child', element), divisionCode, undefined, code), divisionCode, code, 'Sales2')
                } else if (element.name.toLowerCase() === consts.PERFECT_ORDER_INDEX.toLowerCase()) {
                    element.child = getSBSReport(getReportLink('child', element), divisionCode, code, 'Sales')
                } else if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase()) {
                    element.child = getSBSReport(getReportLink('child', element), divisionCode, code, 'Invoice')
                } else {
                    element.child = getSBSReport_Avail(getReport_salesmetrics(getReportLink('child', element), divisionCode, undefined, code), divisionCode, code, 'Sales')
                }
                if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase() || element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                    element.history = getSBSReport(getReportLink('history', element), divisionCode, code, 'Invoice')
                } else {
                    element.history = getSBSReport(getReportLink('history', element), divisionCode, code, 'Sales')
                }
            } else if (!utils.isEmpty(props.selectedProductDivision)) {
                const { code } = props.selectedProductDivision
                if (element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                    element.child = getReport_Salesmetrics(getReportLink('child', element), code)
                } else if (element.name.toLowerCase() === consts.AVAILABILITY_INDEX.toLowerCase()) {
                    element.child = getProductDivisionReport_Avail(getReport_Salesmetrics(getReportLink('child', element), code), code, 'Sales2')
                } else if (element.name.toLowerCase() === consts.PERFECT_ORDER_INDEX.toLowerCase()) {
                    element.child = getProductDivisionReport(getReportLink('child', element), code, 'Sales')
                } else if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase()) {
                    element.child = getProductDivisionReport(getReportLink('child', element), code, 'Invoice')
                } else {
                    element.child = getProductDivisionReport_Avail(getReport_salesmetrics(getReportLink('child', element), code), code, 'Sales')
                }
                if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase() || element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                    element.history = getProductDivisionReport(getReportLink('history', element), code, 'Invoice')
                } else {
                    element.history = getProductDivisionReport(getReportLink('history', element), code, 'Sales')
                }
            } else {
                if (element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                    element.child = getReport_Salesmetrics(getReportLink('child', element))
                } else if (element.name.toLowerCase() === consts.PERFECT_ORDER_INDEX.toLowerCase() || element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase()) {
                    element.child = getReportLink('child', element);
                } else {
                    element.child = getReport_salesmetrics(getReportLink('child', element))
                }
                element.history = getReportLink('history', element)
            }
        }
    })
    return cxList;
}
