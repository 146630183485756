import React, { useState, useEffect, useRef  } from 'react'
import SearchItem from './search-item/SearhItem'
import styles from './search.module.scss'

function Search(props) {

    const { search, customers } = props
    const box = useRef(null);
    useOutsideAlerter(box);
    const advancedSearch = () => {
        props.cancel()
        props.history.push("/dashboard/search");
    }

    const setCustomer = (customer) => {
        const { cas, cxScore, productDivision } = customer
        let obj = {
            cas: customer.cas,
            casBenchmark: customer.casCustomerBenchmark,
            code: customer.code,
            cxBenchmark: customer.cxCustomerBenchmark,
            cxScore: customer.cxScore,
            lastQuarterCas: customer.lastQuarterValue,
            lastQuarterCxScore: customer.lastQuarterCxScore,
            name: customer.name,
            recommendation: customer.recommendation,
            responseCount: customer.responseCount,
            volatility: customer.volatility,
            whiteGlove:customer.whiteGlove,
            id:customer.id
        }
        // if (productDivision) {
        //     props.setProductDivision(productDivision)
        // } else {
        //     props.setProductDivision({})
        // }
        props.setCustomer(obj)
        props.cancel()
        props.history.push("/dashboard/customerdetail")
    }
    const [toggle, setToggle] = useState(false);
    const triggerToggle = () => {
        setToggle(!toggle)
    }
    function useOutsideAlerter(ref) {
        useEffect(() => {

            // Function for click event
            function handleOutsideClick(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    props.cancel()
                }
            }

            // Adding click event listener
            document.addEventListener("click", handleOutsideClick);

        }, [ref]);
    }

    return (
        <React.Fragment>
            {
                search.length >= 3 ?
                    <div className={styles.container} ref={box}>
                        <div className={styles.header}>
                            <div className={styles.cross} onClick={props.cancel}></div>
                            {/* <div>
                                        <span>NSGN</span>
                                    </div>
                                    <div className={styles.toggleButton} onClick={triggerToggle}>
                                            <div className={toggle?styles.checked:styles.toggleCircle}></div>
                                        </div>
                                    <div className={styles.pRight30}>
                                        <span>SGN</span>
                                    </div> */}
                        </div>
                        <div className={styles.result}>
                            {
                                customers && customers.length ?
                                    customers.map((customer, index) => (
                                        <SearchItem setCustomer={setCustomer} key={index} customer={customer} />
                                    )) : <div className={styles.nodata}>No data found!</div>
                            }
                        </div>
                        {/* <div className={styles.footer}>
                                    <span onClick={advancedSearch} >Advanced Search</span>
                                </div> */}
                    </div> : null
            }


        </React.Fragment>

    )

}

export default Search