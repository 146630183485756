// cx index - kpi

export const INVOICING_INDEX = 'INVOICING INDEX';
export const INVOICING_INDEX_CHILD_VIEW = 'Invoicing Index Detailed View';

export const TOTAL_ADMIN_COMPLAINTS = 'TOTAL ADMIN COMPLAINTS';
export const TOTAL_ADMIN_COMPLAINTS_CHILD_VIEW = 'Total Admin Complaints Detailed View';

export const HELD_ORDER_INDEX = 'HELD ORDER INDEX';
export const HELD_ORDER_INDEX_CHILD_VIEW = 'Held Order Index Detailed View';

export const AVAILABILITY_INDEX = 'AVAILABILITY INDEX';
export const AVAILABILITY_INDEX_CHILD_VIEW = 'Availability Index Detailed View';

export const PERFECT_ORDER_INDEX = 'PERFECT ORDER INDEX';
export const PERFECT_ORDER_INDEX_CHILD_VIEW = 'Perfect Order Index Detailed View';

export const AR_DISPUTE_INDEX = 'AR DISPUTE RESOLUTION INDEX';
export const AR_DISPUTE_INDEX_CHILD_VIEW = 'AR Dispute Resolution Index Detailed View';

// nba

export const IMPROVE_INVOICE_AGING = 'Improve Invoice Aging';
export const IMPROVE_INVOICE_AGING_CHILD_VIEW = 'Improve Invoice Aging Detailed View';

export const IMPROVE_OTIF = 'Improve OTIF';
export const IMPROVE_OTIF_CHILD_VIEW = 'Improve OTIF Detailed View';

export const IMPROVE_PRODUCT_AVAILABILITY = 'Improve Product Availability';
export const IMPROVE_PRODUCT_AVAILABILITY_CHILD_VIEW = 'Improve Product Availability Detailed View';

export const REDUCE_RETURN_PERCENTAGE = 'Reduce Return Percentage';
export const REDUCE_RETURN_PERCENTAGE_CHILD_VIEW = 'Reduce Return Percentage Detailed View';

export const NEW_IMPROVE_ORDER_VELOCITY = 'Improve Order Velocity'
export const NEW_IMPROVE_ORDER_VELOCITY_CHILD_VIEW = 'Improve Order Velocity Detailed View'

export const INCREASE_CREDITLINE = 'Increase Credit Limit'
export const INCREASE_CREDITLINE_CHILD_VIEW = 'Increase Credit Limit Detailed View'

//indexes for ES

export const Group_Dashboard_Index = 'tfsdl_nstar_voice_json_group_dashboard'
export const ProductDivision_Dashboard_Index = 'tfsdl_nstar_voice_json_productdivision_dashboard'
export const Group_ScoreDetails_Index = 'tfsdl_nstar_voice_json_group_score_details'
export const PD_ScoreDetails_Index = 'tfsdl_nstar_voice_json_productdivisons_score_details'
export const SBS_ScoreDetails_Index = 'tfsdl_nstar_voice_json_sbs_score_details'
export const Customer_ScoreDetails_Index = 'tfsdl_nstar_voice_json_customer_score_details'
export const PD_customer_ScoreDeatils_Index = 'tfsdl_nstar_voice_json_productdivison_customers_score_details'
export const Group_Attributes_Index = 'tfsdl_nstar_voice_json_group_customer_attributes'
export const PD_Attributes_Index = 'tfsdl_nstar_voice_json_productdivision_attributes'
export const SBS_Attributes_Index = 'tfsdl_nstar_voice_json_sbs_customer_attributes'
export const Customer_Attributes_Index = 'tfsdl_nstar_voice_json_customer_attributes_customer'
export const PD_Customer_Attributes_Index = 'tfsdl_nstar_voice_json_productdivision_customer_attributes'
