import { SET_NBA, SET_NBA_BREAD_CRUMB } from "../constant";

const NBAReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_NBA:
            return {
                ...state,
                nba: action.data
            }
        case SET_NBA_BREAD_CRUMB:
            return {
                ...state,
                breadCrumb: action.data
            }
        default:
            return state
    }
}

export default NBAReducer;