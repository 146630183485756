import { connect } from 'react-redux'
import Score from '../pages/score/Score'
import * as consts from '../store/constant'
import favInput from '../assets/json/user-favorites/input.json'

const mapStateToProps = state => {
    return {
        cxScoreDetails: state.KPIReducer ? state.KPIReducer.cxScoreDetails : null,
        breadCrumb: state.KPIReducer ? state.KPIReducer.breadCrumb : null,
        selectedKPI: state.KPIReducer ? state.KPIReducer.selectedKPI : null,
        selectedProductDivision: state.ProductDivisionReducer ? state.ProductDivisionReducer.data : null,
        selectedSBS: state.SBSReducer ? state.SBSReducer.data : null,
        selectedCustomer: state.CustomerReducer ? state.CustomerReducer.data : null,
        sasParent: state.SASReducer ? state.SASReducer.data : null,
        favInput: favInput,
        favorites: state.FavoriteReducer ? state.FavoriteReducer.data : null
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setKPI: (data) => dispatch({
            type: consts.SET_KPI,
            data: data
        }),
        setBreadCrumb: (data) => dispatch({
            type: consts.SET_BREAD_CRUMB,
            data: data
        }),
        setFavorite: (data) => dispatch({
            type: consts.SET_FAVORITE,
            data: data
        }),
        setSASParent: (data) => dispatch({
            type: consts.SET_SAS_PARENT,
            data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Score)
