import React, { useEffect, useState } from 'react';
import Accordion from "../../components/accordion/Accordion";
import styles from './attributes.module.scss';
import { useHttpGet } from "../../components/hooks/useHttp";
import * as utils from '../../utils/utils';
import * as constants from "../../../src/utils/constant";

function SBSAttribute(props) {

    const [customers, setCustomers] = useState(null)
    const [regions, setRegions] = useState(null)
    const [custGained, setCustGained] = useState(null)
    const [open_backorder, setOpen_Backorder] = useState(null)
    const [sbsDetails, setsbsDetails] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const processData = (fetchedData) => {
        const divisionDetails = fetchedData.productDivision
            .filter(item => item.code.toLowerCase() === props.divisionCode.toLowerCase())[0]
        const sbsDetails = divisionDetails.businessUnits
            .filter(item => item.code.toLowerCase() === props.code.toLowerCase())[0]

        // const regions = [...sbsDetails.revenueDistribution.regions]
        // setRegions([...regions.sort(utils.sorting('revenue', "number", false))])

        const obj = {
            gained: sbsDetails.customerGained,
            lost: sbsDetails.customerLost
        }
        setCustGained({ ...obj })

        const open_backorder = {
            open: sbsDetails.openorder,
            back: sbsDetails.backorder
        }
        setOpen_Backorder(open_backorder)

        const customers = [...sbsDetails.revenueDistribution.customers]
        setCustomers(customers.sort(utils.sorting('revenue', "number", false)))

        return sbsDetails
    };
    const getGroupData = (result) => {
        const group = (result && result.hits && result.hits.hits && result.hits.hits.length>0)?result.hits.hits[0]:[];
        return (group && group['_source'])?group['_source']:{};
    }
    useEffect(() => {
        setIsLoading(true)
        const input = {
            name: constants.SBS_Attributes_Index,
            queryParams: {
                "query": {
                    "match_all": {}
                }
            }
        }
        utils.getDataES('-elasticsearch', 'POST', input)
            .then(resp => {
                let groupESData = getGroupData(resp.data);
                let result = processData(groupESData)
                setsbsDetails(result)
                setIsLoading(false)
            }).catch(err => setIsLoading(false))
    }, [])
    return (
        sbsDetails && <div className={styles.attributes}>
            <div className={styles.attributesHeader}>{props.code} (Division level view)</div>
            <div className={styles.attributesBody}>
                <div className={styles.groupTopSection}>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>YTD Revenue​</div>
                        <div className={styles.bodyValue}>{(sbsDetails.totalRevenue)?'$'+utils.convertToInternationalCurrencySystem(sbsDetails.totalRevenue.toFixed(2)):'-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>YTD Growth​</div>
                        <div className={styles.bodyValue}>{(sbsDetails.YTDGrowth)?sbsDetails.YTDGrowth.toFixed(2)+'%':'-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>QoQ Growth​</div>
                        <div className={styles.bodyValue}>{(sbsDetails.QoQGrowth)?sbsDetails.QoQGrowth.toFixed(2)+'%':'-'}</div>
                    </div>
                </div>
                <div>
                    {/* <Accordion
                        title="Revenue Distribution by Region (YTD)"
                        type="regions"
                        list={regions}
                    /> */}
                    <Accordion title="Open Order & Backorder"
                        type="openOrder"
                        open_backorder={open_backorder}
                    />
                    <Accordion
                        title="Top 5 Customers by Revenue​ ($M) and Contribution (%)"
                        type="customerRevenue"
                        customers={customers}
                    />
                    <Accordion title="Customers Gained/Lost"
                        type="customerGained"
                        custGained={custGained}
                    />
                </div>
            </div>
        </div>)
}

export default SBSAttribute