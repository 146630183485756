import React, { useEffect, useState } from "react";
import { useHttpGet } from "../../components/hooks/useHttp";
import Product from "../../components/product/Product";
import Title from "../../components/title/Title";
import styles from "./productdivision.module.scss";
import Carousel from "react-elastic-carousel";
import { Spin } from 'antd';
import { capitalize } from "../../utils/utils";
import * as utils from "../../utils/utils";
import * as constants from "../../../src/utils/constant";

const ProductDivision = (props) => {

  const [fetchedData, setfetchedData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const processData = (fetchedData) => {
    // return fetchedData.productDivisions.filter(
    //   (element) => element.code.toLowerCase() === props.selectedProductDivision.code.toLowerCase()
    // );
    return fetchedData.filter(
      (element) => element.code.toLowerCase() === props.selectedProductDivision.code.toLowerCase()
    );
  };

  // const [isLoading, fetchedData] = useHttpGet(
  //   process.env.REACT_APP_PRODUCTDIVISION_DATA,
  //   [],
  //   processData
  // );

  let productDivision, businessSegments;
  productDivision = props.selectedProductDivision;

  let customers = [];
  if (fetchedData && fetchedData.length>0) {
    businessSegments = fetchedData[0].businessSegments;
    businessSegments.forEach((element) => {
      element.name = element.code.trim()
      element.cxBenchmark = fetchedData[0].cxSbsBenchmark;
      element.casBenchmark = fetchedData[0].casSbsBenchmark;
    });
    businessSegments.sort(utils.sorting('code', 'string', true))

    customers = fetchedData[0].customers;
    customers.forEach((element) => {
      element.name = capitalize(element.name.trim())
      element.cxBenchmark = fetchedData[0].cxCustomerBenchmark;
      element.casBenchmark = fetchedData[0].casCustomerBenchmark;
    });
    customers.sort(utils.sorting('name', 'string', true))
  }

  const showProductDivisionDetail = (item) => {
    props.history.push("/dashboard/productdivisiondetail");
  };

  const showCustomerDetail = (item) => {
    props.setCustomer(item)
    props.history.push("/dashboard/customerdetail");
  };

  const showSBSDetail = (item) => {
    props.setSelectedSBS(item)
    props.history.push('/dashboard/sbsdetail')
  };

  const goBack = () => {
    props.history.push('/dashboard')
  };
  const formatData = (result) => {
    const productDivisions = result.hits.hits[0];
    const customerFilterdArr = productDivisions.inner_hits['productDivisions.customers'].hits
    let new_array = []
    if (customerFilterdArr && customerFilterdArr.hits && customerFilterdArr.hits.length > 0) {
      new_array = customerFilterdArr.hits.map(function callback(element) {
        let custObj = {};
        custObj['name'] = (element.fields['productDivisions.customers.name.keyword'])?element.fields['productDivisions.customers.name.keyword'][0]:'-';
        custObj.cxScore = (element.fields['productDivisions.customers.cxScore'])?element.fields['productDivisions.customers.cxScore'][0]:'-';
        custObj.lastQuarterCxScore = (element.fields['productDivisions.customers.lastQuarterCxScore'])?element.fields['productDivisions.customers.lastQuarterCxScore'][0]:'-';
        custObj.code = (element.fields['productDivisions.customers.code.keyword'])?element.fields['productDivisions.customers.code.keyword'][0]:'-';
        custObj.id = (element.fields['productDivisions.customers.id'])?element.fields['productDivisions.customers.id'][0]:'-';
        custObj.name = (element.fields['productDivisions.customers.name.keyword'])?element.fields['productDivisions.customers.name.keyword'][0]:'-';
        custObj.cas = (element.fields['productDivisions.customers.cas'])?element.fields['productDivisions.customers.cas'][0]:'-';
        custObj.volatility = (element.fields['productDivisions.customers.volatility.keyword'])?element.fields['productDivisions.customers.volatility.keyword'][0]:'-';
        custObj.recommendation = (element.fields['productDivisions.customers.recommendation.keyword'])?element.fields['productDivisions.customers.recommendation.keyword'][0]:'-';
        custObj.whiteGlove = (element.fields['productDivisions.customers.whiteGlove.keyword'])?element.fields['productDivisions.customers.whiteGlove.keyword'][0]:'-';
        return custObj
      })
    }
    let allProductDivisions = [...productDivisions['_source'].productDivisions]
    let selectedPDIndex = allProductDivisions.findIndex(item=>item.code === productDivision.code)
    allProductDivisions[selectedPDIndex].customers = new_array
    //productDivisions['_source'].productDivisions.customers = new_array;
    return allProductDivisions;
  }
  useEffect(() => {
    const input = {
      name: constants.ProductDivision_Dashboard_Index,
      queryParams: {
        "_source": {
          "excludes": "*.customers"
        },
        "query": {
          "nested": {
            "path": "productDivisions.customers",
            "query": {
              "bool": {
                "must": [
                  {
                    "match": {
                      "productDivisions.customers.whiteGlove": true
                    }
                  },
                  {
                    "match": {
                      "productDivisions.customers.productdivision": productDivision.code
                    }
                  }
                ]
              }
            },
                 "inner_hits": {
              "_source": false,
              "size": 50,
              "docvalue_fields": [
                "productDivisions.customers.code.keyword",
                "productDivisions.customers.name.keyword",
                "productDivisions.customers.whiteGlove.keyword",
                "productDivisions.customers.cxScore",
                "productDivisions.customers.lastQuarterCxScore",
                "productDivisions.customers.cas",
                "productDivisions.customers.productdivision.keyword",
                "productDivisions.customers.id",
                "productDivisions.customers.volatility.keyword",
                "productDivisions.customers.recommendation.keyword"
              ]
            }
          }
        }
      }
    }
    setIsLoading(true)
    utils.getDataES('-elasticsearch', 'POST',input)
      .then(resp => {
        let ESData = formatData(resp.data);
        let result = processData(ESData)
        setfetchedData(JSON.parse(JSON.stringify(result)))
        setIsLoading(false)
      }).catch(err =>  setIsLoading(false))
    props.setCustomer({})
    props.setSelectedSBS({})
  }, [])
  const productBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 4 },
    { width: 1750, itemsToShow: 5 }
  ]
  const customerBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 }
  ]
  return <Spin spinning={isLoading}>
    <div className={styles.container}>
      <div className={styles.productsContainer} >
        {
          productDivision ?
            <div>
              <div className={styles.productHead}>
                <div className={styles.backBtn} onClick={goBack}>
                  Back
          </div>
                <Title value="Product Division" />
              </div>
              <Product
                product={productDivision}
                {...props}
                type="product"
                showDetail={showProductDivisionDetail}
              />
            </div> : null
        }
        {businessSegments && businessSegments.length ? (
          <div className={styles.productBox}>
            <Title value="Business Units​" count={businessSegments.length} />
            <div className="outerGrid">
              <Carousel breakPoints={productBreakPoints}>
                {businessSegments.map((item, index) => {
                  return (
                    <Product
                      key={index}
                      product={item}
                      {...props}
                      type="productdivision"
                      showDetail={showSBSDetail}
                    />
                  );
                })}
              </Carousel>
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.productsContainer}>
        {customers && customers.length ? (
          <div className={styles.customerLayoutBx}>
            <Title value="Customers" count={customers.length} />
            <Carousel breakPoints={customerBreakPoints}>
              {customers.map((item, index) => {
                return (
                  <Product
                    key={index}
                    product={item}
                    {...props}
                    type="customer"
                    showDetail={showCustomerDetail}
                  />
                );
              })}
            </Carousel>
          </div>
        ) : null}
      </div>
    </div>
  </Spin>
};

export default ProductDivision;
