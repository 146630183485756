import React, { useState, useEffect } from 'react'
import PowerBi from '../../../components/powerbi/PowerBi'
import { getCASReport } from '../Cas.utils'
import styles from './childview.module.scss'
import * as utils from "../../../utils/utils";
import * as favUtils from "../../../utils/favorite.utils";
import { Spin } from 'antd';
import FavoriteIcon from '../../../components/favoriate-icon/favoriteIcon'

const ChildView = (props) => {

    const childview = getCASReport({ ...props, type: 'childview' })
    const favInput = JSON.parse(JSON.stringify(props.favInput));
    let [FavoriteId, setFavoriteId] = useState(null)
    const [loading, setLoading] = useState(false)

    const goBack = () => {
        props.setSASParent('sas')
        props.history.push('/dashboard/score/cas')
    }

    async function getFavorites() {
        const favorites = await favUtils.getFavorites()
        props.setFavorite(favorites)
    }

    async function addToFavorite(isAlreadyFavorite) {
        setLoading(true)
        if (!isAlreadyFavorite) {
            favInput.UserId = utils.getUserEmail()
            favInput.ViewType = 9
            favInput.InputParameters.productDivision = props.selectedProductDivision;
            favInput.InputParameters.customer = props.selectedCustomer;
            favInput.InputParameters.sbs = props.selectedSBS;
            favInput.InputParameters.sasParent = 'sas';
            favInput.InputParameters.cxScoreDetails = props.cxScoreDetails;
            favInput.InputParameters.breadcrumb = props.breadCrumb;
            const FavoriteId = await favUtils.addFavorites(favInput)
            setFavoriteId(FavoriteId)
        } else {
            await favUtils.removeFavorites(FavoriteId)
            setFavoriteId(null)
        }
        setLoading(false)
        getFavorites()
    }

    useEffect(() => {
        setFavoriteId(favUtils.chkIsFavorite(
            props.favorites,
            9,
            props.selectedProductDivision,
            props.selectedCustomer,
            props.selectedSBS))
    }, [])

    return (
        <Spin spinning={loading}>
            <div className={styles.container}>
                <div className={styles.headerSection}>
                    <div onClick={goBack} className={styles.backBtn}>Back</div>
                    <h1 className={styles.heading}>CAS Survey Areas</h1>
                    <FavoriteIcon
                        isFavorite={FavoriteId ? true : false}
                        addToFavorite={addToFavorite} />
                </div>
                <div className={styles.reportContainer}>
                    <PowerBi view={childview} height="700px" />
                </div>
            </div>
        </Spin>
    )
}

export default ChildView